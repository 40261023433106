.countdown__svg {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding-left: 6%;
  width: 80px;
  height: 80px;

  path {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

//MEDIA AREA

@mixin mediaSVG(
  $padding-left,
  $width,
  $height,
  $webkit-transform,
  $ms-transform,
  $transform
) {
  .countdown__svg {
    //size of circular path svg
    padding-left: $padding-left;
    width: $width;
    height: $height;

    path {
      //circular path scale radius size
      -webkit-transform: $webkit-transform;
      -ms-transform: $ms-transform;
      transform: $transform;
    }
  }
}

@media (min-width: 393px) {
  @include mediaSVG(
    $padding-left: 6%,
    $width: 90px,
    $height: 90px,
    $webkit-transform: scale(1.1),
    $ms-transform: scale(1.1),
    $transform: scale(1.1)
  );
}

@media (min-width: 482px) {
  @include mediaSVG(
    $padding-left: 5%,
    $width: 100px,
    $height: 100px,
    $webkit-transform: scale(1.2),
    $ms-transform: scale(1.2),
    $transform: scale(1.2)
  );
}

@media (min-width: 576px) {
  @include mediaSVG(
    $padding-left: 3%,
    $width: 100px,
    $height: 100px,
    $webkit-transform: scale(1.3),
    $ms-transform: scale(1.3),
    $transform: scale(1.3)
  );
}

@media (min-width: 768px) {
  @include mediaSVG(
    $padding-left: 3%,
    $width: 100px,
    $height: 100px,
    $webkit-transform: scale(1.3),
    $ms-transform: scale(1.3),
    $transform: scale(1.3)
  );
}
