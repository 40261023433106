.about-us__faq {
  max-width: 1000px;
  width: 90%;

  .faq__header {
    background-color: #47b0e2;

    padding: 40px 80px;

    .header__title {
      font-family: var(--major-title-fontFamily);
      font-size: var(--major-title-fontSize);
      line-height: 40px;
      text-transform: capitalize;
      color: #f1faff;
      margin-bottom: 20px;
    }

    .header__search-questions {
      width: 650px;
      max-width: 100%;
      height: 60px;

      border: 2px solid #003a58;
      border-radius: 5px;

      .search-questions__input-wrapper {
        width: 100%;
        height: 100%;

        // From component
        .input-group {
          height: 100%;
        }

        .search-questions__input {
          width: 100%;
          height: 100%;
        }
      }

      .search-questions__submit {
        font-family: var(--all-button-fontFamily);
        font-size: var(--all-button-fontSize);
        line-height: 32px;
        color: white;

        background-color: #003a58;
        border: none;
        width: 128px;
        height: 100%;
      }
    }
  }

  .faq_box {
    padding: 50px 0;
    background-color: #e3f3fc;

    .faq__details {
      background-color: #e3f3fc;
      padding: 0 80px;

      height: 700px;
      overflow: scroll;
      overflow-x: hidden;
      overscroll-behavior: contain;

      // // sorok scrollbar
      // scrollbar-width: none; /* Firefox */
      // -ms-overflow-style: none; /* Internet Explorer 10+ */

      // &::-webkit-scrollbar {
      //   display: none; /* Safari and Chrome */
      // }

      > div {
        padding-bottom: 50px;

        &:first-child {
          padding-top: 0;
        }
        &:last-child {
          padding-bottom: 0;
        }
      }

      .details__section {
        .section__topic {
          color: #083a56;
          font-family: var(--title-description-fontFamily);
          font-size: var(--title-description-fontSize);
          line-height: 32px;
          text-decoration: underline;

          padding-bottom: 8px;
        }

        .section__question,
        .section__answer {
          background: #ffffff;
          border: 1px solid #4f758a;
        }

        .section__question {
          padding: 16px 14px;

          .question__text {
            font-weight: 500;
            color: rgb(80, 80, 80);
          }

          .question__expander {
            cursor: pointer;
          }
        }

        .section__answer {
          padding: 16px 14px;

          transition: max-height 0.2s ease-out, padding 0.2s, border 0.2s;

          max-height: 900px;

          overflow: hidden;

          &.section__answer_hide {
            max-height: 0px;
            padding: 0;

            border: none;
          }
        }
      }
    }
  }
}

// MEDIA AREA

@mixin faqMedia(
  $padding1,
  $fontSize1,
  $width1,
  $height1,
  $fontSize2,
  $width2,
  $padding2,
  $padding3,
  $paddingBottom1,
  $fontSize3,
  $paddingBottom2,
  $padding4,
  $padding5
) {
  .about-us__faq {
    .faq__header {
      padding: $padding1;

      .header__title {
        font-size: $fontSize1;
      }

      .header__search-questions {
        width: $width1;

        height: $height1;

        .search-questions__input-wrapper {
          .search-questions__input {
          }
        }

        .search-questions__submit {
          font-size: $fontSize2;
          width: $width2;
        }
      }
    }

    .faq_box {
      padding: $padding2;

      .faq__details {
        padding: $padding3;

        > div {
          padding-bottom: $paddingBottom1;
        }

        .details__section {
          .section__topic {
            font-size: $fontSize3;

            padding-bottom: $paddingBottom2;
          }

          .section__question {
            padding: $padding4;
          }

          .section__answer {
            padding: $padding5;

            &.section__answer_hide {
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  @include faqMedia(
    $padding1: 40px 80px,
    $fontSize1: var(--major-title-fontSize),
    $width1: 650px,
    $height1: 60px,
    $fontSize2: var(--all-button-fontSize),
    $width2: 128px,
    $padding2: 50px 0,
    $padding3: 0 80px,
    $paddingBottom1: 50px,
    $fontSize3: var(--title-description-fontSize),
    $paddingBottom2: 8px,
    $padding4: 16px 14px,
    $padding5: 16px 14px
  );
}

@media (max-width: 768px) {
  @include faqMedia(
    $padding1: 30px 60px,
    $fontSize1: var(--major768-title-fontSize),
    $width1: 500px,
    $height1: 55px,
    $fontSize2: var(--all-button768-fontSize),
    $width2: 100px,
    $padding2: 40px 0,
    $padding3: 0 30px,
    $paddingBottom1: 40px,
    $fontSize3: var(--title768-description-fontSize),
    $paddingBottom2: 6px,
    $padding4: 15px 13px,
    $padding5: 15px 13px
  );
}

@media (max-width: 480px) {
  @include faqMedia(
    $padding1: 20px 20px,
    $fontSize1: var(--major480-title-fontSize),
    $width1: 500px,
    $height1: 50px,
    $fontSize2: var(--all-button480-fontSize),
    $width2: 70px,
    $padding2: 30px 0,
    $padding3: 0 15px,
    $paddingBottom1: 30px,
    $fontSize3: var(--title480-description-fontSize),
    $paddingBottom2: 4px,
    $padding4: 14px 12px,
    $padding5: 14px 12px
  );
}
