.component-dropdown {    
    // Removing library arrow to use our customize arrow
    .dropdown-toggle::after {
        display: none;
    }

    @mixin component(
        $background-color: initial,
        $border: initial,
        $box-shadow: initial,
        $menuBorder: initial,
        $toggleHeight: initial,
    ) {
        .component-dropdown__wrapper {
            .wrapper__toggle {
                position: relative;
    
                background-color: $background-color;
                border: $border;
                box-shadow: $box-shadow;

                height: $toggleHeight;
    
                .toggle__icon {
                    width: 15px;
                    height: 15px;
    
                    margin-left: 5px;
                }
            }
    
            .wrapper__menu {
                width: 100%;
                max-height: 200px;
    
                overflow: auto;
                
                border: $menuBorder;
            }
        }
    }

    &.component-dropdown_light-theme {
        .wrapper__toggle {
            color: black !important;
        }

        @include component(
            white !important, 
            1px solid black !important,
            none !important,
            1px solid black,
            38px
        );
    }
}