.backButtonBackground {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 4px;
    border-radius: 50%;
  }
  
  //MEDIA AREA
  
  @mixin backbuttonmedia($padding) {
    .backButtonBackground {
      padding: $padding;
    }
  }
  
  @media (min-width: 393px) {
    @include backbuttonmedia($padding: 6px);
  }
  
  @media (min-width: 482px) {
    @include backbuttonmedia($padding: 7px);
  }
  
  @media (min-width: 576px) {
    @include backbuttonmedia($padding: 8px);
  }
  
  @media (min-width: 768px) {
    @include backbuttonmedia($padding: 9px);
  }
  