.home__steps-to-register {
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 60px;

  .steps-to-register__header > span {
    font-family: var(--major-title-fontFamily);
    font-size: var(--major-title-fontSize);
    font-style: normal;
    font-weight: bold;
    line-height: 50px;
    letter-spacing: 0em;
    color: var(--color-forTitle);

    position: relative;

    .EkadTepi {
      color: #47b0e2;
    }
  }

  .steps-to-register__sub-header {
    .steps-to-register_text {
      font-family: var(--minor-title-fontFamily);
      font-size: var(--title-description-fontSize);
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
      padding-top: 20px;
      padding-bottom: 30px;
      width: 90%;
      color: #3d3d3d;
    }
  }

  .steps-to-register__steps {
    position: relative;

    .steps__one {
      margin-right: 220px;
    }

    .steps__two {
      position: absolute;
      top: 0px;
      left: calc(50% - 150px);
      z-index: 1;
    }

    .steps__three {
    }

    .number {
      color: #47b0e2;
      font-family: Rubik One;
      font-size: 70px;
      font-style: normal;
      font-weight: 400;
      line-height: 80px;
      letter-spacing: 0em;
      text-align: center;
    }

    .description {
      width: 200px;
      height: 75px;

      font-family: Poppins;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
    }

    .steps__one,
    .steps__two,
    .steps__three {
      //border: 5px solid #47b0e2;
      filter: drop-shadow(0px 8px 5px rgba(0, 0, 0, 0.2));
      border-radius: 50%;
      background-color: white;

      height: 300px;
      width: 300px;
    }
  }
}

//MEDIA AREA

@mixin mediaRegistrationSteps(
  $space,
  $fontSize1,
  $fontSize2,
  $paddingTop1,
  $paddingBottom1,
  $marginRight1,
  $marginLeft1,
  $marginTop1,
  $fontSize3,
  $lineHeight3,
  $width1,
  $height1,
  $fontSize4,
  $lineHeight4,
  $width2,
  $height2
) {
  .home__steps-to-register {
    margin-top: $space;
    padding-top: $space;
    padding-bottom: $space;

    .steps-to-register__header > span {
      font-size: $fontSize1;
    }

    .steps-to-register__sub-header {
      .steps-to-register_text {
        font-size: $fontSize2;
        padding-top: $paddingTop1;
        padding-bottom: $paddingBottom1;
      }
    }

    .steps-to-register__steps {
      .steps__one {
        margin-right: $marginRight1;
      }

      .steps__two {
        margin-left: $marginLeft1;
      }

      .steps__three {
        margin-top: $marginTop1;
      }

      .number {
        font-size: $fontSize3;
        line-height: $lineHeight3;
      }

      .description {
        width: $width1;
        height: $height1;
        font-size: $fontSize4;
        line-height: $lineHeight4;
      }

      .steps__one,
      .steps__two,
      .steps__three {
        height: $width2;
        width: $height2;
      }
    }
  }
}

@media (max-width: 991px) {
  .steps-to-register__steps {
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;

    .steps__one {
    }

    .steps__two {
    }
    .steps__three {
      position: relative;
    }
  }

  @include mediaRegistrationSteps(
    $space: 35px,
    $fontSize1: var(--major-title-fontSize),
    $fontSize2: var(--title-description-fontSize),
    $paddingTop1: 15px,
    $paddingBottom1: 25px,
    $marginRight1: 260px,
    $marginLeft1: 140px,
    $marginTop1: -50px,
    $fontSize3: 65px,
    $lineHeight3: 80px,
    $width1: 190px,
    $height1: 75px,
    $fontSize4: 16px,
    $lineHeight4: 30px,
    $width2: 300px,
    $height2: 300px
  );
}

@media (max-width: 768px) {
  @include mediaRegistrationSteps(
    $space: 30px,
    $fontSize1: var(--major768-title-fontSize),
    $fontSize2: var(--title768-description-fontSize),
    $paddingTop1: 10px,
    $paddingBottom1: 20px,
    $marginRight1: 200px,
    $marginLeft1: 140px,
    $marginTop1: -40px,
    $fontSize3: 60px,
    $lineHeight3: 70px,
    $width1: 170px,
    $height1: 75px,
    $fontSize4: 14px,
    $lineHeight4: 24px,
    $width2: 220px,
    $height2: 220px
  );
}

@media (max-width: 480px) {
  @include mediaRegistrationSteps(
    $space: 25px,
    $fontSize1: var(--major480-title-fontSize),
    $fontSize2: var(--title480-description-fontSize),
    $paddingTop1: 5px,
    $paddingBottom1: 15px,
    $marginRight1: 150px,
    $marginLeft1: 145px,
    $marginTop1: -27px,
    $fontSize3: 45px,
    $lineHeight3: 60px,
    $width1: 120px,
    $height1: 65px,
    $fontSize4: 13px,
    $lineHeight4: 18px,
    $width2: 160px,
    $height2: 160px
  );
}
