.nav-button {
  margin: 5px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 12px 28px rgba(0, 0, 0, 0.2);

  &.nav-button_secondary {
    background-color: var(--color-disabled);
  }

  &.nav-button_primary {
    background-color: var(--color-primary);
  }
}
