.preview {
  position: relative;
  width: 100%;
  height: 100%;
  background: black;

  display: flex;
  flex-direction: column;
  justify-content: center;

  .preview__header {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    padding: 5px;

    width: 100%;

    i {
      color: whitesmoke;
      font-size: 25px;
      margin: 0;
      cursor: pointer;

      span {
        font-size: 12px;
        margin: 0;
        vertical-align: middle;
        margin-left: 5px;
        font-family: "Poppins", sans-serif;
      }
    }
    .preview__headerSend {
      display: flex;
      justify-content: space-around;
      border-radius: 20px;
      background-color: whitesmoke;
      margin: 0;
      align-items: center;
      padding: 5px 8px 5px 8px;
      cursor: pointer;

      i {
        color: black;
        font-size: 23px;
        margin: 0;
      }

      span {
        font-size: small;
        font-weight: 600;
        margin: 0;
        font-family: "Poppins", sans-serif;
      }
    }
  }

  .preview__image {
    width: 100%;
    height: 100vw;
    background: black;
    margin: 0;

    object-fit: contain;
  }

  .preview__footer {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px;

    width: 100%;

    .preview__footerTop {
      margin: 0;
      font-family: "Poppins", sans-serif;
      .caption {
        width: 100%;
      }

      .filters {
        width: 100%;
      }
    }

    .preview__footerBottom {
      display: flex;
      justify-content: center;
      padding: 5px;
      width: 100%;

      div > p {
        color: whitesmoke;
        font-size: 14px;
        font-family: "Poppins", sans-serif;
      }
    }
  }
}
