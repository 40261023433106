.rsvp-session {
  .rsvp-session-ecard-id {
    font-family: var(--title-description-fontFamily);
    font-size: var(--title-description-fontSize);

    margin-top: 10px;
    margin-bottom: 16px;
  }

  .session__container {
    width: 80%;
    .session__element {
      border: 1px solid var(--color-disabled);

      &:hover {
        cursor: pointer;
      }
      .sesi-section {
        font-family: var(--title-description-fontFamily);
        font-size: 16px;
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 2px;
      }
      .sesi-time,
      .sesi-attendance {
        font-family: var(--all-description-fontFamily);
        font-size: 14px;
      }
    }
  }

  .session__selectedId {
    color: rgb(255, 255, 255);
    background: rgb(100, 100, 100);

    &:hover {
      cursor: pointer;
      background: rgb(100, 100, 100);
    }
  }

  .name-list__header {
    .header__title {
      font-family: var(--title-description-fontFamily);
      font-size: var(--title-description-fontSize);
      margin-top: 20px;
      margin-bottom: 0;
    }
    .name-list-attendance-total {
      font-family: var(--all-description-fontFamily);
      font-size: var(--all-description-fontSize);
      margin-bottom: 16px;
    }
  }

  .name-list__container {
    width: 100%;

    height: 320px;
    border: 2px solid var(--color-disabled);
    align-content: flex-start; //need to specify thish to override bootstrap row behavior align-content: stretch

    .name-list__element {
      font-family: "poppins";
      border: 1px solid black;
      height: 80px;

      .name-list__element-name {
        margin: 15px 0px;
        .attendance-name {
          font-size: 14px;
        }
        .attendance-phone {
          font-size: 12px;
        }
      }

      .name-list__element-number {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: #4f758a;

        .attendance-number {
          font-size: 20px;
          color: white;
        }
      }
    }
  }
}

//MEDIA AREA

@media (max-width: 991px) {
  .rsvp-session {
    .rsvp-session-ecard-id {
      font-size: var(--title-description-fontSize);
    }

    .session__container {
      width: 80%;
      .session__element {
        .sesi-section {
          font-size: 16px;
        }

        .sesi-time,
        .sesi-attendance {
          font-size: 14px;
        }
      }
    }

    .name-list__header {
      .header__title {
        font-size: var(--title-description-fontSize);
        margin-top: 20px;
      }
      .name-list-attendance-total {
        font-size: var(--all-description-fontSize);
        margin-bottom: 16px;
      }
    }

    .name-list__container {
      .name-list__element {
        height: 80px;

        .name-list__element-name {
          margin: 15px 0px;
          .attendance-name {
            font-size: 14px;
          }
          .attendance-phone {
            font-size: 12px;
          }
        }

        .name-list__element-number {
          width: 45px;
          height: 45px;

          .attendance-number {
            font-size: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .rsvp-session {
    .rsvp-session-ecard-id {
      font-size: var(--title768-description-fontSize);
    }

    .session__container {
      width: 80%;
      .session__element {
        .sesi-section {
          font-size: 15px;
          margin-top: 8px;
          margin-bottom: 2px;
        }

        .sesi-time,
        .sesi-attendance {
          font-size: 13px;
          margin-bottom: 5px;
        }
      }
    }

    .name-list__header {
      .header__title {
        font-size: var(--title768-description-fontSize);
        margin-top: 20px;
      }
      .name-list-attendance-total {
        font-size: var(--all768-description-fontSize);
        margin-bottom: 16px;
      }
    }

    .name-list__container {
      .name-list__element {
        height: 70px;

        .name-list__element-name {
          margin: 15px 0px;
          .attendance-name {
            font-size: 14px;
          }
          .attendance-phone {
            font-size: 12px;
          }
        }

        .name-list__element-number {
          width: 40px;
          height: 40px;

          .attendance-number {
            font-size: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .rsvp-session {
    .rsvp-session-ecard-id {
      font-size: var(--title480-description-fontSize);
    }

    .session__container {
      width: 80%;
      .session__element {
        .sesi-section {
          font-size: 14px;
          margin-top: 6px;
          margin-bottom: 0px;
        }

        .sesi-time,
        .sesi-attendance {
          font-size: 12px;
          margin-bottom: 3px;
        }
      }
    }

    .name-list__header {
      .header__title {
        font-size: var(--title480-description-fontSize);
        margin-top: 18px;
      }
      .name-list-attendance-total {
        font-size: var(--all480-description-fontSize);
        margin-bottom: 14px;
      }
    }

    .name-list__container {
      .name-list__element {
        height: 60px;

        .name-list__element-name {
          margin: 12px 0px;
          .attendance-name {
            font-size: 14px;
          }
          .attendance-phone {
            font-size: 12px;
          }
        }

        .name-list__element-number {
          width: 35px;
          height: 35px;

          .attendance-number {
            font-size: 18px;
          }
        }
      }
    }
  }
}
