.wish__wish-list {
  .wish-list__element {
    position: relative;
    margin-bottom: 15px;

    background-color: rgb(248, 248, 248);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 5px 0px;

    .wisher-list {
      margin: 10px 5px;
      width: 15%;

      .wish-list__element-name {
      }
      .wish-list__element-relay {
        word-break: break-all;
      }
    }

    .wish-list__element-wishtime {
      margin: 10px 20px 15px 5px;

      width: 80%;

      .wish-wish {
      }
      .wish-time {
        position: absolute;
        font-size: 9px;
        bottom: 0;
        right: 2px;
      }
    }
    .action__delete {
      padding: 0;
      border-radius: 0px;
      height: 25px;
      width: 25px;

      position: absolute;

      margin-right: 2px;
      top: 0;
      right: 0;
    }
  }
}

.wish-list__element.disabled {
  pointer-events: none;
  opacity: 0.6;
}

//MEDIA AREA

@media (max-width: 991px) {
}

@media (max-width: 768px) {
}

@media (max-width: 480px) {
}
