$bg-accent: #ffffff;

.custom__button {
  position: absolute;
  bottom: 3%;
  right: 6%;

  // background-color: #fafafa;
  // border-radius: 50%;
  // margin: 2px;
  // padding: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 300ms;

  &:hover {
    filter: brightness(0.9);
  }

  i {
    font-size: 30px;
  }
}

//MEDIA AREA

@mixin custombuttonmedia($padding, $fontsize1) {
  .custom__button {
    margin: $padding;
    padding: $padding;
    i {
      font-size: $fontsize1;
      background-color: white;
      border-radius: 50%;
      border: 2px solid white;
    }
  }
}

@media (min-width: 393px) {
  @include custombuttonmedia($padding: 6px, $fontsize1: 32px);
}

@media (min-width: 482px) {
  @include custombuttonmedia($padding: 7px, $fontsize1: 34px);
}

@media (min-width: 576px) {
  @include custombuttonmedia($padding: 8px, $fontsize1: 38px);
}

@media (min-width: 768px) {
  @include custombuttonmedia($padding: 9px, $fontsize1: 42px);
}
