@mixin logoAnimate($animation, $duration, $count, $direction) {
  margin: 5%;
  padding: 0;
  width: 50%;

  -webkit-animation: $animation $duration $count $direction;
  animation: $animation $duration $count $direction;
}

.logo {
  background-color: var(--color-primary);
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .logo__title {
    @include logoAnimate(jello-horizontal, 2s, 1, both);
  }

  .logo__image {
    @include logoAnimate(flip-horizontal-bottom, 0.6s, infinite, both);
  }
}

//////////////////////

.alllove {
  position: relative;

  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-family: "Cookie";
    font-size: var(--minor-title-fontSize);
    color: var(--color-primary);
    text-align: center;
    line-height: 120%;
    letter-spacing: 1px;

    span {
      font-family: "Concert One";
      font-size: 24px;
    }
  }

  .love {
    position: absolute;
    top: 50%;
    left: 30%;
    color: var(--color-primary);
  }

  .love1 {
    -webkit-animation-name: love1;
    -webkit-animation-duration: 7s;
    -webkit-animation-iteration-count: infinite;
  }

  .love2 {
    left: 35%;
    -webkit-animation-name: love2;
    -webkit-animation-duration: 6s;
    -webkit-animation-iteration-count: infinite;
  }

  .love3 {
    left: 40%;
    -webkit-animation-name: love3;
    -webkit-animation-duration: 8s;
    -webkit-animation-iteration-count: infinite;
  }

  .love4 {
    left: 50%;
    -webkit-animation-name: love4;
    -webkit-animation-duration: 7s;
    -webkit-animation-iteration-count: infinite;
  }

  .love5 {
    left: 55%;
    -webkit-animation-name: love5;
    -webkit-animation-duration: 6s;
    -webkit-animation-iteration-count: infinite;
  }

  @keyframes love1 {
    0% {
      top: 65%;
    }
    20% {
      left: 27%;
    }
    40% {
      left: 25%;
    }
    60% {
      left: 23%;
    }
    80% {
      left: 25%;
    }
    100% {
      top: 15%;
      left: 25%;
      opacity: 0;
    }
  }

  @-webkit-keyframes love1 {
    0% {
      top: 65%;
    }
    20% {
      left: 27%;
    }
    40% {
      left: 25%;
    }
    60% {
      left: 23%;
    }
    80% {
      left: 25%;
    }
    100% {
      top: 15%;
      left: 25%;
      opacity: 0;
    }
  }

  @keyframes love2 {
    0% {
      top: 65%;
    }
    20% {
      left: 37%;
    }
    40% {
      left: 35%;
    }
    60% {
      left: 33%;
    }
    80% {
      left: 35%;
    }
    100% {
      top: 15%;
      left: 35%;
      opacity: 0;
    }
  }

  @-webkit-keyframes love2 {
    0% {
      top: 65%;
    }
    20% {
      left: 37%;
    }
    40% {
      left: 35%;
    }
    60% {
      left: 33%;
    }
    80% {
      left: 35%;
    }
    100% {
      top: 15%;
      left: 35%;
      opacity: 0;
    }
  }

  @keyframes love3 {
    0% {
      top: 65%;
    }
    20% {
      left: 47%;
    }
    40% {
      left: 45%;
    }
    60% {
      left: 43%;
    }
    80% {
      left: 45%;
    }
    100% {
      top: 15%;
      left: 45%;
      opacity: 0;
    }
  }

  @-webkit-keyframes love3 {
    0% {
      top: 65%;
    }
    20% {
      left: 47%;
    }
    40% {
      left: 45%;
    }
    60% {
      left: 43%;
    }
    80% {
      left: 45%;
    }
    100% {
      top: 15%;
      left: 45%;
      opacity: 0;
    }
  }

  @keyframes love4 {
    0% {
      top: 65%;
    }
    20% {
      left: 57%;
    }
    40% {
      left: 55%;
    }
    60% {
      left: 53%;
    }
    80% {
      left: 55%;
    }
    100% {
      top: 15%;
      left: 55%;
      opacity: 0;
    }
  }

  @-webkit-keyframes love4 {
    0% {
      top: 65%;
    }
    20% {
      left: 57%;
    }
    40% {
      left: 55%;
    }
    60% {
      left: 53%;
    }
    80% {
      left: 55%;
    }
    100% {
      top: 15%;
      left: 55%;
      opacity: 0;
    }
  }

  @keyframes love5 {
    0% {
      top: 65%;
    }
    20% {
      left: 67%;
    }
    40% {
      left: 65%;
    }
    60% {
      left: 63%;
    }
    80% {
      left: 65%;
    }
    100% {
      top: 15%;
      left: 65%;
      opacity: 0;
    }
  }

  @-webkit-keyframes love5 {
    0% {
      top: 65%;
    }
    20% {
      left: 67%;
    }
    40% {
      left: 65%;
    }
    60% {
      left: 63%;
    }
    80% {
      left: 65%;
    }
    100% {
      top: 15%;
      left: 65%;
      opacity: 0;
    }
  }
}
