@mixin decoration(
  $background-color: white,
  $text-color: var(--color-primary),
  $font-family: var(--all-button-fontFamily),
  $border: 1px solid var(--color-primary),
  $border-radius: 10px
) {
  background-color: $background-color;
  border-radius: $border-radius;
  border: $border;

  color: $text-color;
  font-family: $font-family;
}

.component-button {
  font-size: var(--all-button-fontSize);

  white-space: nowrap;

  padding: 10px 30px;

  outline: none !important;

  cursor: pointer;

  &:active {
    transform: scale(0.98);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.24);
  }

  &.component-button_bright-primary {
    @include decoration(
      $background-color: var(--color-primary),
      $text-color: white
    );
  }

  &.component-button_bright-inverse-primary {
    @include decoration();
  }

  &.component-button_dark-primary {
    @include decoration(
      $background-color: var(--color-primary),
      $text-color: white,
      $border: 1px solid white
    );
  }

  &.component-button_dark-inverse-primary {
    @include decoration($border: 1px solid white);
  }

  &.component-button_secondary {
    @include decoration(
      $background-color: var(--color-secondary),
      $border: 1px solid var(--color-secondary),
      $text-color: white
    );
  }

  &.component-button_inverse-secondary {
    @include decoration(
      $border: 1px solid var(--color-secondary),
      $text-color: var(--color-secondary)
    );
  }

  &.component-button_unimportant {
    @include decoration(
      $background-color: lightgrey,
      $text-color: #696969,
      $border: lightgrey
    );
  }

  &.component-button-delete {
    @include decoration(
      $background-color: rgb(255, 92, 92),
      $border: lightgrey
    );
  }

  &.component-button_disabled {
    @include decoration(
      $background-color: lightgrey,
      $text-color: #696969,
      $border: lightgrey
    );

    cursor: default;
  }

  &.component-button_transparent-light-bg {
    @include decoration(
      $background-color: transparent,
      $text-color: #4f758a,
      $border: 2px solid #4f758a
    );

    padding: 13px 46px;
  }

  &.component-button_success {
    @include decoration($background-color: #41d888, $text-color: #ffffff);
  }
}

// MEDIA AREA

@media (max-width: 768px) {
  .component-button {
    font-size: var(--all768-button-fontSize) !important;
    padding: 10px 25px !important;
  }
}

@media (max-width: 480px) {
  .component-button {
    font-size: var(--all480-button-fontSize) !important;
    padding: 10px 20px !important;
  }
}
