.menu-container {
  z-index: 11;
  flex: 1;
  position: absolute;
  height: 85%;
  width: 80%;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f6f6f6;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 12px 28px rgba(0, 0, 0, 0.2);
}
