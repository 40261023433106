.about-us {
  .about-us__page-title {
    font-family: var(--major-title-fontFamily);
    font-size: var(--major-title-fontSize);
    font-weight: bold;

    padding-bottom: 40px;

    color: var(--color-forTitle);

    .EkadTepi {
      color: #47b0e2;
    }
  }

  @import "./sendEmail.scss";

  .about-us__horizontal-divider {
    height: 3px;
    width: 100%;

    margin-top: 45px;
    margin-bottom: 45px;

    background-color: rgb(44, 44, 44);
  }

  @import "./faq.scss";
}

// MEDIA AREA

@mixin mediaAboutUs($fontSize1, $paddingBottom1) {
  .about-us {
    .about-us__page-title {
      font-size: $fontSize1;
      padding-bottom: $paddingBottom1;
    }

    .about-us__horizontal-divider {
    }
  }
}

@media (max-width: 991px) {
  @include mediaAboutUs(
    $fontSize1: var(--major-title-fontSize),
    $paddingBottom1: 35px
  );
}

@media (max-width: 768px) {
  @include mediaAboutUs(
    $fontSize1: var(--major768-title-fontSize),
    $paddingBottom1: 30px
  );
}

@media (max-width: 480px) {
  @include mediaAboutUs(
    $fontSize1: var(--major480-title-fontSize),
    $paddingBottom1: 20px
  );
}
