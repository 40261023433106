.display__album-info {
  margin: 30px 40px;
  .album-info__header {
    .header__title {
      font-family: var(--title-description-fontFamily);
      font-size: var(--title-description-fontSize);
      margin-bottom: 0;
    }
  }

  .album-info__content {
    .album-info__content-ecard {
      height: 300px;
      width: 80%;

      .content-ecard__wrapper {
        img {
          margin: 10px;
          border: 5px solid gold;
        }
      }
    }

    .content-ecard__header {
      margin-top: 20px;
      margin-bottom: 20px;

      .header__title {
        font-family: var(--title-description-fontFamily);
        font-size: var(--title-description-fontSize);
        margin-bottom: 0;
      }
      .media-list-total {
        font-family: var(--all-description-fontFamily);
        font-size: var(--all-description-fontSize);
      }
    }

    .content-ecard__album {
      height: 320px;
      width: 90%;
      border: 2px solid var(--color-disabled);
      font-size: 14px;

      .content-ecard__album-notification {
        height: 320px;
        color: rgb(201, 201, 201);
      }
    }
  }
}

//MEDIA AREA

@media (max-width: 991px) {
  .display__album-info {
    margin: 30px 30px;
    .album-info__header {
      .header__title {
        font-size: var(--title-description-fontSize);
      }
    }

    .album-info__content {
      .album-info__content-ecard {
        .content-ecard__wrapper {
          img {
          }
        }
      }

      .content-ecard__header {
        margin-top: 20px;
        margin-bottom: 20px;

        .header__title {
          font-size: var(--title-description-fontSize);
        }
        .media-list-total {
          font-size: var(--all-description-fontSize);
        }
      }

      .content-ecard__album {
        font-size: 14px;

        .content-ecard__album-notification {
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .display__album-info {
    margin: 30px 30px;
    .album-info__header {
      .header__title {
        font-size: var(--title768-description-fontSize);
      }
    }

    .album-info__content {
      .album-info__content-ecard {
        .content-ecard__wrapper {
          img {
          }
        }
      }

      .content-ecard__header {
        margin-top: 20px;
        margin-bottom: 10px;

        .header__title {
          font-size: var(--title768-description-fontSize);
          margin-bottom: 0;
        }
        .media-list-total {
          font-size: var(--all768-description-fontSize);
        }
      }

      .content-ecard__album {
        font-size: 13px;

        .content-ecard__album-notification {
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .display__album-info {
    margin: 30px 15px;
    .album-info__header {
      .header__title {
        font-size: var(--title480-description-fontSize);
      }
    }

    .album-info__content {
      .album-info__content-ecard {
        .content-ecard__wrapper {
          img {
          }
        }
      }

      .content-ecard__header {
        margin-top: 20px;
        margin-bottom: 0px;

        .header__title {
          font-size: var(--title480-description-fontSize);
          margin-bottom: 0;
        }
        .media-list-total {
          font-size: var(--all480-description-fontSize);
        }
      }

      .content-ecard__album {
        font-size: 12px;

        .content-ecard__album-notification {
        }
      }
    }
  }
}
