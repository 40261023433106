.modal-content{

    .modal-header{

        p{

            font-family: var(--all-description-fontFamily);
            font-size: var(--all-description-fontSize);
            color: #838181;

            span{
                cursor: pointer;
                color: #37B3F6;
                text-decoration: underline;
            }
        }
    }

    .modal-body{

        .errorMessage{
            font: var(--text-size-xs);
            color: var(--color-failure);
        }

    }

    .modal-footer{

        a{
            font-family: var(--all-description-fontFamily);
            font-size: var(--all-description-fontSize);
            color: #37B3F6;
        }
    }
}