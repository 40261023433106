.component-color-picker {
    position: relative;

    z-index: 6;

    .component-color-picker__label {
        padding-right: 9px;
        white-space: nowrap;
    }
    
    .component-color-picker__color-component {
        position: absolute;
        bottom: 100%;
        left: 0;
    }

    .component-color-picker__trigger-wrapper {
        border-radius: 5px;
        border: 1px solid black;
        background-color: white;

        padding: 5px;

        width: 100px;
        height: 38px;

        cursor: pointer;

        .trigger-wrapper__trigger {
            border-radius: 5px;
    
            width: 100%;
            height: 100%;
        }
    }
}

.component-color-picker__tooltip {
    .arrow {
        display: none !important;
    }

    .tooltip-inner {
        background-color: transparent;
    }
}