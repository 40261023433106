@mixin flexBox(
  $display,
  $flex-directions: row,
  $justify-content: null,
  $align-items: null,
  $flex: null
) {
  display: $display;
  flex-direction: $flex-directions;
  justify-content: $justify-content;
  align-items: $align-items;
  flex: $flex;
}

@mixin containerMap(
  $height: null,
  $margin: null,
  $width: null,
  $font-size: null,
  $text-align: null,
  $line-height: null
) {
  height: $height;
  margin: $margin;
  width: $width;
  font-size: $font-size;
  text-align: $text-align;
  line-height: $line-height;
}

.mapContainer {
  @include flexBox(
    $display: flex,
    $flex-directions: column,
    $justify-content: flex-start,
    $align-items: center
  );

  @include containerMap($height: 100%, $margin: 10px 0px 10px 0px, $width: 80%);

  .frameDiv {
    @include containerMap(
      $height: 100%,
      $margin: 0px 0px 10px 0px,
      $width: 100%
    );

    iframe {
      @include containerMap($height: 100%, $width: 100%);
    }
  }

  .location__menu {
    @include flexBox(
      $display: flex,
      $flex-directions: row,
      $justify-content: center,
      $align-items: center
    );

    @include containerMap($margin: 0px, $width: 100%);

    height: 12%;
    box-shadow: rgba(0, 0, 0, 0.6) 0px 1.95px 4px;
    background-color: rgba($color: #ffffff, $alpha: 0.9);

    .lokasi__icon {
      line-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      a {
        @include containerMap($font-size: 14px, $margin: 0);
        margin-top: 2px;
      }

      .d {
        @include containerMap($margin: 0, $text-align: center, $font-size: 9px);
      }
    }
  }
}

//animation
// .lokasi__icon a {
//   animation: animationlokasi 2s ease-out;
// }

//MEDIA AREA

@mixin mediaMap($fontsize1, $fontsize2, $line-height, $width) {
  .mapContainer {
    .frameDiv {
      width: $width;
    }

    .location__menu {
      .lokasi__icon {
        line-height: $line-height;
        a {
          font-size: $fontsize1; //font size icon
        }

        .d {
          font-size: $fontsize2; // font size descp
        }
      }
    }
  }
}

@media (min-width: 393px) {
  @include mediaMap(
    $fontsize1: 16px,
    $fontsize2: 11px,
    $line-height: 110%,
    $width: 100%
  );
}

@media (min-width: 482px) {
  @include mediaMap(
    $fontsize1: 18px,
    $fontsize2: 13px,
    $line-height: 120%,
    $width: 100%
  );
}

@media (min-width: 576px) {
  @include mediaMap(
    $fontsize1: 20px,
    $fontsize2: 15px,
    $line-height: 130%,
    $width: 100%
  );
}

@media (min-width: 768px) {
  @include mediaMap(
    $fontsize1: 22px,
    $fontsize2: 17px,
    $line-height: 140%,
    $width: 100%
  );
}
