.component-order {
  .order-card {
    margin: 10px 30px;
    img {
      margin: 0px;
      border: 5px solid rgb(250, 174, 32);
    }
  }
  .order-details {
    font-family: Poppins;
    font-size: 14px;
    padding: 5px 30px 10px 0px;
    word-break: break-all;
    width: 300px;

    Form {
      .card-name__container {
        font-size: 14px;
        background-color: rgb(250, 250, 250);
        margin-bottom: 5px !important;

        .card-name__input {
          font-size: 14px;
          border: none;
          outline: none;
          margin: 0;
          background-color: rgb(250, 250, 250);
          padding-left: 3px !important;

          .component-input__label {
            margin: 0;
            font-weight: 600;
          }
        }

        .card-name__edit {
          margin: 0;
          height: 34px;
          padding: 0px 10px !important;
          opacity: 0.8;
          outline: none;
          border: none;
          border-radius: 0;

          .edit__icon {
            color: blue;
          }
        }
      }
    }

    p,
    a {
      margin-bottom: 8px;
    }

    .component-order__action {
      margin-top: 10px;

      .component-order__action-wrapper {
        .action__edit {
          margin: 2px;
          height: 35px;
          width: 60%;
          border-radius: 6px;
        }
        .action__delete {
          margin: 2px;
          padding: 12px;
          background-color: rgb(248, 248, 248);
          height: 35px;
          border-radius: 6px;
        }
      }
      .action__pay {
        margin: 5px 2px;
        height: 40px;
        border-radius: 6px;
      }
    }

    .completed-order {
      .action__copy {
        margin: 2px;
        height: 35px;
        width: 50%;
        border-radius: 6px;
      }
      .action__edit {
        margin: 2px;
        height: 35px;
        width: 50%;
        border-radius: 6px;
      }
    }
  }
}

//MEDIA AREA

@media (max-width: 991px) {
  .component-order {
    .order-card {
      margin: 10px 30px;
      img {
        border: 5px solid rgb(250, 174, 32);
      }
    }
    .order-details {
      font-size: 14px;
      padding: 5px 30px 10px 0px;

      Form {
        .card-name__container {
          font-size: 14px;
          margin-bottom: 5px !important;

          .card-name__input {
            font-size: 14px;
            padding-left: 3px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .component-order {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .order-card {
      margin: 5px 30px 0 30px;
      img {
        border: 5px solid rgb(250, 174, 32);
      }
    }

    .order-details {
      font-size: 14px;
      padding: 0px 30px 30px 0px;

      Form {
        .card-name__container {
          font-size: 14px;
          margin-bottom: 5px !important;

          .card-name__input {
            font-size: 14px;
            padding-left: 3px !important;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .component-order {
    width: 260px;
    .order-card {
      margin: 5px 10px 0 10px;
      img {
        border: 5px solid rgb(250, 174, 32);
      }
    }

    .order-details {
      width: 260px;
      font-size: 12px;
      margin: 0px 0px 10px 0px;

      Form {
        .card-name__container {
          font-size: 12px;
          margin-bottom: 5px !important;

          .card-name__input {
            font-size: 12px;
            padding-left: 3px !important;
          }
        }
      }
    }
  }
}
