.payment {
  .payment__page-title {
    font-family: var(--major-title-fontFamily);
    font-size: var(--major-title-fontSize);
    font-weight: bold;

    padding-bottom: 40px;
    color: var(--color-forTitle);

    .EkadTepi {
      color: #47b0e2;
    }
  }

  form {
    max-width: 577px;
    //background-color: red;
  }

  .payment__amount {
    border-top: 2px solid #000000;
    border-bottom: 2px solid #000000;
    font-family: Tienne;
    padding: 10px 0;

    .total-amount {
      margin-left: 10px;
      font-size: 16px;
    }
    .the-amount {
      margin-right: 10px;
      font-size: 32px;
    }
  }

  //for label and input styling
  .component-input__label {
    font-family: Tienne;
    font-size: var(--all-input-fontSize);
    margin-left: 3px;
  }

  .component-input__input {
    //border-radius: 10px;
    border: 2px solid #e9e9e9;
  }

  .payment__billing-info {
    font-family: var(--all-input-fontFamily);
    font-size: var(--all-input-fontSize);

    .payment-id {
      margin-top: 20px;

      .payment-for-order {
        padding: 5px 10px;
        font-family: Poppins;
        font-size: 18px;
        background-color: rgba(255, 255, 0, 0.3);
      }
    }

    .billing-info__fullname {
      margin: 15px 0px;
      margin-top: 20px;
    }

    .tel-and-email {
      margin: 15px 0px;
      margin-top: 0px;
      width: 100%;
      .billing-info__phone {
        margin-right: 15px;
        margin-bottom: 0px;
        //width: 100px !important;

        .selected-flag {
          margin-left: 10px;
        }

        input {
          width: 200px;
        }
        .Phone__input {
          padding: 0.375rem 3.2rem;
          padding-right: 0rem;
        }
      }

      .billing-info__email {
        margin-bottom: 0px;
        width: 100%;
      }
    }

    .payment-info__address1 {
      margin: 15px 0px;
      margin-top: 0px;
      width: 100%;

      .address1__street {
        margin-bottom: 0px;
        margin-right: 15px;
        width: 60%;
      }

      .address1__city {
        margin-bottom: 0px;
        width: 40%;
      }
    }

    .personal-info__address2 {
      .address2__postcode {
        margin-bottom: 0px;
        margin-right: 15px;
      }
      .address2__state {
        margin-bottom: 0px;
      }
      .address2__origin {
        margin-bottom: 0px;
        margin-left: 15px;
      }
    }
  }
}

.payment__method {
  //background-color: #e9e9e9;
  margin-top: 20px;
  padding: 10px 0;
  .payment__method-selection {
    font-family: Tienne;
    font-size: 18px;
    margin: 10px 0;

    .payment__method-selection-list {
      .selections__item-wrapper {
        color: #ffffff;
        width: 160px;
        margin-left: 15px;

        .selections__item {
          cursor: pointer;
          .item__header {
            padding: 5px 10px;
            font-size: 16px;
          }
        }

        .selection__item_selected {
          color: rgb(255, 255, 255);
          background: rgb(0, 68, 85);
        }

        .selection__item_unselected {
          background: rgb(255, 255, 255);
          color: rgb(0, 68, 85);
          border: 1px solid rgb(0, 68, 85);
        }

        .card {
          pointer-events: none;
          background-color: #e9ecef;
          color: #8d8d8d;
          border: none;
        }
      }
    }
  }
}

.payment-button {
  margin: 5px 0;
  .payment__button-back {
    width: 50%;
    margin: 0 10px;
    color: rgb(0, 185, 0);
    border: 1px solid rgb(0, 185, 0);
  }

  .payment__button-pay {
    width: 50%;
    margin: 0 10px;
    background-color: rgb(0, 185, 0);
    border: 1px solid rgb(0, 185, 0);
  }
}

@import "./creditCard.scss";
@import "./onlineBanking.scss";
@import "./loading.scss";

//MEDIA AREA

@media (max-width: 991px) {
  .payment {
    .payment__page-title {
      font-size: var(--major-title-fontSize);
      padding-bottom: 35px;
    }

    form {
      max-width: 577px;
    }

    .payment__amount {
      padding: 10px 0;

      .total-amount {
        margin-left: 10px;
        font-size: 16px;
      }
      .the-amount {
        margin-right: 10px;
        font-size: 32px;
      }
    }

    //for label and input styling
    .component-input__label {
      font-size: var(--all-input-fontSize);
      margin-left: 3px;
    }

    .payment__billing-info {
      font-size: var(--all-input-fontSize);

      .payment-id {
        margin-top: 20px;

        .payment-for-order {
          padding: 5px 10px;
          font-size: 18px;
        }
      }

      .billing-info__fullname {
        margin: 15px 0px;
        margin-top: 20px;
      }

      .tel-and-email {
        margin: 15px 0px;
        margin-top: 0px;
        width: 100%;

        .billing-info__phone {
          margin-right: 15px;
          margin-bottom: 0px;

          .selected-flag {
            margin-left: 10px;
          }

          input {
            width: 200px;
          }
          .Phone__input {
            padding: 0.375rem 3.2rem;
            padding-right: 0rem;
          }
        }

        .billing-info__email {
          margin-bottom: 0px;
          width: 100%;
        }
      }

      .payment-info__address1 {
        margin: 15px 0px;
        margin-top: 0px;
        width: 100%;

        .address1__street {
          margin-bottom: 0px;
          margin-right: 15px;
          width: 60%;
        }

        .address1__city {
          margin-bottom: 0px;
          width: 40%;
        }
      }

      .personal-info__address2 {
        .address2__postcode {
          margin-bottom: 0px;
          margin-right: 15px;
        }
        .address2__state {
          margin-bottom: 0px;
        }
        .address2__origin {
          margin-bottom: 0px;
          margin-left: 15px;
        }
      }
    }
  }

  .payment__method {
    margin-top: 20px;
    padding: 10px 0;

    .payment__method-selection {
      font-size: 18px;
      margin: 10px 0;

      .payment__method-selection-list {
        .selections__item-wrapper {
          width: 160px;
          margin-left: 15px;

          .selections__item {
            .item__header {
              padding: 5px 10px;
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .payment-button {
    margin: 5px 0;
    .payment__button-back {
      margin: 0 10px;
    }

    .payment__button-pay {
      margin: 0 10px;
    }
  }
}

@media (max-width: 768px) {
  .payment {
    .payment__page-title {
      font-size: var(--major768-title-fontSize);
      padding-bottom: 30px;
    }

    form {
      max-width: 577px;
    }

    .payment__amount {
      padding: 8px 0;

      .total-amount {
        margin-left: 10px;
        font-size: 15px;
      }
      .the-amount {
        margin-right: 10px;
        font-size: 30px;
      }
    }

    //for label and input styling
    .component-input__label {
      font-size: var(--all768-input-fontSize);
      margin-left: 3px;
    }

    .component-input__input {
      font-size: var(--all768-input-fontSize);
    }

    .payment__billing-info {
      font-size: var(--all768-input-fontSize);

      .payment-id {
        margin-top: 20px;

        .payment-for-order {
          padding: 5px 10px;
          font-size: 16px;
        }
      }

      .billing-info__fullname {
        margin: 15px 0px;
        margin-top: 20px;
      }

      .tel-and-email {
        margin: 15px 0px;
        margin-top: 0px;
        width: 100%;

        .billing-info__phone {
          margin-right: 15px;
          margin-bottom: 0px;

          .selected-flag {
            margin-left: 10px;
          }

          input {
            width: 200px;
          }
          .Phone__input {
            padding: 0.375rem 3.2rem;
            padding-right: 0rem;
          }
        }

        .billing-info__email {
          margin-bottom: 0px;
          width: 100%;
        }
      }

      .payment-info__address1 {
        margin: 15px 0px;
        margin-top: 0px;
        width: 100%;

        .address1__street {
          margin-bottom: 0px;
          margin-right: 15px;
          width: 60%;
        }

        .address1__city {
          margin-bottom: 0px;
          width: 40%;
        }
      }

      .personal-info__address2 {
        .address2__postcode {
          margin-bottom: 0px;
          margin-right: 15px;
        }
        .address2__state {
          margin-bottom: 0px;
        }
        .address2__origin {
          margin-bottom: 0px;
          margin-left: 15px;
        }
      }
    }
  }

  .payment__method {
    margin-top: 15px;
    padding: 8px 0;

    .payment__method-selection {
      font-size: 16px;
      margin: 8px 0;

      .payment__method-selection-list {
        .selections__item-wrapper {
          width: 150px;
          margin-left: 10px;

          .selections__item {
            .item__header {
              padding: 6px 10px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .payment-button {
    margin: 5px 0;
    .payment__button-back {
      margin: 0 6px;
    }

    .payment__button-pay {
      margin: 0 6px;
    }
  }
}

@media (max-width: 480px) {
  .payment {
    .payment__page-title {
      font-size: var(--major480-title-fontSize);
      padding-bottom: 30px;
    }

    form {
      width: 300px;
    }

    .payment__amount {
      padding: 6px 0;

      .total-amount {
        margin-left: 10px;
        font-size: 13px;
      }
      .the-amount {
        margin-right: 10px;
        font-size: 26px;
      }
    }

    //for label and input styling
    .component-input__label {
      font-size: var(--all480-input-fontSize);
      margin-left: 3px;
    }

    .component-input__input {
      font-size: var(--all480-input-fontSize);
    }

    .payment__billing-info {
      font-size: var(--all480-input-fontSize);

      .payment-id {
        margin-top: 20px;

        .payment-for-order {
          padding: 5px 10px;
          font-size: 14px;
        }
      }

      .billing-info__fullname {
        margin: 15px 0px;
        margin-top: 20px;
      }

      .tel-and-email {
        margin: 15px 0px;
        margin-top: 0px;
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .billing-info__phone {
          margin: 15px 0px;
          margin-top: 0px;
          width: 100%;

          .selected-flag {
            margin-left: 10px;
          }

          input {
            width: 100%;
          }

          .Phone__input {
            padding: 0.375rem 3.2rem;
            padding-right: 0rem;
          }
        }

        .billing-info__email {
          margin-bottom: 0px;
          width: 100%;
        }
      }

      .payment-info__address1 {
        margin: 15px 0px;
        margin-top: 0px;
        width: 100%;

        .address1__street {
          margin-bottom: 0px;
          margin-right: 5px;
          width: 60%;
        }

        .address1__city {
          margin-bottom: 0px;
          width: 40%;
        }
      }

      .personal-info__address2 {
        .address2__postcode {
          margin-bottom: 0px;
          margin-right: 5px;
        }
        .address2__state {
          margin-bottom: 0px;
        }
        .address2__origin {
          margin-bottom: 0px;
          margin-left: 5px;
        }
      }
    }
  }

  .payment__method {
    margin-top: 10px;
    padding: 8px 0;

    .payment__method-selection {
      font-size: 14px;
      margin: 8px 10px;

      .payment__method-selection-list {
        display: flex;
        flex-direction: column;

        .selections__item-wrapper {
          width: 140px;
          margin-left: 10px;
          margin-top: 8px;

          .selections__item {
            .item__header {
              padding: 6px 8px;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
