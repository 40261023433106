.component-card {
  margin: 15px 10px;

  .component-card-wrapper {
    .component-card__image-wrapper {
      position: relative;

      .component-card__image {
        width: 240px;
        height: 354px;

        @media (max-width: 991px) {
          width: 200px;
          height: 295px;
        }

        @media (max-width: 768px) {
          width: 200px;
          height: 300px;
        }

        @media (max-width: 480px) {
          width: 180px;
          height: 280px;
        }
      }

      .component-card__name-price_tag {
        position: absolute;
        color: #ff9e7b;
        font-family: var(--minor-title-fontFamily);
        font-size: 16px;
        top: 0;
        left: 0;
        padding: 4px 8px;
        background-color: rgba(255, 255, 255, 0.932);

        &.componenent-card__name-price_tag__hidden {
          display: none;
        }
      }

      .component-card__homepage {
        z-index: 100;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        text-align: center;

        .homepage-title {
          margin: 0;

          .home__text3 {
            font-size: var(--homepage-fontSize);
            text-transform: capitalize;
            font-family: var(--homepage-title-fontFamily);
            letter-spacing: 1px;
          }
        }

        .hompage-name {
          margin: 25px 0px;

          .home__text1 {
            font-size: var(--homepage-name-fontSize);
            line-height: 100%;
            margin: 0;
            font-family: Vollkorn SC;
            letter-spacing: 2px;
            text-transform: uppercase;
          }
        }

        .hompage-date {
          margin: 0;

          .home__text3 {
            font-size: var(--homepage-fontSize);
            font-family: var(--homepage-date-fontFamily);
            text-transform: capitalize;
            letter-spacing: 1px;
          }
        }
      }

      .component-card__action {
        position: absolute;
        bottom: 0px;
        left: 20px;
        right: 20px;
        z-index: 1;

        .component-card__action-wrapper {
          padding: 5px;

          .action__preview {
            padding: 8px 20px;
            margin-right: 2px;

            @media (max-width: 768px) {
              padding: 8px 18px !important;
            }

            @media (max-width: 480px) {
              padding: 8px 16px !important;
            }
          }

          .action__add-to-cart {
            border: 2px solid #47b0e2;
            padding: 8px 20px;
            margin-left: 2px;

            @media (max-width: 768px) {
              padding: 8px 18px !important;
            }

            @media (max-width: 480px) {
              padding: 8px 16px !important;
            }

            .add-to-cart__icon {
              width: 16px;
              height: 16px;

              @media (max-width: 768px) {
                width: 15px;
                height: 15px;
              }

              @media (max-width: 480px) {
                width: 14px;
                height: 14px;
              }
            }
          }
        }

        &.componenent-card__action__hidden {
          display: none;
        }
      }
    }

    .component-card__name {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;

      padding: 6px;

      &.component-card__name__hidden {
        display: none;
      }
    }
  }
}
