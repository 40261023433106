.component-slider-input {
    padding: 10px;

    .component-slider-input__input {
        margin-right: 5px;
    }

    .component-slider-input__label {
        margin-bottom: 0;
    }
}