.component-content-expander {
  @mixin mainDisplay(
    $background-color: white,
    $font-family: inherit,
    $font-size: inherit,
    $color: inherit
  ) {
    .component-content-expander__main-display {
      background: $background-color;
      border: 1px solid #abe4ff;

      padding: 20px 14px;

      cursor: pointer;

      .main-display__text {
        font-family: $font-family;
        font-size: $font-size;
        color: $color;
      }

      .main-display__expander {
        color: $color;
      }
    }
  }

  @mixin content(
    $background-color: white,
    $font-family: inherit,
    $font-size: inherit
  ) {
    .component-content-expander__content {
      background: $background-color;
      border: 1px solid #abe4ff;

      padding: 16px 14px;

      transition: max-height 0.2s, padding 0.2s, border 0.2s, visibility 0s;
      max-height: 900px;

      pointer-events: initial;
      visibility: visible;

      overflow: auto;

      font-family: $font-family;
      font-size: $font-size;

      > * {
        padding-bottom: 17px;
      }

      &.component-content-expander_hide {
        max-height: 0px;
        padding: 0 14px;

        border-top: none;
        border-bottom: none;

        pointer-events: none;
        visibility: hidden;
      }
    }
  }

  @include mainDisplay();
  @include content();

  &.component-content-expander_blue-theme {
    @include mainDisplay(#47b0e2, Poppins, 16px, white);
    @include content(#e3f3fc, Rokkitt, 20px);
  }
  &.component-content-expander_dark-grey-theme {
    @include mainDisplay(#333434, Poppins, 16px, white);
    @include content(#cccccc, Rokkitt, 20px);
  }
}
