.rsvp_component {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  background-color: rgb(236, 236, 236);
  height: 100%;
  width: 100%;
  border-radius: 30px;

  .rsvp_header {
    width: 100%;
    height: 10%;
    padding: 15px;
    background: #ffffff;
    border-radius: 30px 30px 0 0;

    .rsvp_header_back {
      display: flex !important;
      justify-content: flex-start !important;
      width: 20%;
      font-size: 24px;
    }
    .rsvp_header_title {
    
    }

    .rsvp_header_empty {
      width: 20%;
    }
  }

  .rsvp__container {
    padding: 0;
    height: 90%;
    width: 100%;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    

    .sesi__container_center {
      margin: 20px 0px 10px 0px;
      width: 85%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: scroll;
      overflow-x: hidden;
      //border-radius: 20px;
      overscroll-behavior: contain;
      // sorok scrollbar
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Internet Explorer 10+ */

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    }

    .sesi__container_top {
      margin: 20px 0px 10px 0px;
      width: 85%;
      height: 100%;
      display: flex;
      flex-direction: column;
      overflow: scroll;
      overflow-x: hidden;
      //border-radius: 20px;
      overscroll-behavior: contain;
      // sorok scrollbar
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Internet Explorer 10+ */

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }
    }
  }
}

// .sesi__container {
//   animation: animationrsvp 1s linear;
// }
