.fail {
  border: 4px solid #ff2851;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 20px;
}
.fail__body {
  padding: 0 !important;

  .fail-icons {
    width: 100%;
    background-color: #ff2851;
    padding: 40px 80px 30px 80px;
    .fail__body-icon {
      //color: var(--color-primary);
      font-size: 100px;
      color: white;
    }

    .fail__body-headers {
      font-size: 20px;
      font-family: var(--title-description-fontFamily);
      padding: 20px 0 0 0;
      color: rgb(252, 252, 252);
    }
  }

  .fail-details {
    .fail__body-content {
      font-size: var(--all-description-fontSize);
      font-family: var(--all-description-fontFamily);

      .fail__body-content-1 {
        margin: 20px 0;
        width: 400px;

        .p-title {
          font-size: 20px;
          font-weight: bold;
          margin: 10px 40px;
        }

        .p-des {
          font-family: Ropa Sans;
          font-size: 18px;
          margin: 10px 40px;
        }
      }
    }
  }

  .receipt__account-button {
    background-color: #ff2851;
    color: rgb(255, 255, 255);
    border-radius: 10px;
    width: 80%;
    white-space: nowrap;
    outline: none !important;
    cursor: pointer;
    border: none;
    font-family: Poppins;
  }
}

// MEDIA AREA

@mixin mediaFail(
  $padding1,
  $fontSize1,
  $fontSize2,
  $fontSize3,
  $fontSize4,
  $margin1,
  $fontSize5
) {
  .fail__body {
    .fail-icons {
      padding: $padding1;

      .fail__body-icon {
        font-size: $fontSize1;
      }

      .fail__body-headers {
        font-size: $fontSize2;
      }
    }

    .fail-details {
      .fail__body-content {
        font-size: $fontSize3;

        .fail__body-content-1 {
          .p-title {
            font-size: $fontSize4;
            margin: $margin1;
          }

          .p-des {
            font-size: $fontSize5;
            margin: $margin1;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  @include mediaFail(
    $padding1: 30px 60px 20px 60px,
    $fontSize1: 90px,
    $fontSize2: 18px,
    $fontSize3: var(--all-description-fontSize),
    $fontSize4: 20px,
    $margin1: 10px 40px,
    $fontSize5: 18px
  );
}

@media (max-width: 768px) {
  @include mediaFail(
    $padding1: 30px 40px 20px 40px,
    $fontSize1: 90px,
    $fontSize2: 17px,
    $fontSize3: var(--all768-description-fontSize),
    $fontSize4: 18px,
    $margin1: 10px 0px 0 25px,
    $fontSize5: 16px
  );

  .fail-details {
    .fail__body-content {
      .fail__body-content-1 {
        width: 300px !important;
      }
    }
  }
}

@media (max-width: 480px) {
  @include mediaFail(
    $padding1: 20px 20px 10px 20px,
    $fontSize1: 80px,
    $fontSize2: 16px,
    $fontSize3: var(--all480-description-fontSize),
    $fontSize4: 15px,
    $margin1: 10px 0px 0 0px,
    $fontSize5: 14px
  );

  .fail-details {
    .fail__body-content {
      .fail__body-content-1 {
        margin: 10px 0 !important;
        width: 200px !important;
      }
    }
  }
}
