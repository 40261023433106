.camera {
  position: relative;
  width: 100%;
  height: 100%;
  background: black;

  .camera__header {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    padding: 5px;

    width: 100%;

    i {
      color: whitesmoke;
      font-size: 30px;
      margin: 5px;
      cursor: pointer;
    }

    div {
      margin: 0;
      cursor: pointer;
    }
  }

  .camera__footer {
    position: absolute;
    bottom: 0;
    transform: translateY(-80%);
    display: flex;
    justify-content: center;
    padding: 5px;

    width: 100%;

    .capture__icon {
      color: whitesmoke;
      font-size: 40px;
      margin: 0;
      cursor: pointer;
    }

    .switch__camera {
      position: absolute;
      color: whitesmoke;
      font-size: 20px;
      margin: 0;
      bottom: 30%;
      right: 25%;
      border-radius: 18px;
      transform: rotate(180deg);
      transition: transform 0.5s linear 0.1s;
      cursor: pointer;

      &:active {
        transform: rotate(-180deg);
        transition: transform 0s;
      }
    }
  }

  .camera__webcam {
    position: relative;
    width: 100%;
    height: 100%;
    background: black;
  }
}
