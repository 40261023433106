.receipt {
  .receipt__page-title {
    font-family: var(--major-title-fontFamily);
    font-size: var(--major-title-fontSize);
    font-weight: bold;

    padding-bottom: 40px;
    color: var(--color-forTitle);

    .EkadTepi {
      color: #47b0e2;
    }
  }

  .receipt__account-button {
    margin-bottom: 10px;
  }
}

@import "./success.scss";

@import "./fail.scss";

@import "./pending.scss";

// MEDIA AREA

@mixin mediaReceipt($fontSize, $paddingBottom) {
  .receipt {
    .receipt__page-title {
      font-size: $fontSize;
      padding-bottom: $paddingBottom;
    }
  }
}

@media (max-width: 991px) {
  @include mediaReceipt(
    $fontSize: var(--major-title-fontSize),
    $paddingBottom: 40px
  );
}

@media (max-width: 768px) {
  @include mediaReceipt(
    $fontSize: var(--major768-title-fontSize),
    $paddingBottom: 35px
  );
}

@media (max-width: 480px) {
  @include mediaReceipt(
    $fontSize: var(--major480-title-fontSize),
    $paddingBottom: 30px
  );

  .receipt {
    .receipt__page-title {
      width: 80%;
    }
  }
}
