.component-date-picker {
    .component-date-picker__label {
        white-space: nowrap;
    }

    .component-date-picker__input {
        border-radius: 4px;
        height: 36px;

        padding: 0px 12px;

        &:focus-visible {
            outline: none;
        }
    }

    // Updating the third party library
    .react-datepicker__triangle {
        &:before, &:after {
            left: -20px !important;
        }
    }
    .react-datepicker-wrapper {
        width: 100%;
        height: fit-content;
    }
}