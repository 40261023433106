.loading {
    position: relative;


    .loading__payment-form{
        cursor: not-allowed;
        opacity: 0.6;

        * {
            pointer-events:none;
        }
    }

    .loading__notice{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 20%;
        height: 20%;
        background-color: var(--color-disabled);

        .notice-message{
            font-size: var(--title-description-fontSize);
            font-family: var(--title-description-fontFamily);
            color: var(--color-forTitle);
            position: absolute;
        }

        .notice-svgLoader {
            animation: spin 0.75s linear infinite;
            margin: auto;

            circle {
                fill: var(--color-primary);
            }
        }
          
    }
}