@mixin flexBox(
  $display,
  $flex-direction: null,
  $align-items: null,
  $justify-content: null
) {
  display: $display;
  flex-direction: $flex-direction;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin containerMessage(
  $text-align: null,
  $font-size: null,
  $margin-top: null,
  $margin-bottom: null,
  $margin-left: null,
  $margin-right: null,
  $background-color: null,
  $width: null,
  $border-radius: null
) {
  text-align: $text-align;
  font-size: $font-size;
  margin-top: $margin-top;
  margin-bottom: $margin-bottom;
  margin-left: $margin-left;
  margin-right: $margin-right;
  background-color: $background-color;
  width: $width;
  border-radius: $border-radius;
}

.message {
  @include flexBox($display: flex, $align-items: center);

  padding: 5px;
  justify-content: center;

  .message__info {
    @include containerMessage($width: 90%, $border-radius: 10px);
    @include flexBox($display: flex, $flex-direction: column);
    box-shadow: rgba(99, 99, 99, 0.5) 0px 2px 4px 0px;
    background-color: white;

    .message__header {
      @include containerMessage(
        $margin-top: 6px,
        $margin-bottom: 2px,
        $margin-right: 3px,
        $margin-left: 8px
      );
      @include flexBox($display: flex, $align-items: flex-start);

      .MuiAvatar-root {
        width: 30px;
        height: 30px;
        border: 1px solid rgb(214, 214, 214);
        border-radius: 50%;
      }

      .message__headerInfo {
        @include containerMessage($margin-left: 6px, $margin-right: 5px);
        flex: 1;
        font-family: "Poppins", sans-serif;

        .message__from {
          color: #3a3a3a;
          font-size: 10px;
          font-weight: 500;
        }

        .message__relationship {
          color: #5e5e5e;
          font-size: 8px;
        }
      }
    }

    .message__text {
      @include containerMessage(
        $text-align: left,
        $font-size: 9.5px,
        $margin-top: 4px,
        $margin-bottom: 0,
        $margin-right: 10px,
        $margin-left: 12px
      );

      color: #2b2b2b;
      font-family: "Poppins", sans-serif;

      p {
        margin: 0;
        white-space: normal;
      }
    }

    .message__timestamp {
      @include flexBox($display: flex, $justify-content: flex-end);
      @include containerMessage(
        $font-size: 7.5px,
        //size tarikh
        $margin-bottom: 2px,
        $margin-right: 10px,
        $margin-left: 0px
      );
      color: rgb(180, 180, 180);
      font-family: "Poppins", sans-serif;
    }
  }
}

//MEDIA AREA

@mixin mediaMessage(
  $padding1,
  $border-radius,
  $margin1,
  $avatar,
  $margin2,
  $fontsize1,
  $fontsize2,
  $margin3,
  $fontsize3,
  $margin4,
  $fontsize4
) {
  .message {
    padding: $padding1;

    .message__info {
      border-radius: $border-radius;

      .message__header {
        margin: $margin1;

        .MuiAvatar-root {
          width: $avatar;
          height: $avatar;
        }

        .message__headerInfo {
          margin: $margin2;

          .message__from {
            font-size: $fontsize1;
          }

          .message__relationship {
            font-size: $fontsize2;
          }
        }
      }

      .message__text {
        margin: $margin3;
        font-size: $fontsize3;
      }

      .message__timestamp {
        margin: $margin4;
        font-size: $fontsize4;
      }
    }
  }
}

@media (min-width: 393px) {
  @include mediaMessage(
    $padding1: 7px,
    $border-radius: 12px,
    $margin1: 8px 12px,
    // header margin -avatar & nama
    $avatar: 34px,
    $margin2: 0 8px,
    $fontsize1: 11px,
    $fontsize2: 9px,
    $margin3: 0px 12px,
    // ucapan margin
    $fontsize3: 10.5px,
    $margin4: 2px 12px,
    $fontsize4: 8.5px
  );
}

@media (min-width: 482px) {
  @include mediaMessage(
    $padding1: 7px,
    $border-radius: 14px,
    $margin1: 10px 14px,
    // header margin -avatar & nama
    $avatar: 38px,
    $margin2: 0 10px,
    $fontsize1: 13px,
    $fontsize2: 11px,
    $margin3: 0px 14px,
    // ucapan margin
    $fontsize3: 12.5px,
    $margin4: 3px 13px 2px 13px,
    $fontsize4: 10.5px
  );
}

@media (min-width: 576px) {
  @include mediaMessage(
    $padding1: 9px,
    $border-radius: 16px,
    $margin1: 12px 16px,
    // header margin -avatar & nama
    $avatar: 44px,
    $margin2: 0 12px,
    $fontsize1: 15px,
    $fontsize2: 13px,
    $margin3: 0px 16px,
    // ucapan margin
    $fontsize3: 14.5px,
    $margin4: 4px 14px 2px 14px,
    $fontsize4: 12.5px
  );
}

@media (min-width: 768px) {
  @include mediaMessage(
    $padding1: 11px,
    $border-radius: 18px,
    $margin1: 14px 18px,
    // header margin -avatar & nama
    $avatar: 50px,
    $margin2: 0 14px,
    $fontsize1: 17px,
    $fontsize2: 15px,
    $margin3: 0px 18px,
    // ucapan margin
    $fontsize3: 16.5px,
    $margin4: 5px 15px 2px 15px,
    $fontsize4: 14.5px
  );
}
