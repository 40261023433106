.component-input {
    .component-input__label {
        white-space: nowrap;
    }

    .component-input__input {

    }

    .component-input__error-msg {

    }
}