.pending {
  border: 4px solid #f0d800;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 20px;
}
.pending__body {
  padding: 0 !important;

  .pending-icons {
    width: 100%;
    background-color: #f0d800;
    padding: 40px 80px 30px 80px;
    .pending__body-icon {
      //color: var(--color-primary);
      font-size: 100px;
      color: white;
    }

    .pending__body-headers {
      font-size: 20px;
      font-family: var(--title-description-fontFamily);
      padding: 20px 0 0 0;
      color: rgb(252, 252, 252);
    }
  }

  .pending-details {
    .pending__body-content {
      font-size: var(--all-description-fontSize);
      font-family: var(--all-description-fontFamily);

      .pending__body-content-1 {
        margin: 20px 0;
        width: 400px;

        p {
          font-family: Ropa Sans;
          margin: 10px;
          font-size: 18px;
        }
      }
    }
  }

  .receipt__account-button {
    background-color: #f0d800;
    color: rgb(255, 255, 255);
    border-radius: 10px;
    width: 80%;
    white-space: nowrap;
    outline: none !important;
    cursor: pointer;
    border: none;
    font-family: Poppins;
  }
}

// MEDIA AREA

@mixin mediaPending(
  $padding1,
  $fontSize1,
  $fontSize2,
  $padding2,
  $margin1,
  $fontSize3
) {
  .pending__body {
    .pending-icons {
      padding: $padding1;
      .pending__body-icon {
        font-size: $fontSize1;
      }

      .pending__body-headers {
        font-size: $fontSize2;
        padding: $padding2;
      }
    }

    .pending-details {
      .pending__body-content {
        .pending__body-content-1 {
          p {
            margin: $margin1;
            font-size: $fontSize3;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  @include mediaPending(
    $padding1: 30px 60px 20px 60px,
    $fontSize1: 90px,
    $fontSize2: 18px,
    $padding2: 20px 0 0 0,
    $margin1: 10px 20px,
    $fontSize3: 18px
  );

  .pending-details {
    .pending__body-content {
      .pending__body-content-1 {
        margin: 10px 0 !important;
      }
    }
  }
}

@media (max-width: 768px) {
  @include mediaPending(
    $padding1: 30px 40px 20px 40px,
    $fontSize1: 90px,
    $fontSize2: 17px,
    $padding2: 20px 0 0 0,
    $margin1: 10px 20px,
    $fontSize3: 16px
  );

  .pending-details {
    .pending__body-content {
      .pending__body-content-1 {
        margin: 10px 0 !important;
        width: 300px !important;
      }
    }
  }
}

@media (max-width: 480px) {
  @include mediaPending(
    $padding1: 20px 20px 10px 20px,
    $fontSize1: 80px,
    $fontSize2: 17px,
    $padding2: 20px 0 0 0,
    $margin1: 10px 20px,
    $fontSize3: 14px
  );

  .pending-details {
    .pending__body-content {
      .pending__body-content-1 {
        margin: 10px 0 !important;
        width: 200px !important;
      }
    }
  }
}
