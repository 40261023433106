.calendarmodal {

  .modal-footer{

    .btn{

      a {
        color: #ffffff;
        text-decoration: none;
    
        &:hover {
          text-decoration: none;
        }
      }

    }
  }
  
}
