.blinking-cursor:after {
  content: "|";
  animation: blink 1s step-start infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

// .typewriterIn {
//   animation: word 10s ease-in-out;
// }

// @keyframes word {
//   100% {
//     opacity: 1;
//   }
// }
