.forgot-password {
  height: 48vh;

  .forgot-password__page-title {
    font-family: var(--major-title-fontFamily);
    font-size: var(--major-title-fontSize);
    font-weight: bold;

    padding-bottom: 20px;

    color: var(--color-forTitle);
  }

  .forgot-password__note {
    padding: 15px;

    p {
      font-size: 15px;
      font-family: Poppins;
      margin: 0;
      padding: 10px 20px;
      background-color: rgb(255, 255, 232);
    }
  }

  Form {
    font-family: Poppins;
    font-size: 16px;
    position: relative;
    max-width: 450px;
    width: 80%;

    .forgot-password__email {
      border-bottom: 2px solid #47b0e2;
      border-radius: 0;
      margin-bottom: 20px;
      padding: 0;

      .email__label {
        padding-left: 6px;
        margin: 0;
      }

      .email__input {
        border: none;
        outline: none;
        box-shadow: none;
        font-size: 14px;

        position: relative;
      }

      .component-input__error-msg {
        width: fit-content;
        position: absolute;
        text-align: right;
        right: 7%;
        top: 7%;
      }
    }

    .forgot-password__button {
    }
  }

  p {
    font-size: 14px;
    font-family: Poppins;
    margin-top: 20px;
    background-color: blanchedalmond;
    padding: 10px;
  }
}

// MEDIA AREA

@mixin mediaForgotPassword(
  $fontSize1,
  $fontSize2,
  $fontSize3,
  $right,
  $fontSize4
) {
  .forgot-password {
    .forgot-password__page-title {
      font-size: $fontSize1;
    }

    .forgot-password__note {
      p {
        font-size: $fontSize2;
      }
    }

    Form {
      font-size: $fontSize3;

      .forgot-password__email {
        .component-input__error-msg {
          right: $right;
        }
        .email__input {
          font-size: $fontSize4;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  @include mediaForgotPassword(
    $fontSize1: var(--major-title-fontSize),
    $fontSize2: 15px,
    $fontSize3: 16px,
    $right: 8%,
    $fontSize4: 14px
  );
}

@media (max-width: 768px) {
  @include mediaForgotPassword(
    $fontSize1: var(--major768-title-fontSize),
    $fontSize2: 15px,
    $fontSize3: 16px,
    $right: 10%,
    $fontSize4: 14px
  );
}

@media (max-width: 480px) {
  @include mediaForgotPassword(
    $fontSize1: var(--major480-title-fontSize),
    $fontSize2: 13.5px,
    $fontSize3: 15px,
    $right: 12%,
    $fontSize4: 13px
  );
}
