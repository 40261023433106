// (design1) for background theme image
.general_background {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.general_background img,
.general_background picture {
  height: 100%;
  width: 100%;
}

// (design2) for white background card
.white_background {
  flex: 1;
  position: absolute;
  padding: 5px;
  height: 85%;
  width: 70%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f6f6f6;
}

// upper container
.container__upper {
  display: flex;
  flex-direction: column;
  flex: 1;

  margin: 0;
}

// (design7) untuk semua component header
.header {
  margin-top: 20px;
  margin-bottom: 0px;
  text-align: center;
}

//font header tajuk
#tajuk {
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  margin: 0px;
  font-weight: 600;
}

//MEDIA AREA

@mixin mediaheader($font-size) {
  #tajuk {
    font-size: $font-size;
  }
}

@media (min-width: 393px) {
  @include mediaheader($font-size: 18px);
}

@media (min-width: 482px) {
  @include mediaheader($font-size: 20px);
}

@media (min-width: 576px) {
  @include mediaheader($font-size: 22px);
}

@media (min-width: 768px) {
  @include mediaheader($font-size: 24px);
}
