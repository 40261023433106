.home__highlight {
  width: 100%;
  height: 722px;

  .highlight__bg {
    position: absolute;
    top: 0px;

    width: 100%;
    max-width: 1440px;
    height: 722px;
  }

  .highlight__details {
    position: relative;
    z-index: 1;

    .details__header {
      width: fit-content;
    }

    .details__description {
      width: fit-content;
    }

    .details__action {
    }
  }

  .highlight__sample {
    position: relative;
    z-index: 1;

    .sample__card {
      position: absolute;
      top: 0px;
      left: calc(50% - 147px);
      z-index: -1;

      border-radius: 25px;

      width: 294px;
      height: 573px;
    }

    .sample__mobile-frame {
      width: 294px;
      height: 573px;
    }
  }
}

// MEDIA AREA

@mixin mediaHighlight($height1, $height2) {
  .home__highlight {
    height: $height1;

    .highlight__bg {
      height: $height2;
    }
  }
}

@media (max-width: 991px) {
  @include mediaHighlight($height1: 500px, $height2: 500px);
}

@media (max-width: 768px) {
  @include mediaHighlight($height1: 350px, $height2: 350px);
}

@media (max-width: 480px) {
  @include mediaHighlight($height1: 550px, $height2: 550px);
}
