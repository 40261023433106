.closeButton{
    z-index: 11;
    position: absolute;
    top: 10px;
    left: 20px;

    //need to use media?
    font-size: 24px;

    &:hover{
        cursor: pointer;
    }
}