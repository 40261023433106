.update-e-card {
  .update-e-card__page-title {
    font-family: var(--major-title-fontFamily);
    font-size: var(--major-title-fontSize);
    font-weight: bold;

    padding-bottom: 40px;
    color: var(--color-forTitle);

    position: relative;

    .EkadTepi {
      color: #47b0e2;
    }
  }

  .update-e-card__navigation-steps {
    padding-bottom: 30px;

    .navigation-steps__item-wrapper {
      padding-right: 16px;
      padding-bottom: 18px;

      > div {
        &:last-child {
          padding-right: 0;
          padding-bottom: 0;
        }
      }

      .navigation-steps__item {
        width: 80px;

        .item__number {
          font-family: Poppins;
          font-size: 20px;

          background-color: white;
          box-shadow: rgba(71, 176, 226, 0.5) 0px 0px 8px;
          border-radius: 50%;

          width: 50px;
          height: 50px;

          cursor: pointer;

          &.item__number_current {
            background-color: #47b0e2;
            color: white;
          }
        }

        .item__name {
          font-family: Poppins;
          font-size: 16px;
        }
      }
    }
  }

  .update-e-card__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .content__user-inputs {
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .user-inputs__inputs {
        position: relative;

        margin-bottom: 40px;

        .inputs__shimmer {
          background-color: #ddd;

          width: 430px;
          max-width: 90vw;
          min-height: 100px;
        }

        @import "./inputs/coverInputs.scss";
        @import "./inputs/contentInputs.scss";
        @import "./inputs/contactInputs.scss";
        @import "./inputs/eventInputs.scss";
        @import "./inputs/tentativeInputs.scss";
        @import "./inputs/locationInputs.scss";
        @import "./inputs/wishInputs.scss";
        @import "./inputs/galleryInputs.scss";

        .preview__complete-step {
          position: relative;

          width: 450px;
          max-width: 100vw;
          height: 750px;
          max-height: 100vh;

          // box-shadow: 2px 2px 10px 5px grey;
          border: 1px solid black;

          @import "../ECard/general.scss";
          @import "../ECard/home.scss";
        }
      }

      .user-inputs__submit {
        .submit__discard-changes,
        .submit__save-changes {
          margin: 0 20px;
        }
      }
    }

    .content__preview {
      position: relative;
      overflow: hidden;

      width: 450px;
      max-width: 100vw;
      height: 750px;
      max-height: 100vh;

      // box-shadow: 2px 6px 8px 4px grey;
      border: 1px solid black;

      &.content__preview_no-shadow {
        box-shadow: none;
        border: none;
      }

      @import "../ECard/general.scss";

      @import "../ECard/home.scss";
      @import "../ECard/content.scss";
      @import "../ECard/contact.scss";
      @import "../ECard/countdown.scss";
      @import "../ECard/details.scss";
      @import "../ECard/gallery.scss";
      @import "../ECard/location.scss";
      @import "../ECard/mapContainer.scss";
      @import "../ECard/rsvp.scss";
      @import "../ECard/tentative.scss";
      @import "../ECard/wish.scss";

      .preview__shimmer {
        background-color: #ddd;
        width: 100%;
        height: 100%;
      }

      .actions__complete-step {
        width: 100%;
        height: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .complete-step__back {
          margin-bottom: 80px;
        }

        .complete-step__pay {
        }
      }
    }
  }
}

// MEDIA AREA

@media (max-width: 991px) {
  .update-e-card {
    .update-e-card__content {
      .content__preview {
        &.content__preview_no-shadow {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;
          height: 150px;
        }

        .actions__complete-step {
          margin-top: 30px;
          height: 120px;

          .complete-step__back {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .update-e-card {
    .update-e-card__page-title {
      font-size: var(--major768-title-fontSize);
      padding-bottom: 35px;
    }

    .update-e-card__navigation-steps {
      padding: 0px 15px;

      .navigation-steps__item-wrapper {
        padding-right: 14px;
        padding-bottom: 16px;

        .navigation-steps__item {
          width: 70px;

          .item__number {
            font-size: 17px;
            width: 45px;
            height: 45px;
          }

          .item__name {
            font-size: 13px;
          }
        }
      }
    }

    .update-e-card__content {
      .content__user-inputs {
        padding: 30px 10px;

        .user-inputs__inputs {
          margin-bottom: 30px;

          .inputs__shimmer {
          }
          .preview__complete-step {
            width: 400px;
            max-width: 100vw;
            height: 650px;
            max-height: 100vh;
            border: none;
          }
        }

        .user-inputs__submit {
          .submit__discard-changes,
          .submit__save-changes {
            margin: 0 10px;
          }
        }
      }

      .content__preview {
        width: 400px;
        max-width: 100vw;
        height: 650px;
        max-height: 100vh;
        border: none;

        &.content__preview_no-shadow {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;
          height: 150px;
        }

        .preview__shimmer {
        }

        .actions__complete-step {
          margin-top: 30px;
          height: 120px;

          .complete-step__back {
            margin-bottom: 20px;
          }

          .complete-step__pay {
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .update-e-card {
    .update-e-card__page-title {
      font-size: var(--major480-title-fontSize);
      padding-bottom: 30px;
    }

    .update-e-card__navigation-steps {
      padding: 0px 10px;

      .navigation-steps__item-wrapper {
        padding-right: 10px;
        padding-bottom: 14px;

        .navigation-steps__item {
          width: 65px;

          .item__number {
            font-size: 16px;
            width: 40px;
            height: 40px;
          }

          .item__name {
            font-size: 12px;
          }
        }
      }
    }

    .update-e-card__content {
      .content__user-inputs {
        padding: 30px 10px;

        .user-inputs__inputs {
          margin-bottom: 30px;

          .inputs__shimmer {
          }
          .preview__complete-step {
            width: 300px;
            max-width: 100vw;
            height: 500px;
            max-height: 100vh;
            border: none;
          }
        }

        .user-inputs__submit {
          .submit__discard-changes,
          .submit__save-changes {
            margin: 0 10px;
          }
        }
      }

      .content__preview {
        width: 300px;
        max-width: 100vw;
        height: 500px;
        max-height: 100vh;
        border: none;

        &.content__preview_no-shadow {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;
          height: 150px;
        }

        .preview__shimmer {
        }

        .actions__complete-step {
          margin-top: 30px;
          height: 100px;

          .complete-step__back {
            margin-bottom: 20px;
          }

          .complete-step__pay {
          }
        }
      }
    }
  }
}
