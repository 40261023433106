@mixin flexBox(
  $display,
  $flex-directions: row,
  $justify-content: null,
  $align-items: null
) {
  display: $display;
  flex-direction: $flex-directions;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin containertentative($height: null, $width: null, $text-align: null) {
  height: $height;
  width: $width;
  text-align: $text-align;
}

.tentative_component {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  background-color: rgb(236, 236, 236);
  height: 100%;
  width: 100%;
  border-radius: 30px;

  .tentative_header {
    width: 100%;
    height: 10%;
    padding: 15px;
    background: #ffffff;
    border-radius: 30px 30px 0 0;

    .tentative_header_back {
      display: flex !important;
      justify-content: flex-start !important;
      width: 20%;
      font-size: 24px;
    }
    .tentative_header_title {
    
    }

    .tentative_header_empty {
      width: 20%;
    }
  }

  .tentative__container {
    padding: 0;
    height: 90%;
    width: 100%;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}

.box_list_top {
  @include flexBox($display: flex, $flex-directions: column);
}

.box_list_center {
  @include flexBox($display: flex, $flex-directions: column);
  justify-content: center;
}

.box_list_center,
.box_list_top {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  margin: auto;
  // border: 3px solid black;
  // border-radius: 8px;
  border-radius: 30px;

  overflow: scroll;
  overflow-x: hidden;
  //border-radius: 20px;
  overscroll-behavior: contain;
  // sorok scrollbar
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  // &::-webkit-scrollbar-thumb {
  //     background: #FF0000;
  // }

  @include containertentative($height: 100%, $width: 70%, $text-align: center);

  #list_aturcara {
    width: 100%;
    padding: 6px;
    margin: 8px 0px;

    .aturcara__time {
      font-size: 10px;
    }

    .aturcara__aktiviti {
      display: flex;
      justify-content: center;
      align-items: center;
      .aktivitinya {
        margin: 0;
        text-align: center;
        font-size: 12px;
        width: 60%;
      }
    }
  }
}

//animation
#list_aturcara {
  animation: aturcaraanimation 1s ease-out;
  animation-fill-mode: both;
}

//MEDIA AREA

@mixin mediatentative(
  $margin-top,
  $margin-bottom,
  $margin,
  $font-size1,
  $font-size2
) {
  .box_list_center,
  .box_list_top {
    margin-top: $margin-top !important;
    margin-bottom: $margin-bottom !important;
    #list_aturcara {
      margin: $margin;

      .aturcara__time {
        font-size: $font-size1; //masa
      }

      .aturcara__aktiviti {
        .aktivitinya {
          font-size: $font-size2; //aktiviti
        }
      }
    }
  }
}

@media (min-width: 393px) {
  @include mediatentative(
    $margin-top: 25px,
    $margin-bottom: 25px,
    $margin: 10px 0,
    $font-size1: 13px,
    $font-size2: 15px
  );
}

@media (min-width: 482px) {
  @include mediatentative(
    $margin-top: 25px,
    $margin-bottom: 25px,
    $margin: 12px 0,
    $font-size1: 14px,
    $font-size2: 16px
  );
}

@media (min-width: 576px) {
  @include mediatentative(
    $margin-top: 30px,
    $margin-bottom: 30px,
    $margin: 14px 0,
    $font-size1: 15px,
    $font-size2: 17px
  );
}

@media (min-width: 768px) {
  @include mediatentative(
    $margin-top: 35px,
    $margin-bottom: 35px,
    $margin: 16px 0,
    $font-size1: 16px,
    $font-size2: 18px
  );
}
