* {
  box-sizing: border-box;
}

body {
  font-family: "Lato", sans-serif;
}

@mixin flexBox(
  $display,
  $flex-directions: row,
  $justify-content: null,
  $align-items: null,
  $flex: null
) {
  display: $display;
  flex-direction: $flex-directions;
  justify-content: $justify-content;
  align-items: $align-items;
  flex: $flex;
}

@mixin containerCountdown(
  $height: null,
  $margin: null,
  $width: null,
  $font-size: null,
  $line-height: null
) {
  height: $height;
  margin: $margin;
  width: $width;
  font-size: $font-size;
  line-height: $line-height;
}

.countdown {
  @include flexBox(
    $display: flex,
    $flex-directions: column,
    $justify-content: space-between,
    $flex: 1
  );
  align-items: center;
  width: 100%;
}

.countdown__wrapper {
  @include flexBox(
    $display: grid,
    $justify-content: center,
    $align-items: center
  );

  @include containerCountdown($height: 100%, $width: 100%);

  grid-template-columns: 50% 50%;

  .countdown__item {
    @include flexBox(
      $display: flex,
      $flex-directions: column,
      $justify-content: center,
      $align-items: center
    );

    @include containerCountdown(
      $height: 70%,
      $width: 66%,
      $line-height: 50%,
      $font-size: 13px //font size number
    );

    padding-top: 8px; //timer top margin
    position: relative;
    letter-spacing: 1px;
    font-weight: 600;

    .countdown__timer {
      @include containerCountdown($font-size: 10px, $margin: 8px);

      font-weight: 600;
      text-transform: uppercase;
      transform: translateZ(1);
    }
  }
}

//MEDIA AREA

@mixin mediaCountdown(
  $font-size2,
  $line-height,
  $width,
  $height,
  $font-size3,
  $margin
) {
  .countdown {
    .countdown__wrapper {
      .countdown__item {
        font-size: $font-size2; //timer number size
        line-height: $line-height; //height distance between timer number and title
        width: $width; //timer gridbox width
        height: $height; //timer gridbox height

        .countdown__timer {
          font-size: $font-size3; //timer title size
          margin: $margin; //timer title margin
        }
      }
    }
  }
}

@media (min-width: 393px) {
  @include mediaCountdown(
    $font-size2: 16px,
    //timer size
    $line-height: 50%,
    $width: 66%,
    $height: 70%,
    $font-size3: 11px,
    //desc timer size
    $margin: 8px
  );
}

@media (min-width: 482px) {
  @include mediaCountdown(
    $font-size2: 17px,
    //timer size
    $line-height: 60%,
    $width: 66%,
    $height: 70%,
    $font-size3: 12px,
    //desc timer size
    $margin: 7px
  );
}

@media (min-width: 576px) {
  @include mediaCountdown(
    $font-size2: 18px,
    //timer size
    $line-height: 60%,
    $width: 66%,
    $height: 70%,
    $font-size3: 13px,
    //desc timer size
    $margin: 7px
  );
}

@media (min-width: 768px) {
  @include mediaCountdown(
    $font-size2: 19px,
    //timer size
    $line-height: 70%,
    $width: 66%,
    $height: 70%,
    $font-size3: 14px,
    //desc timer size
    $margin: 6px
  );
}
