.home__card-preview {
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 40px;

  .card-preview__header > span {
    font-family: var(--major-title-fontFamily);
    font-size: var(--major-title-fontSize);
    font-style: normal;
    font-weight: bold;
    line-height: 50px;
    letter-spacing: 0em;
    color: var(--color-forTitle);

    position: relative;

    .EkadTepi {
      color: #47b0e2;
    }
  }

  .card-preview__sub-header {
    .card-preview_text {
      font-family: var(--minor-title-fontFamily);
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
      padding-top: 20px;
      padding-bottom: 30px;
      width: 90%;
      color: #3d3d3d;
    }
  }

  .card-preview-wrapper {
    padding-bottom: 30px;

    .card-preview__prev {
      transform: rotate(180deg);
    }

    .card-preview__prev,
    .card-preview__next {
      max-width: 100%;

      cursor: pointer;
    }

    .card-preview__item {
      height: 420px;
      width: fit-content;

      .item__wrapper {
        position: relative;

        .item__background {
          width: 240px;
          height: 354px;

          box-shadow: 0px 12px 6px -6px #868686;

          // @media (max-width: 768px) {
          //   width: 200px;
          //   height: 300px;
          // }

          // @media (max-width: 576px) {
          //   width: 180px;
          //   height: 280px;
          // }
        }

        .card-preview__homepage {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 90%;
          text-align: center;
          text-transform: uppercase;

          .homepage-title {
            margin: 0;

            .home__text3 {
              font-size: var(--homepage-fontSize);
              text-transform: capitalize;
              font-family: var(--homepage-title-fontFamily);
            }
          }

          .hompage-name {
            margin: 25px 0px;

            .home__text1 {
              font-size: var(--homepage-name-fontSize);
              line-height: 100%;
              margin: 0;
              font-family: var(--homepage-name-fontFamily);
            }
          }

          .hompage-date {
            margin: 0;

            .home__text3 {
              font-size: var(--homepage-fontSize);
              font-family: var(--homepage-date-fontFamily);
            }
          }
        }

        .item__card-type {
          position: absolute;
          bottom: 0;
          left: calc(50% - 80px);
          font-family: Poppins;
          font-size: var(--all-description-fontSize);
          background-color: white;
          border: 1px solid black;
          width: 160px;
          padding: 5px;
        }

        .item__action-wrapper {
          width: 100%;

          .item__action {
            position: absolute;
            bottom: 25%;
          }
        }
      }
    }

    .slick-slide {
      opacity: 1;
    }

    .slick-center {
      opacity: 1;
      z-index: 10;
      .item__background {
        width: 280px !important;
        height: 396px !important;
      }
    }
  }

  // .card-preview__description {
  //   padding-bottom: 20px;

  //   .description__text {
  //     font-family: Poppins;
  //     font-size: 16px;
  //     font-style: normal;
  //     font-weight: 400;
  //     line-height: 32px;
  //     letter-spacing: 0em;
  //     text-align: center;

  //     max-width: 700px;
  //     margin: 0;
  //   }
  // }

  .card-preview__action {
    .action__wrapper {
      max-width: 700px;
      width: 100%;

      .action__button {
        border: 2px solid #37b3f6;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
        padding: 12px 30px;
      }
    }
  }
}

//MEDIA AREA

@media (max-width: 991px) {
  .home__card-preview {
    margin-top: 35px;
    padding-top: 35px;
    padding-bottom: 35px;

    .card-preview__header > span {
      font-size: var(--major-title-fontSize);
    }

    .card-preview__sub-header {
      .card-preview_text {
        font-size: var(--title-description-fontSize);
        padding-top: 15px;
        padding-bottom: 25px;
      }
    }

    .card-preview-wrapper {
      padding-bottom: 25px;

      .card-preview__item {
        height: 420px;

        .item__wrapper {
          .item__background {
            width: 240px;
            height: 354px;
          }

          .card-preview__homepage {
            .homepage-title {
              .home__text3 {
                font-size: var(--homepage-fontSize);
              }
            }

            .hompage-name {
              margin: 25px 0px;

              .home__text1 {
                font-size: var(--homepage-name-fontSize);
                line-height: 100%;
              }
            }

            .hompage-date {
              .home__text3 {
                font-size: var(--homepage-fontSize);
              }
            }
          }

          .item__card-type {
            font-size: var(--all-description-fontSize);
            width: 160px;
            padding: 5px;
          }
        }
      }

      .slick-center {
        .item__background {
          width: 280px !important;
          height: 396px !important;
        }
      }
    }

    .card-preview__action {
      .action__wrapper {
        max-width: 700px;
        width: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .home__card-preview {
    margin-top: 30px;
    padding-top: 30px;
    padding-bottom: 30px;

    .card-preview__header > span {
      font-size: var(--major768-title-fontSize);
    }

    .card-preview__sub-header {
      .card-preview_text {
        font-size: var(--title768-description-fontSize);
        padding-top: 10px;
        padding-bottom: 20px;
      }
    }

    .card-preview-wrapper {
      padding-bottom: 20px;

      .card-preview__item {
        height: 420px;

        .item__wrapper {
          .item__background {
            width: 240px;
            height: 354px;
          }

          .card-preview__homepage {
            .homepage-title {
              .home__text3 {
                font-size: var(--homepage-fontSize);
              }
            }

            .hompage-name {
              margin: 25px 0px;

              .home__text1 {
                font-size: var(--homepage-name-fontSize);
                line-height: 100%;
              }
            }

            .hompage-date {
              .home__text3 {
                font-size: var(--homepage-fontSize);
              }
            }
          }

          .item__card-type {
            font-size: var(--all768-description-fontSize);
            width: 160px;
            padding: 5px;
          }
        }
      }

      .slick-center {
        .item__background {
          width: 280px !important;
          height: 396px !important;
        }
      }
    }

    .card-preview__action {
      .action__wrapper {
        max-width: 700px;
        width: 100%;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
      }
    }
  }
}

@media (max-width: 480px) {
  .home__card-preview {
    margin-top: 30px;
    padding-top: 30px;
    padding-bottom: 30px;

    .card-preview__header > span {
      font-size: var(--major480-title-fontSize);
    }

    .card-preview__sub-header {
      .card-preview_text {
        font-size: var(--title480-description-fontSize);
        padding-top: 5px;
        padding-bottom: 15px;
      }
    }

    .card-preview-wrapper {
      padding-bottom: 15px;

      .card-preview__item {
        height: 420px;

        .item__wrapper {
          .item__background {
            width: 200px;
            height: 324px;
            display: none;
          }

          .card-preview__homepage {
            display: none;
          }

          .item__card-type {
            font-size: var(--all480-description-fontSize);
            width: 160px;
            padding: 5px;
            display: none;
          }

          .item__action-wrapper {
            .item__action {
              opacity: 0;
            }
          }
        }
      }

      .slick-center {
        position: relative;
        .item__background {
          width: 240px !important;
          height: 366px !important;
          display: block !important;
        }

        .card-preview__homepage {
          display: block !important;
        }

        .item__card-type {
          display: block !important;
        }

        .item__action-wrapper {
          .item__action {
            opacity: 1 !important;
          }
        }
      }
    }

    .card-preview__action {
      .action__wrapper {
        max-width: 700px;
        width: 100%;
      }
    }
  }
}
