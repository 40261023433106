.success {
  border: 4px solid #2ecc71;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 6px 20px;
}
.success__body {
  padding: 0 !important;

  .success-icons {
    width: 100%;
    background-color: #2ecc71;
    padding: 40px 80px 30px 80px;
    .success__body-icon {
      //color: var(--color-primary);
      font-size: 100px;
      color: white;
    }

    .success__body-headers {
      font-size: 18px;
      font-family: var(--title-description-fontFamily);
      padding: 20px 0 0 0;
      color: rgb(252, 252, 252);
    }
  }

  .suceess-details {
    .success__body-content {
      font-size: var(--all-description-fontSize);
      font-family: var(--all-description-fontFamily);

      .success__body-content-1 {
        margin: 20px 0;
        width: 400px;

        p {
          font-family: Poppins;
          margin: 0;
        }
      }

      .success__body-content-2 {
        margin: 20px 0;

        .details-order2 {
          font-family: Ropa Sans;

          color: rgb(49, 49, 49);

          .first-id {
            margin: 10px 0;
            font-size: 20px;
            font-weight: bold;
          }
          .second-id {
            margin: 10px 0;
            font-size: 18px;
          }
          .third-id {
            margin: 10px 0;
            font-size: 18px;
          }
        }
      }
    }
  }

  .receipt__account-button {
    background-color: #2ecc71;
    color: rgb(255, 255, 255);
    border-radius: 10px;
    width: 80%;
    white-space: nowrap;
    outline: none !important;
    cursor: pointer;
    border: none;
    font-family: Poppins;
  }
}

// MEDIA AREA

@mixin mediaSuccess(
  $padding1,
  $fontSize1,
  $padding2,
  $fontSize2,
  $fontSize3,
  $margin1,
  $width,
  $margin2,
  $margin3,
  $fontSize4,
  $fontSize5
) {
  .success__body {
    .success-icons {
      padding: $padding1;
      .success__body-icon {
        font-size: $fontSize1;
      }

      .success__body-headers {
        font-size: $fontSize2;
        padding: $padding2;
      }
    }

    .suceess-details {
      .success__body-content {
        font-size: $fontSize3;

        .success__body-content-1 {
          margin: $margin1;
          width: $width;
        }

        .success__body-content-2 {
          margin: $margin2;

          .details-order2 {
            .first-id {
              margin: $margin3;
              font-size: $fontSize4;
            }
            .second-id {
              margin: $margin3;
              font-size: $fontSize5;
            }
            .third-id {
              margin: $margin3;
              font-size: $fontSize5;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  @include mediaSuccess(
    $padding1: 30px 60px 20px 60px,
    $fontSize1: 90px,
    $fontSize2: 18px,
    $padding2: 20px 0 0 0,
    $fontSize3: var(--all-description-fontSize),
    $margin1: 20px 0,
    $width: 400px,
    $margin2: 20px 0,
    $margin3: 10px 0 0 0,
    $fontSize4: 20px,
    $fontSize5: 18px
  );
}

@media (max-width: 768px) {
  @include mediaSuccess(
    $padding1: 30px 40px 20px 40px,
    $fontSize1: 90px,
    $fontSize2: 17px,
    $padding2: 20px 0 0 0,
    $fontSize3: var(--all768-description-fontSize),
    $margin1: 20px 0,
    $width: 400px,
    $margin2: 20px 0,
    $margin3: 10px 0 0 0,
    $fontSize4: 18px,
    $fontSize5: 16px
  );
}

@media (max-width: 480px) {
  @include mediaSuccess(
    $padding1: 20px 20px 10px 20px,
    $fontSize1: 80px,
    $fontSize2: 16px,
    $padding2: 20px 0 0 0,
    $fontSize3: var(--all480-description-fontSize),
    $margin1: 20px 0,
    $width: 400px,
    $margin2: 20px 0,
    $margin3: 8px 0 0 0,
    $fontSize4: 16px,
    $fontSize5: 14px
  );
}
