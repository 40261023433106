$bg: #242526;
$bg-accent: #484a4d;
$text-color: #dadce1;
$icon-size: 60px;
$border: 1px solid #474a4d;
$border-radius: 8px;
$speed: 500ms;

@mixin flexBox(
  $display,
  $flex-directions: row,
  $justify-content: null,
  $align-items: null,
  $flex: null
) {
  display: $display;
  flex-direction: $flex-directions;
  justify-content: $justify-content;
  align-items: $align-items;
  flex: $flex;
}

@mixin containerwish(
  $height: null,
  $margin: null,
  $width: null,
  $font-size: null,
  $text-align: null,
  $line-height: null
) {
  height: $height;
  margin: $margin;
  width: $width;
  font-size: $font-size;
  text-align: $text-align;
  line-height: $line-height;
}

.wish_component {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  background-color: rgb(236, 236, 236);
  height: 100%;
  width: 100%;
  border-radius: 30px;

  .wish_header {
    width: 100%;
    height: 10%;
    padding: 15px;
    background: #ffffff;
    border-radius: 30px 30px 0 0;

    .wish_header_back {
      display: flex !important;
      justify-content: flex-start !important;
      width: 20%;
      font-size: 24px;
    }
    .wish_header_title {
    
    }

    .wish_header_empty {
      width: 20%;
    }
  }

  .wish__container {
    padding: 0;
    height: 90%;
    width: 100%;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}

//for button and other
.ucapan__timeline {
  @include containerwish($height: 95%, $width: 90%);
  margin-top: 20px;
  margin-bottom: 10px;
  padding: 0px;
  //box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  overflow: scroll;
  overflow-x: hidden;
  //border-radius: 20px;
  overscroll-behavior: contain;
  // sorok scrollbar
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  // &::-webkit-scrollbar-thumb {
  //     background: #FF0000;
  // }
}
