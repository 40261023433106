.footer {
  margin-top: 40px;
  padding: 25px 0;
  height: min-content;
  width: 100%;
  background: var(--color-primary);
  font-family: Poppins;
  font-size: var(--all-description-fontSize);

  .footer-contact-us {
    color: #fafafa;
    margin: 10px auto;

    .footer-contact-us-title {
      margin: 5px auto;
    }

    .footer-contact-us-icons {
      .logo {
        margin: 10px 15px;
        height: 35px;
        width: 35px;

        cursor: pointer;
      }
    }

    .footer-contact-us-bottom {
    }
  }

  .footer-break-line {
    margin: 0px auto;
    width: 70%;
    border: 1px solid #e5e5e5;
  }

  .footer-others-details {
    margin: 10px auto;
    color: #f7f7f7;
  }
}

@mixin meidaFooter(
  $marginTop,
  $padding1,
  $fontSize1,
  $margin1,
  $margin2,
  $margin3,
  $box,
  $width1,
  $margin4
) {
  .footer {
    margin-top: $marginTop;
    padding: $padding1;
    font-size: $fontSize1;

    .footer-contact-us {
      margin: $margin1;
      font-size: $fontSize1;

      .footer-contact-us-title {
        margin: $margin2;
      }

      .footer-contact-us-icons {
        .logo {
          margin: $margin3;
          height: $box;
          width: $box;
        }
      }
    }

    .footer-break-line {
      width: $width1;
    }

    .footer-others-details {
      margin: $margin4;
      font-size: $fontSize1;
    }
  }
}

@media (max-width: 991px) {
  @include meidaFooter(
    $marginTop: 35px,
    $padding1: 25px 0,
    $fontSize1: var(--all-description-fontSize),
    $margin1: 10px auto,
    $margin2: 5px auto,
    $margin3: 10px 15px,
    $box: 32px,
    $width1: 70%,
    $margin4: 10px auto
  );
}

@media (max-width: 768px) {
  @include meidaFooter(
    $marginTop: 30px,
    $padding1: 20px 0,
    $fontSize1: var(--all768-description-fontSize),
    $margin1: 8px auto,
    $margin2: 5px auto,
    $margin3: 8px 10px,
    $box: 28px,
    $width1: 70%,
    $margin4: 8px auto
  );
}

@media (max-width: 480px) {
  @include meidaFooter(
    $marginTop: 25px,
    $padding1: 15px 0,
    $fontSize1: var(--all480-description-fontSize),
    $margin1: 6px auto,
    $margin2: 4px auto,
    $margin3: 6px 6px,
    $box: 24px,
    $width1: 90%,
    $margin4: 6px auto
  );
}
