@mixin flexBox(
  $display,
  $flex-directions: row,
  $justify-content: null,
  $align-items: null,
  $flex: null
) {
  display: $display;
  flex-direction: $flex-directions;
  justify-content: $justify-content;
  align-items: $align-items;
  flex: $flex;
}

@mixin containerRSVP(
  $height: null,
  $margin: null,
  $width: null,
  $font-size: null,
  $text-align: null,
  $line-height: null
) {
  height: $height;
  margin: $margin;
  width: $width;
  font-size: $font-size;
  text-align: $text-align;
  line-height: $line-height;
}

.rsvpsession {
  @include containerRSVP($margin: 0);
  margin-bottom: 10px;
}

.sesi {
  width: 90%;

  .rsvptop__info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    .sesi__title {
      text-align: center;
      line-height: 90%;
      margin: 8px 0px 2px 0px;

      .rsvp__sesi {
        font-size: 13px;
        font-weight: 600;
      }

      .rsvp__sesiTime {
        margin: 2px 0;
        font-size: 10px;
      }
    }

    .rsvp-seat {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      text-align: center;
      margin: 0px 0px 5px 0px;
      .seat-empty {
        .seat-empty-number {
          font-size: 12px;
          font-weight: 600;
        }
        .seat-empty-descp {
          font-size: 8px;
          line-height: 1.3;
        }
      }

      .seat-total {
        .seat-total-number {
          font-size: 12px;
          font-weight: 600;
        }
        .seat-total-descp {
          font-size: 8px;
          line-height: 1.3;
        }
      }
    }
  }

  .kehadiran {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      height: 100%;
      width: 100%;
      outline: none;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 10px;
      letter-spacing: 1px;
      color: #ffffff;
      border: none;
      background-color: #32cd32;
      
      &:disabled {
        color: black;
        background-color: grey;
      }
    }
  }
}

.data {
  margin-bottom: 5px;
}

form .data label {
  @include containerRSVP(
    $margin: 0px,
    $font-size: 13px
  ); //font size tajuk nama/hp/pax
}

form .data input,
form .data select {
  @include containerRSVP(
    $width: 100%,
    $font-size: 12px
  ); //font size untuk isi maklumat

  padding-left: 10px;
  border: 2px solid grey;
}

form .btn {
  @include containerRSVP(
    $height: 45px,
    $margin: 30px 0,
    $width: 100%,
    $font-size: 14px
  ); //font size hantar
}

//MEDIA AREA

@mixin mediaRSVP(
  $margin-bottom1,
  $width,
  $margin1,
  $font-size1,
  $margin2,
  $font-size2,
  $margin3,
  $font-size3,
  $height,
  $font-size4,
  $line-height,
  $font-size5
) {
  .rsvpsession {
    margin-bottom: $margin-bottom1;
  }

  .sesi {
    width: $width;

    .rsvptop__info {
      .sesi__title {
        margin: $margin1;

        .rsvp__sesi {
          font-size: $font-size1;
        }

        .rsvp__sesiTime {
          margin: $margin2;
          font-size: $font-size2;
        }
      }

      .rsvp-seat {
        margin: $margin3;
        .seat-empty {
          .seat-empty-number {
            font-size: $font-size3;
          }
          .seat-empty-descp {
            font-size: $font-size4;
            line-height: $line-height;
          }
        }

        .seat-total {
          .seat-total-number {
            font-size: $font-size3;
          }
          .seat-total-descp {
            font-size: $font-size4;
            line-height: $line-height;
          }
        }
      }
    }

    .kehadiran {
      height: $height;

      button {
        font-size: $font-size5;
      }
    }
  }
}
@media (min-width: 393px) {
  @include mediaRSVP(
    $margin-bottom1: 15px,
    $width: 90%,
    $margin1: 9px 0px 3px 0px,
    $font-size1: 14px,
    $margin2: 3px 0,
    $font-size2: 11px,
    $margin3: 0px 0px 6px 0px,
    $font-size3: 13px,
    $font-size4: 9px,
    $line-height: 1.4,
    $height: 36px,
    $font-size5: 11px
  );
}

@media (min-width: 482px) {
  @include mediaRSVP(
    $margin-bottom1: 20px,
    $width: 85%,
    $margin1: 10px 0px 4px 0px,
    $font-size1: 15px,
    $margin2: 4px 0,
    $font-size2: 12px,
    $margin3: 0px 0px 7px 0px,
    $font-size3: 14px,
    $font-size4: 10px,
    $line-height: 1.4,
    $height: 40px,
    $font-size5: 12px
  );
}

@media (min-width: 576px) {
  @include mediaRSVP(
    $margin-bottom1: 25px,
    $width: 80%,
    $margin1: 13px 0px 6px 0px,
    $font-size1: 16px,
    $margin2: 6px 0,
    $font-size2: 13px,
    $margin3: 0px 0px 9px 0px,
    $font-size3: 15px,
    $font-size4: 11px,
    $line-height: 1.4,
    $height: 45px,
    $font-size5: 13px
  );
}

@media (min-width: 768px) {
  @include mediaRSVP(
    $margin-bottom1: 30px,
    $width: 75%,
    $margin1: 15px 0px 8px 0px,
    $font-size1: 17px,
    $margin2: 8px 0,
    $font-size2: 14px,
    $margin3: 0px 0px 11px 0px,
    $font-size3: 16px,
    $font-size4: 12px,
    $line-height: 1.4,
    $height: 50px,
    $font-size5: 14px
  );
}
