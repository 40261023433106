.live-model {
  cursor: pointer;

  // &.body__device_handle-tablet-offset {
  //     padding: 35px 0;
  // }

  .live-model__wrapper {
    position: relative;

    width: fit-content;
    height: fit-content;

    .live-model__frame {
      position: relative;

      z-index: 1;
      width: 330px;
      height: 610px;

      pointer-events: none;

      &.live-model__frame_tablet-dimensions {
        width: 578px;
        height: 678px;
      }
    }

    .live-model__website {
      position: absolute;
      top: 17px;
      left: 15px;
      z-index: 0;

      border: none;
      border-radius: 19px;

      width: fit-content;
      height: 572px;
      //   transform: scale(0.68);

      &.live-model__website_tablet-dimensions {
        border-radius: 0;

        top: 17px;
        left: 98px;

        width: 386px;
        height: 622px;
      }
    }
  }
}
