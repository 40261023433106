.ecard {
  position: relative;
  max-width: 800px;
  min-width: 280px;
  height: 100%;

  .ecard__nav {
    z-index: 10;
    right: 10%;
    bottom: 8%;

    .menu-close,
    .menu-open {
      height: 30px;
      width: 30px;
    }

    i {
      font-size: 30px;
    }
  }

  .carousel {
    height: 100%;

    .carousel-inner {
      height: 100%;

      .carousel-item {
        height: 100%;
      }
    }
  }

  @import "./camera.scss";

  @import "./contact.scss";

  @import "./content.scss";

  @import "./countdown.scss";

  @import "./details.scss";

  @import "./gallery.scss";

  @import "./general.scss";

  @import "./home.scss";

  @import "./location.scss";

  @import "./loadingScreen.scss";

  @import "./mapContainer.scss";

  @import "./preview.scss";

  @import "./rsvp.scss";

  @import "./tempData.scss";

  @import "./tentative.scss";

  @import "./wish.scss";

  @import "./menuContainer.scss";
}

.ecard div ol {
  li {
    background-color: #47b0e2;
    border-radius: 50%;
    height: 13px;
    width: 13px;
  }
}

.ecard .carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.ecard .carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

//MEDIA AREA

@mixin ecardMedia($height-width) {
  .ecard div ol li {
    height: $height-width;
    width: $height-width;
  }
}

@media (min-width: 393px) {
  @include ecardMedia($height-width: 14px);
  .ecard .ecard-audio {
    i {
      font-size: 32px;
    }
  }
}

@media (min-width: 482px) {
  @include ecardMedia($height-width: 15px);
  .ecard .ecard-audio {
    i {
      font-size: 34px;
    }
  }
}

@media (min-width: 576px) {
  @include ecardMedia($height-width: 16px);
  .ecard .ecard-audio {
    i {
      font-size: 36px;
    }
  }
}

@media (min-width: 768px) {
  @include ecardMedia($height-width: 17px);
  .ecard .ecard-audio {
    i {
      font-size: 38px;
    }
  }
}
