$border-radius: 8px;

.dropdown__item {
  height: 150px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: $border-radius;
  padding: 0.5px;

  display: flex;
  flex-direction: column;

  &:hover {
    background-color: whitesmoke;
  }
}
