:root {
  --text-size-xs: 10px;
  --text-size: 14px;
  --text-size-lg: 18px;
  --text-size-xl: 24px;
  --text-size-xxl: 72px;
  --text-size-xxxl: 120px;
  --title--size: 30px;

  //for website
  --major-title-fontFamily: "Roboto";
  --major-title-fontSize: 36px;
  --minor-title-fontFamily: "Poppins";
  --minor-title-fontSize: 20px;
  --title-description-fontFamily: "Poppins";
  --title-description-fontSize: 18px;
  --all-description-fontFamily: "Roboto";
  --all-description-fontSize: 16px;
  --all-input-fontFamily: "Poppins";
  --all-input-fontSize: 16px;
  --all-button-fontFamily: "Poppins";
  --all-button-fontSize: 15px;

  //for website mediaqueries
  --major768-title-fontSize: 32px;
  --major480-title-fontSize: 28px;
  --minor768-title-fontSize: 19px;
  --minor480-title-fontSize: 18px;
  --title768-description-fontSize: 17px;
  --title480-description-fontSize: 16px;
  --all768-description-fontSize: 15px;
  --all480-description-fontSize: 14px;
  --all768-input-fontSize: 15px;
  --all480-input-fontSize: 14px;
  --all768-button-fontSize: 14px;
  --all480-button-fontSize: 13px;

  //For Card Homepage preview
  --homepage-title-fontFamily: "Great Vibes";
  --homepage-name-fontFamily: "Vollkorn SC";
  --homepage-date-fontFamily: "Great Vibes";
  --homepage-fontSize: 17px;
  --homepage-name-fontSize: 21px;

  //for Ecard preview default setting
  --ecard-default-fontfamily1: "Poppins", sans-serif;
  --ecard-default-fontfamily2: "Tangerine", sans-serif;
  --ecard-default-fontfamily3: "Vollkorn SC", sans-serif;
  --ecard-default-fontfamily4: "Voces", sans-serif;
}

// @media (max-width: 991px) {
// }

// @media (max-width: 768px) {
// }

// @media (max-width: 480px) {
// }

@font-face {
  font-family: "Bebas Neue";
  src: url(../../fonts/BebasNeue-Regular.ttf);
}

@font-face {
  font-family: "Belleza";
  src: url(../../fonts/Belleza-Regular.ttf);
}

@font-face {
  font-family: "Caesar Dressing";
  src: url(../../fonts/CaesarDressing-Regular.ttf);
}

@font-face {
  font-family: "Cookie";
  src: url(../../fonts/Cookie-Regular.ttf);
}

@font-face {
  font-family: "Concert One";
  src: url(../../fonts/ConcertOne-Regular.ttf);
}

@font-face {
  font-family: "Rokkitt";
  src: url(../../fonts/Rokkitt-Regular.ttf);
}

@font-face {
  font-family: "Ropa Sans";
  src: url(../../fonts/RopaSans-Regular.ttf);
}

@font-face {
  font-family: "Rosario";
  src: url(../../fonts/Rosario-Regular.ttf);
}

@font-face {
  font-family: "Rubik One";
  src: url(../../fonts/RubikOne-Regular.ttf);
}

@font-face {
  font-family: "Roboto";
  src: url(../../fonts/Roboto-Light.ttf);
}

@font-face {
  font-family: "Poppins";
  src: url(../../fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "Tangerine";
  src: url(../../fonts/Tangerine-Bold.ttf);
}

@font-face {
  font-family: "Vollkorn SC";
  src: url(../../fonts/VollkornSC-SemiBold.ttf);
}

@font-face {
  font-family: "Voces";
  src: url(../../fonts/Voces-Regular.ttf);
}

@font-face {
  font-family: "Tienne";
  src: url(../../fonts/Tienne-Regular.ttf);
}

@font-face {
  font-family: "Rouge Script";
  src: url(../../fonts/RougeScript-Regular.ttf);
}

@font-face {
  font-family: "Great Vibes";
  src: url(../../fonts/GreatVibes-Regular.ttf);
}

@font-face {
  font-family: "Courgette";
  src: url(../../fonts/Courgette-Regular.ttf);
}

@font-face {
    font-family: 'Roboto';
    src: url(../../fonts/Roboto-Light.ttf);
}

@font-face {
  font-family: 'Limelight Regular';
  src: url(../../fonts/Limelight-Regular.ttf);
}

@font-face {
  font-family: 'Spirax';
  src: url(../../fonts/Spirax-Regular.ttf);
}

@font-face {
  font-family: 'Dancing Script';
  src: url(../../fonts/DancingScript-Regular.ttf);
}

