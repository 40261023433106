.account-details {
  .account-details__page-title {
    font-family: var(--major-title-fontFamily);
    font-size: var(--major-title-fontSize);
    font-weight: bold;

    padding-bottom: 40px;
    color: var(--color-forTitle);

    position: relative;

    .EkadTepi {
      color: #47b0e2;
    }
  }

  .account-details__content {
    .content__selections {
      padding-right: 30px;

      > div {
        &:last-child {
          padding-bottom: 0;
        }
      }

      .selections__item-wrapper {
        padding-bottom: 30px;
        width: 300px;

        .selections__item {
          box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.2);
          background: #ffffff;
          border-left: 6px solid var(--color-primary);
          padding: 20px 10px 25px 10px;
          cursor: pointer;

          &.selection__item_selected {
            background-color: var(--color-primary);
            color: white;
          }

          .item__header {
            font-family: var(--title-description-fontFamily);
            font-size: var(--title-description-fontSize);
            line-height: 30px;
          }

          .item__description {
            font-family: var(--all-description-fontFamily);
            font-size: var(--all-description-fontSize);
            line-height: 25px;
          }
        }
      }
    }

    .account_display {
      .content__display {
        box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.2);
        border: 4px solid var(--color-primary);
        border-radius: 8px;
        height: min-content;
        width: 600px;
      }

      .missing-ecard {
        height: 300px;
        max-height: 100vh;
        width: 300px;
        max-width: 100vw;

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        text-align: center;

        .missing-ecard__msg {
          opacity: 0.5;
        }

        .missing-ecard__action {
          margin-top: 20px;
        }
      }

      @import "./personalInfo.scss";

      @import "./cards.scss";

      @import "./wish.scss";

      @import "./rsvp.scss";

      @import "./album.scss";

      @import "./security.scss";
    }
  }
}

@media (max-width: 991px) {
  .account-details {
    .account-details__page-title {
      font-size: var(--major-title-fontSize);
      padding-bottom: 35px;
    }

    .account-details__content {
      display: flex;
      flex-direction: column;

      .content__selections {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding-right: 0px;

        > div {
          &:last-child {
            padding-bottom: 30px;
          }
        }

        .selections__item-wrapper {
          padding-bottom: 30px;
          width: 200px;
          margin: 0 10px;

          .selections__item {
            border-left: 6px solid var(--color-primary);
            padding: 20px 10px 25px 10px;

            .item__header {
              font-size: var(--title-description-fontSize);
              line-height: 30px;
            }

            .item__description {
              font-size: var(--all-description-fontSize);
              line-height: 25px;
              display: none;
            }
          }
        }
      }

      .account_display {
        display: flex;
        justify-content: center;
        align-items: center;
        .content__display {
          border: 4px solid var(--color-primary);
          border-radius: 8px;
          height: min-content;
          width: 650px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .account-details {
    .account-details__page-title {
      font-size: var(--major768-title-fontSize);
      padding-bottom: 35px;
    }

    .account-details__content {
      display: flex;
      flex-direction: column;

      .content__selections {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        padding-right: 0px;

        > div {
          &:last-child {
            padding-bottom: 30px;
          }
        }

        .selections__item-wrapper {
          padding-bottom: 30px;
          width: 200px;
          margin: 0 10px;

          .selections__item {
            border-left: 5px solid var(--color-primary);
            padding: 15px 12px 20px 12px;

            .item__header {
              font-size: var(--all768-description-fontSize);
              line-height: 30px;
            }

            .item__description {
              font-size: var(--all768-description-fontSize);
              line-height: 25px;
              display: none;
            }
          }
        }
      }

      .account_display {
        display: flex;
        justify-content: center;
        align-items: center;
        .content__display {
          border: 3px solid var(--color-primary);
          border-radius: 8px;
          height: min-content;
          width: 620px;
        }
      }
    }
  }
}

@media (max-width: 599px) {
  .account-details {
    .account-details__content {
      .account_display {
        .content__display {
          width: 480px;
        }
      }
    }
  }
}

@media (max-width: 540px) {
  .account-details {
    .account-details__content {
      .account_display {
        .content__display {
          width: 430px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .account-details {
    .account-details__page-title {
      font-size: var(--major480-title-fontSize);
      padding-bottom: 30px;
    }

    .account-details__content {
      .content__selections {
        > div {
          &:last-child {
            padding-bottom: 20px;
          }
        }

        .selections__item-wrapper {
          padding-bottom: 20px;
          width: 140px;
          margin: 0 6px;

          .selections__item {
            border-left: 4px solid var(--color-primary);
            padding: 12px 10px 15px 10px;

            .item__header {
              font-size: var(--all480-description-fontSize);
              line-height: 30px;
            }
          }
        }
      }

      .account_display {
        display: flex;
        justify-content: center;
        align-items: center;
        .content__display {
          border: 3px solid var(--color-primary);
          border-radius: 6px;
          height: min-content;
          width: 430px;
        }
      }
    }
  }
}

@media (max-width: 455px) {
  .account-details {
    .account-details__content {
      .account_display {
        .content__display {
          width: 300px;
        }
      }
    }
  }
}
