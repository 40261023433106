.top-navigation-bar {
  position: relative;
  .top-navigation-bar__top-section {
    width: 100%;

    background-color: var(--color-primary);

    padding: 20px 70px;

    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);

    .top-section__left-segment {
      width: 180px;
    }

    .top-section__middle-segment {
      .middle-segment__logo {
        position: relative;
        cursor: pointer;

        .logo__top-text {
          font-family: Limelight Regular;
          font-size: 40px;
          font-style: normal;
          font-weight: 400;
          line-height: 49px;
          letter-spacing: 0em;
          text-align: left;
          color: white;
          margin-bottom: -4px;
        }

        .logo__bottom-text {
          font-family: Ropa Sans;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          letter-spacing: 0.05em;
          text-align: left;
          color: white;
        }

        .logo__card-icon {
          position: absolute;
          top: -5px;
          right: -50px;
          opacity: 7%;
          width: 290px;
          height: 80px;
        }
      }
    }

    .top-section__right-segment {
      width: 280px;

      .right-segment__cart-wrapper {
        .shopping-cart {
          position: relative;
          width: 100%;
          height: 100%;
          .right-segment__cart {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }

          .right-segment__cart-value {
            position: absolute;
            right: -20%;
            top: 20%;
            border-radius: 50%;
            font-size: 14px;
            font-weight: 700;
            color: white;
            background-color: rgb(255, 125, 50);
            padding: 4px;
            text-align: center;
            vertical-align: middle;
            line-height: 100%;
          }
        }
      }

      .right-segment__account,
      .right-segment__logOut {
        padding: 7px 15px;
        font-family: Ropa Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }

      .right-segment__account {
        border: 2px solid white;
        border-radius: 10px;
        color: #002c44;
        background-color: white;
      }

      .right-segment__logOut {
        border: 2px solid white;
        border-radius: 10px;
        color: white;
      }

      .right-segment__cart-wrapper,
      .right-segment__account,
      .right-segment__logOut {
        cursor: pointer;
        margin-right: 15px;
      }

      .right-segment__account:hover,
      .right-segment__logOut:hover,
      .right-segment__cart:hover {
        transform: scale(1.03);
      }
    }
  }

  .top-navigation-bar__bottom-section {
    padding-top: 30px;
    padding-bottom: 20px;

    .bottom-section__main {
    }

    .bottom-section__theme {
    }

    .bottom-section__contact-us {
    }

    .bottom-section__main,
    .bottom-section__theme,
    .bottom-section__contact-us {
      font-family: Bebas Neue;
      font-size: 28px;
      font-style: normal;
      font-weight: normal;
      line-height: 36px;

      padding-bottom: 6px;

      cursor: pointer;

      &.bottom-section_underline {
        border-bottom: 2px solid #47b0e2;
      }
    }
  }
}

//MEDIA AREA

@mixin mediaTopNavigationBar(
  $padding1,
  $width1,
  $fontSize1,
  $lineHeight1,
  $fontSize2,
  $lineHeight2,
  $width2,
  $height1,
  $width3,
  $fontSize3,
  $lineHeight3,
  $padding2,
  $padding3,
  $fontSize4,
  $lineHeight4,
  $marginRight1,
  $paddingTop1,
  $paddingBottom1,
  $fontSize5,
  $lineHeight5,
  $paddingBottom2
) {
  .top-navigation-bar {
    .top-navigation-bar__top-section {
      padding: $padding1;

      .top-section__left-segment {
        width: $width1;
      }

      .top-section__middle-segment {
        .middle-segment__logo {
          .logo__top-text {
            font-size: $fontSize1;
            line-height: $lineHeight1;
          }

          .logo__bottom-text {
            font-size: $fontSize2;
            line-height: $lineHeight2;
          }

          .logo__card-icon {
            width: $width2;
            height: $height1;
          }
        }
      }

      .top-section__right-segment {
        width: $width3;

        .right-segment__cart-wrapper {
          .shopping-cart {
            .right-segment__cart {
            }

            .right-segment__cart-value {
              font-size: $fontSize3;
              line-height: $lineHeight3;
              padding: $padding2;
            }
          }
        }

        .right-segment__account,
        .right-segment__logOut {
          font-size: $fontSize4;
          line-height: $lineHeight4;
          padding: $padding3;
        }

        .right-segment__cart-wrapper,
        .right-segment__account,
        .right-segment__logOut {
          margin-right: $marginRight1;
        }
      }
    }

    .top-navigation-bar__bottom-section {
      padding-top: $paddingTop1;
      padding-bottom: $paddingBottom1;

      .bottom-section__main,
      .bottom-section__theme,
      .bottom-section__contact-us {
        font-size: $fontSize5;
        line-height: $lineHeight5;
        padding-bottom: $paddingBottom2;
      }
    }
  }
}

@media (max-width: 991px) {
  @include mediaTopNavigationBar(
    $padding1: 30px 40px,
    $width1: 0px,
    $fontSize1: 38px,
    $lineHeight1: 40px,
    $fontSize2: 18px,
    $lineHeight2: 21px,
    $width2: 60px,
    $height1: 34px,
    $width3: 280px,
    $fontSize3: 14px,
    $lineHeight3: 100%,
    $padding2: 4px,
    $fontSize4: 16px,
    $lineHeight4: 24px,
    $padding3: 7px 15px,
    $marginRight1: 10px,
    $paddingTop1: 30px,
    $paddingBottom1: 20px,
    $fontSize5: 26px,
    $lineHeight5: 36px,
    $paddingBottom2: 6px
  );

  .top-section__middle-segment {
    .logo__bottom-text {
      letter-spacing: 0.08em !important;
    }

    .logo__card-icon {
      top: 3px !important;
      right: -65px !important;
      display: none;
    }
  }

  .top-section__left-segment {
    display: none;
  }

  .top-navigation-bar__top-section {
    display: flex;
    justify-content: space-between !important;
  }
}

@media (max-width: 768px) {
  @include mediaTopNavigationBar(
    $padding1: 25px 10px,
    $width1: 0px,
    //logo scale
    $fontSize1: 36px,
    $lineHeight1: 44px,
    $fontSize2: 16px,
    $lineHeight2: 16px,
    $width2: 45px,
    $height1: 29px,
    $width3: 130px,
    //finish logo
    $fontSize3: 13px,
    $lineHeight3: 60%,
    $padding2: 4px,
    $fontSize4: 14px,
    $lineHeight4: 17px,
    $padding3: 6px 8px,
    $marginRight1: 8px,
    $paddingTop1: 25px,
    $paddingBottom1: 20px,
    $fontSize5: 24px,
    $lineHeight5: 30px,
    $paddingBottom2: 4px
  );

  .top-section__middle-segment {
    transform: scale(0.95);

    .logo__bottom-text {
      letter-spacing: 0.12em !important;
    }
    .logo__card-icon {
      top: 7px !important;
      right: -45px !important;
    }
  }

  .top-section__right-segment {
    .shopping-cart {
      display: flex;
      justify-content: center;
      align-items: center;

      .right-segment__cart {
        width: 26px !important;
        height: 26px !important;
      }
    }
    .right-segment__account-box {
      margin-left: 4px;
      display: flex;
      flex-direction: column;

      .right-segment__account {
        margin: 2px 0;
      }
    }
  }
}

@media (max-width: 480px) {
  @include mediaTopNavigationBar(
    $padding1: 10px 0px,
    $width1: 0px,
    //logo scale
    $fontSize1: 32px,
    $lineHeight1: 35px,
    $fontSize2: 16px,
    $lineHeight2: 21px,
    $width2: 35px,
    $height1: 25px,
    $width3: 100px,
    //finish logo
    $fontSize3: 12px,
    $lineHeight3: 50%,
    $padding2: 4px,
    $fontSize4: 10px,
    $lineHeight4: 14px,
    $padding3: 3px 4px,
    $marginRight1: 6px,
    $paddingTop1: 20px,
    $paddingBottom1: 15px,
    $fontSize5: 22px,
    $lineHeight5: 25px,
    $paddingBottom2: 2px
  );

  .top-section__middle-segment {
    transform: scale(0.8) translateX(-8%);

    .logo__bottom-text {
      letter-spacing: 0.06em !important;
    }

    .logo__card-icon {
      top: 5px !important;
      right: -35px !important;
    }
  }

  .top-section__right-segment {
    .shopping-cart {
      .right-segment__cart {
        width: 22px !important;
        height: 22px !important;
      }
    }

    .right-segment__account-box {
      display: flex;
      flex-direction: column;

      .right-segment__account {
        margin: 4px 0;
      }
    }
  }
}
