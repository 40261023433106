.backdropBackground{
    z-index: 10;
    position: absolute;
    height: 100%;
    width: 100%;
    transition: opacity .15s;

    &.primary{
        background-color: #000000;
    }

    &.show{
       opacity: 0.5; 
    }

    &.fade{
        opacity: 0; 
     }
}

