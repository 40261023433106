@mixin container(
  $display,
  $flex-directions: row,
  $justify-content: null,
  $align-items: null,
  $flex: null
) {
  display: $display;
  flex-direction: $flex-directions;
  justify-content: $justify-content;
  align-items: $align-items;
  flex: $flex;
}

@mixin containerContent(
  $margin: null,
  $height: null,
  $width: null,
  $text-align: null,
  $font-size: null
) {
  margin: $margin;
  height: $height;
  width: $width;
  text-align: $text-align;
  font-size: $font-size;
}

.content_component {
  flex: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f6f6f6;
}
//for items inside cards
.content__containerTop {
  @include container(
    $display: flex,
    $flex-directions: column,
    $align-items: center,
    $justify-content: center
  );

  @include containerContent(
    // $margin: 10px 15px 0px 15px,
    $height: 50%,
    $width: 100%
  );
}

.content__containerBody {
  @include container(
    $display: grid,
    $flex-directions: center,
    $align-items: center
  );

  @include containerContent(
    // $margin: 10px 15px,
    $height: 45%,
    $width: 100%,
    $font-size: 30px //icon size
  );

  grid-template-columns: 50% 50%;
  grid-auto-rows: minmax(auto, auto);

  div {
    line-height: 80%;
    text-align: center;

    .a {
      @include containerContent($margin: 0px 2px, $font-size: 12px);
    }
  }
}

.container__bottom {
  position: relative;
  font-size: 18px;
  margin-top: 0px;
}

//animation
.icons {
  animation: 2s ease 0s infinite normal none running fontgradient;
  -webkit-animation: fontgradient 2s ease infinite;
  -moz-animation: fontgradient 2s ease infinite;
  animation: fontgradient 2s ease infinite;
}

//MEDIA AREA

@mixin mediaContent($font-size1, $line-height, $font-size2, $font-size3) {
  .content__containerBody {
    font-size: $font-size1; //icon size

    div {
      line-height: $line-height; //jarak icon dgn descp

      .a {
        font-size: $font-size2; // font descp
      }
    }
  }

  .container__bottom {
    font-size: $font-size3; //icon back to home
  }
}

@media (min-width: 393px) {
  @include mediaContent(
    $font-size1: 32px,
    $line-height: 85%,
    $font-size2: 14px,
    $font-size3: 20px
  );
}

@media (min-width: 482px) {
  @include mediaContent(
    $font-size1: 34px,
    $line-height: 90%,
    $font-size2: 16px,
    $font-size3: 22px
  );
}

@media (min-width: 576px) {
  @include mediaContent(
    $font-size1: 36px,
    $line-height: 95%,
    $font-size2: 18px,
    $font-size3: 24px
  );
}

@media (min-width: 768px) {
  @include mediaContent(
    $font-size1: 38px,
    $line-height: 100%,
    $font-size2: 20px,
    $font-size3: 26px
  );
}
