.card-catalog {
  .card-catalog__title {
    font-family: var(--major-title-fontFamily);
    font-size: var(--major-title-fontSize);
    font-weight: bold;

    padding-bottom: 20px;

    color: var(--color-forTitle);

    .EkadTepi {
      color: #47b0e2;
    }
  }

  .card-catalog__theme-list {
    max-width: 800px;
    padding-bottom: 20px;

    .theme-list__item {
      padding: 10px;

      .item__wrapper {
        width: 200px;
        height: 65px;

        border: 2px solid #47b0e2;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 4px -1px,
          rgba(0, 0, 0, 0.3) 0px 1px 2px -1px;
        box-sizing: border-box;
        border-radius: 10px;

        cursor: pointer;

        &:hover,
        &.theme-list__item_selected {
          background: #37b3f6;

          box-sizing: border-box;
          border-radius: 10px;

          color: white;
          font-weight: bold;
        }

        .item__name {
          font-family: var(--title-description-fontFamily);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 27px;
          letter-spacing: 0em;
        }
      }
    }
  }

  // .card-catalog__horizontal-divider {
  //   width: 800%;
  //   height: 0px;

  //   border: 2px solid #003c49;
  // }

  .card-catalog__filters {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 4px 7px -2.5px,
      rgba(0, 0, 0, 0.3) 0px 2px 4px -2px;
    padding: 40px 20px;
    margin: 10px 0 30px 0;

    border: 2px solid #47b0e2;
    border-radius: 2px;

    .filters__top {
      // padding-bottom: 15px;

      .top__label {
        font-family: var(--all-input-fontFamily);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 27px;
        letter-spacing: 0em;

        padding-right: 10px;
      }

      .top__selected-filters {
        padding-right: 20px;

        .selected-filters__wrapper {
          border-radius: 5px;
          border: 2px solid #47b0e2;

          width: 480px;
          height: 50px;

          padding: 0 10px;

          .selected-filters__selection {
            padding: 5px;

            .selection__wrapper {
              background: #47b0e2;
              border-radius: 6px;

              width: fit-content;

              padding: 5px 15px;

              cursor: pointer;

              .selection__text {
                color: white;
              }

              .selection__remove {
                display: inline-block;

                color: white;

                font-weight: 700;

                padding-left: 5px;
              }
            }
          }
        }
      }

      .top__color-selection {
        border-radius: 5px;
        border: 2px solid #47b0e2;
        background-color: white;
        height: 50px;
        width: 140px;
        box-shadow: none;

        &:active,
        &:focus {
          box-shadow: none !important;
          background-color: white;
        }

        &:after {
          display: none;
        }

        .color-selection__toggle {
          .toggle__text {
            font-family: var(--all-input-fontFamily);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 21px;
            letter-spacing: 0em;
            color: black;
          }

          .toggle__icon {
            display: inline-block;

            width: 15px;
            height: 15px;

            margin-left: 5px;
          }
        }
      }
    }

    .filters__bottom {
      .bottom__item {
        padding: 10px;

        cursor: pointer;

        .item__wrapper {
          border: 2px solid #47b0e2;
          border-radius: 8px;

          padding: 5px 25px;

          .item__text {
            font-family: var(--all-input-fontFamily);
            font-size: var(--all-input-fontSize);
            font-style: normal;
            font-weight: normal;
          }

          .item__add {
            display: inline-block;
            font-weight: 700;
            padding-left: 10px;
          }
        }
      }
    }
  }
}

//MEDIA AREA

@mixin mediaCardCatalog(
  $fontSize1,
  $paddingBottom1,
  $paddingBottom2,
  $padding1,
  $width1,
  $height1,
  $fontSize2,
  $lineHeight1,
  $padding2,
  $margin1,
  $paddingBottom3,
  $fontSize3,
  $lineHeight2,
  $paddingRight1,
  $paddingRight2,
  $width2,
  $height2,
  $padding3,
  $padding4,
  $padding5,
  $paddingLeft1,
  $width3,
  $height3,
  $lineHeight3,
  $width5,
  $height5,
  $marginLeft1,
  $padding6,
  $padding7,
  $paddingLeft2
) {
  .card-catalog {
    .card-catalog__title {
      font-size: $fontSize1;
      padding-bottom: $paddingBottom1;
    }

    .card-catalog__theme-list {
      padding-bottom: $paddingBottom2;

      .theme-list__item {
        padding: $padding1;

        .item__wrapper {
          width: $width1;
          height: $height1;

          .item__name {
            font-size: $fontSize2;
            line-height: $lineHeight1;
          }
        }
      }
    }

    .card-catalog__filters {
      padding: $padding2;
      margin: $margin1;
      .filters__top {
        // padding-bottom: $paddingBottom3;

        .top__label {
          font-size: $fontSize3;
          line-height: $lineHeight2;
          padding-right: $paddingRight1;
        }

        .top__selected-filters {
          padding-right: $paddingRight2;

          .selected-filters__wrapper {
            width: $width2;
            height: $height2;
            padding: $padding3;

            .selected-filters__selection {
              padding: $padding4;

              .selection__wrapper {
                padding: $padding5;

                .selection__text {
                  font-size: $fontSize3;
                }

                .selection__remove {
                  padding-left: $paddingLeft1;
                }
              }
            }
          }
        }

        .top__color-selection {
          width: $width3;
          height: $height3;

          .color-selection__toggle {
            .toggle__text {
              font-size: $fontSize3;
              line-height: $lineHeight3;
            }

            .toggle__icon {
              width: $width5;
              height: $height5;
              margin-left: $marginLeft1;
            }
          }
        }
      }

      .filters__bottom {
        .bottom__item {
          padding: $padding6;

          .item__wrapper {
            padding: $padding7;

            .item__text {
              font-size: $fontSize3;
            }

            .item__add {
              padding-left: $paddingLeft2;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  @include mediaCardCatalog(
    $fontSize1: var(--major-title-fontSize),
    $paddingBottom1: 20px,
    $paddingBottom2: 20px,
    $padding1: 8px,
    $width1: 180px,
    $height1: 60px,
    $fontSize2: var(--all-description-fontSize),
    $lineHeight1: 27px,
    $padding2: 35px 20px,
    $margin1: 10px 0 30px 0,
    $paddingBottom3: 15px,
    $fontSize3: 15px,
    $lineHeight2: 27px,
    $paddingRight1: 10px,
    $paddingRight2: 10px,
    $width2: 420px,
    $height2: 50px,
    $padding3: 0px 8px,
    $padding4: 5px,
    $padding5: 5px 15px,
    $paddingLeft1: 5px,
    $width3: 100px,
    $height3: 50px,
    $lineHeight3: 5px,
    $width5: 14px,
    $height5: 12px,
    $marginLeft1: 5px,
    $padding6: 8px,
    $padding7: 5px 20px,
    $paddingLeft2: 6px
  );
}

@media (max-width: 768px) {
  @include mediaCardCatalog(
    $fontSize1: var(--major768-title-fontSize),
    $paddingBottom1: 15px,
    $paddingBottom2: 15px,
    $padding1: 6px,
    $width1: 135px,
    $height1: 50px,
    $fontSize2: var(--all768-description-fontSize),
    $lineHeight1: 20px,
    $padding2: 25px 15px,
    $margin1: 10px 0 25px 0,
    $paddingBottom3: 10px,
    $fontSize3: 14px,
    $lineHeight2: 15px,
    $paddingRight1: 5px,
    $paddingRight2: 5px,
    $width2: 290px,
    $height2: 45px,
    $padding3: 0px 4px,
    $padding4: 3px,
    $padding5: 5px 10px,
    $paddingLeft1: 5px,
    $width3: 90px,
    $height3: 45px,
    $lineHeight3: 10px,
    $width5: 13px,
    $height5: 11px,
    $marginLeft1: 5px,
    $padding6: 6px,
    $padding7: 5px 15px,
    $paddingLeft2: 4px
  );
}

@media (max-width: 480px) {
  @include mediaCardCatalog(
    $fontSize1: var(--major480-title-fontSize),
    $paddingBottom1: 15px,
    $paddingBottom2: 10px,
    $padding1: 5px,
    $width1: 135px,
    $height1: 42px,
    $fontSize2: var(--all480-description-fontSize),
    $lineHeight1: 16px,
    $padding2: 16px 8px,
    $margin1: 10px 0 20px 0,
    $paddingBottom3: 10px,
    $fontSize3: 12px,
    $lineHeight2: 15px,
    $paddingRight1: 5px,
    $paddingRight2: 5px,
    $width2: 180px,
    $height2: 35px,
    $padding3: 0px 1px,
    $padding4: 1px,
    $padding5: 1px 3px,
    $paddingLeft1: 1px,
    $width3: 65px,
    $height3: 35px,
    $lineHeight3: 0px,
    $width5: 12px,
    $height5: 10px,
    $marginLeft1: 2px,
    $padding6: 4px 2px,
    $padding7: 1px 3px,
    $paddingLeft2: 1px
  );
}
