.home__attraction {
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 40px;

  .attraction__header > span {
    font-family: var(--major-title-fontFamily);
    font-size: var(--major-title-fontSize);
    font-style: normal;
    font-weight: bold;
    line-height: 50px;
    letter-spacing: 0em;
    color: var(--color-forTitle);

    position: relative;

    .EkadTepi {
      color: #47b0e2;
    }
  }

  .attraction__sub-header {
    .attraction_text {
      font-family: var(--minor-title-fontFamily);
      font-size: var(--title-description-fontSize);
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
      padding-top: 20px;
      padding-bottom: 30px;
      width: 90%;
      color: #3d3d3d;
    }
  }

  .attraction_list {
    .attraction_list-box {
      margin: 15px;
      width: 250px;
      height: 320px;
      box-shadow: 0px 8px 10px 2px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      cursor: default;
    }

    .attraction_list-price {
      background-color: #edf8ff;
      transition: 0.2s ease-in-out;

      .attraction-price-title {
        font-family: Concert One;
        font-size: 22px;
        color: #0073c6;
        margin: 18px 0;
      }

      .attraction-price-description {
        margin: 0px 25px;
        font-family: Poppins;
        font-size: 15px;
        color: #2b2b2b;
      }
    }

    .attraction_list-fast {
      background-color: #fcffed;
      transition: 0.2s ease-in-out;

      .attraction-fast-title {
        font-family: Concert One;
        font-size: 22px;
        color: #dad308;
        margin: 18px 0;
      }

      .attraction-fast-description {
        margin: 0px 25px;
        font-family: Poppins;
        font-size: 15px;
        color: #2b2b2b;
      }
    }

    .attraction_list-easy {
      background-color: #ffeded;
      transition: 0.2s ease-in-out;

      .attraction-easy-title {
        font-family: Concert One;
        font-size: 22px;
        color: #ea2424;
        margin: 18px 0;
      }

      .attraction-easy-description {
        margin: 0px 25px;
        font-family: Poppins;
        font-size: 15px;
        color: #2b2b2b;
      }
    }

    .attraction_list-unlimited {
      background-color: #edfff4;
      transition: 0.2s ease-in-out;

      .attraction-unlimited-title {
        font-family: Concert One;
        font-size: 22px;
        color: #00c614;
        margin: 18px 0;
      }

      .attraction-unlimited-description {
        margin: 0px 25px;
        font-family: Poppins;
        font-size: 15px;
        color: #2b2b2b;
      }
    }
  }
}

.attraction-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: white;
  cursor: default;
}

.attraction_list-price:hover,
.attraction_list-fast:hover,
.attraction_list-easy:hover,
.attraction_list-unlimited:hover {
  transform: scale(1.05);
}

//MEDIA AREA

@mixin mediaAttraction(
  $space,
  $fontSize1,
  $fontSize2,
  $paddingTop1,
  $paddingBottom1,
  $margin1,
  $width1,
  $height1,
  $fontSize3,
  $margin2,
  $fontSize4,
  $margin3,
  $width2,
  $height2
) {
  .home__attraction {
    margin-top: $space;
    padding-top: $space;
    padding-bottom: $space;

    .attraction__header > span {
      font-size: $fontSize1;
    }

    .attraction__sub-header {
      .attraction_text {
        font-size: $fontSize2;
        padding-top: $paddingTop1;
        padding-bottom: $paddingBottom1;
      }
    }

    .attraction_list {
      .attraction_list-box {
        margin: $margin1;
        width: $width1;
        height: $height1;
      }

      .attraction_list-price {
        .attraction-price-title {
          font-size: $fontSize3;
          margin: $margin2;
        }

        .attraction-price-description {
          font-size: $fontSize4;
          margin: $margin3;
        }
      }

      .attraction_list-fast {
        .attraction-fast-title {
          font-size: $fontSize3;
          margin: $margin2;
        }

        .attraction-fast-description {
          font-size: $fontSize4;
          margin: $margin3;
        }
      }

      .attraction_list-easy {
        .attraction-easy-title {
          font-size: $fontSize3;
          margin: $margin2;
        }

        .attraction-easy-description {
          font-size: $fontSize4;
          margin: $margin3;
        }
      }

      .attraction_list-unlimited {
        .attraction-unlimited-title {
          font-size: $fontSize3;
          margin: $margin2;
        }
        .attraction-unlimited-description {
          font-size: $fontSize4;
          margin: $margin3;
        }
      }
    }
  }

  .attraction-circle {
    width: $width2;
    height: $height2;
  }
}

@media (max-width: 991px) {
  @include mediaAttraction(
    $space: 35px,
    $fontSize1: var(--major-title-fontSize),
    $fontSize2: var(--title-description-fontSize),
    $paddingTop1: 15px,
    $paddingBottom1: 25px,
    $margin1: 15px,
    $width1: 240px,
    $height1: 310px,
    $fontSize3: 22px,
    $margin2: 18px 0,
    $fontSize4: 15px,
    $margin3: 0px 25px,
    $width2: 100px,
    $height2: 100px
  );
}

@media (max-width: 768px) {
  @include mediaAttraction(
    $space: 30px,
    $fontSize1: var(--major768-title-fontSize),
    $fontSize2: var(--title768-description-fontSize),
    $paddingTop1: 10px,
    $paddingBottom1: 20px,
    $margin1: 10px,
    $width1: 210px,
    $height1: 270px,
    $fontSize3: 21px,
    $margin2: 15px 0,
    $fontSize4: 14px,
    $margin3: 0px 15px,
    $width2: 100px,
    $height2: 100px
  );
}

@media (max-width: 480px) {
  @include mediaAttraction(
    $space: 25px,
    $fontSize1: var(--major480-title-fontSize),
    $fontSize2: var(--title480-description-fontSize),
    $paddingTop1: 5px,
    $paddingBottom1: 15px,
    $margin1: 8px,
    $width1: 240px,
    $height1: 210px,
    $fontSize3: 20px,
    $margin2: 10px 0,
    $fontSize4: 13px,
    $margin3: 0px 10px,
    $width2: 90px,
    $height2: 90px
  );
}
