.app {
  height: 100%;
}

.ecard-app {
  background-color: black;
}
// Scrollbar
::-webkit-scrollbar {
  width: 7px;

  border-radius: 5px;

  &:horizontal {
    height: 7px;
  }
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;

  border-radius: 5px;

  &:hover {
    background: #555;
  }
}
