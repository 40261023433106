.display__personal-info {
  .personal-info__header {
    margin: 30px 50px;
    .header__photo {
      width: 130px;
      height: 150px;
      border: solid 4px #e6e6e6;
      margin-right: 20px;
    }

    .header__info {
      text-align: left;
      line-height: 25px;
      font-family: "Poppins";
      width: 100%;

      .header__info-name {
        font-weight: 600;
        font-size: 28px;
        margin: 5px 0;
      }

      .header__info-email {
        font-size: 18px;
        margin-bottom: 0;
        width: 90%;
        word-wrap: break-word;
      }
    }
  }

  //for label and input styling
  .component-input__label {
    font-family: var(--all-input-fontFamily);
    font-size: var(--all-input-fontSize);
    margin-left: 8px;
  }

  .component-input__input {
    font-size: var(--all-input-fontSize);
    border-radius: 10px;
    border: 2px solid #e9ecef;
  }

  //form layout style
  form {
    font-family: var(--all-input-fontFamily);
    font-size: var(--all-input-fontSize);
    margin: 0px 40px 30px 40px;

    line-height: 10px;

    .personal-info__fullname {
      margin: 25px 0px;
      margin-top: 0px;
    }

    .personal-info__name {
      margin: 25px 0px;
    }

    .personal-info__background {
      margin: 25px 0px;
      width: 100%;
      .personal-info__phone {
        margin-right: 15px;
        margin-bottom: 0px;
        width: 50%;
        .selected-flag {
          margin-left: 10px;
        }
        .Phone__input {
          margin-left: 5px;
          padding: 0.375rem 2.75rem;
          padding-right: 0rem;
        }
      }

      .sex__race {
        margin-bottom: 0px;
        width: 40%;
      }

      .background__age {
        margin-bottom: 0px;
        margin-left: 15px;
        width: 30%;
      }
    }

    .personal-info__address1 {
      margin: 25px 0px;
      width: 100%;

      .address1__street {
        margin-bottom: 0px;
        margin-right: 15px;
        width: 60%;
      }

      .address1__city {
        margin-bottom: 0px;
        width: 40%;
      }
    }

    .personal-info__address2 {
      .address2__postcode {
        margin-bottom: 0px;
        margin-right: 15px;
      }
      .address2__state {
        margin-bottom: 0px;
      }
      .address2__origin {
        margin-bottom: 0px;
        margin-left: 15px;
      }
    }

    .w-100 {
      margin: 25px 0px;

      .personal-info__edit,
      .personal-info__save {
        width: 50%;
        border-radius: 8px;
        margin: 0px 8px;
        padding: 16px 0;
      }
    }
  }
}

//MEDIA AREA

@media (max-width: 991px) {
  .display__personal-info {
    .personal-info__header {
      margin: 30px 50px;
      .header__photo {
        width: 110px;
        height: 130px;
        margin-right: 20px;
      }

      .header__info {
        text-align: left;
        line-height: 25px;

        .header__info-name {
          font-weight: 600;
          font-size: 25px;
          margin: 5px 0;
        }

        .header__info-email {
          font-size: 17px;
          margin-bottom: 0;
        }
      }
    }

    //for label and input styling
    .component-input__label {
      font-size: var(--all-input-fontSize);
      margin-left: 8px;
    }

    .component-input__input {
    }

    //form layout style
    form {
      font-size: var(--all-input-fontSize);
      margin: 0px 40px 30px 40px;
      line-height: 10px;

      .personal-info__fullname {
        margin: 25px 0px;
        margin-top: 0px;
      }

      .personal-info__name {
        margin: 25px 0px;
      }

      .personal-info__background {
        margin: 25px 0px;
        width: 100%;
        .personal-info__phone {
          margin-right: 15px;
          margin-bottom: 0px;
          width: 50%;
          .selected-flag {
            margin-left: 10px;
          }
          .Phone__input {
            margin-left: 5px;
            padding: 0.375rem 2.75rem;
            padding-right: 0rem;
          }
        }

        .sex__race {
          margin-bottom: 0px;
          width: 40%;
        }

        .background__age {
          margin-bottom: 0px;
          margin-left: 15px;
          width: 30%;
        }
      }

      .personal-info__address1 {
        margin: 25px 0px;
        width: 100%;

        .address1__street {
          margin-bottom: 0px;
          margin-right: 15px;
          width: 60%;
        }

        .address1__city {
          margin-bottom: 0px;
          width: 40%;
        }
      }

      .personal-info__address2 {
        .address2__postcode {
        }
        .address2__state {
        }
        .address2__origin {
        }
      }

      .w-100 {
        .personal-info__edit,
        .personal-info__save {
          padding: 16px 0;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .display__personal-info {
    .personal-info__header {
      margin: 30px 25px;
      .header__photo {
        width: 90px;
        height: 110px;
        border: solid 4px #e6e6e6;
        margin-right: 20px;
      }

      .header__info {
        text-align: left;

        .header__info-name {
          font-size: 24px;
          margin: 5px 0;
        }

        .header__info-email {
          font-size: 16px;
          margin-bottom: 0;
        }
      }
    }

    //for label and input styling
    .component-input__label {
      font-size: var(--all768-input-fontSize);
      margin-left: 6px;
    }

    .component-input__input {
      font-size: var(--all768-input-fontSize);
      border-radius: 8px;
      border: 2px solid #e9ecef;
    }

    //form layout style
    form {
      font-size: var(--all768-input-fontSize);
      margin: 0px 15px 30px 15px;
      line-height: 10px;

      .personal-info__background {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;

        .personal-info__phone {
          width: 100%;
          margin-right: 6px;

          .component-input__label {
            margin-left: 12px;
          }

          .selected-flag {
            margin-left: 10px;
          }
          .Phone__input {
            padding: 0.375rem 2.75rem;
            padding-right: 0rem;
            width: 100%;
          }
        }

        .sex__race {
          margin: 25px 0px;
          margin-bottom: 0px;
          width: 100%;
        }

        .background__age {
          margin: 25px 0px;
          margin-bottom: 0px;
          width: 100%;
        }
      }

      .personal-info__address1 {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;

        .address1__street {
          margin: 25px 0px;
          margin-bottom: 0px;
          margin-top: 0;
          width: 100%;
        }

        .address1__city {
          margin: 25px 0px;
          margin-bottom: 0px;
          width: 100%;
        }
      }

      .personal-info__address2 {
        .address2__postcode {
          margin-bottom: 0px;
          margin-right: 15px;
        }
        .address2__state {
          margin-bottom: 0px;
        }
        .address2__origin {
          margin-bottom: 0px;
          margin-left: 15px;
        }
      }
      .w-100 {
        .personal-info__edit,
        .personal-info__save {
          padding: 16px 0 !important;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .display__personal-info {
    .personal-info__header {
      margin: 30px 20px;
      .header__photo {
        width: 70px;
        height: 90px;
        border: solid 4px #e6e6e6;
        margin-right: 10px;
      }

      .header__info {
        text-align: left;

        .header__info-name {
          font-size: 22px;
          margin: 3px 0;
        }

        .header__info-email {
          font-size: 13px;
          line-height: 12px;
        }
      }
    }

    //for label and input styling
    .component-input__label {
      font-size: var(--all480-input-fontSize);
      margin-left: 6px;
    }

    .component-input__input {
      font-size: var(--all480-input-fontSize);
      border-radius: 6px;
      border: 2px solid #e9ecef;
    }

    //form layout style
    form {
      font-size: var(--all480-input-fontSize);
      margin: 0px 10px 30px 10px;
      line-height: 10px;

      .personal-info__background {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;

        .personal-info__phone {
          width: 100%;
          margin-right: 6px;

          .component-input__label {
            margin-left: 12px;
          }

          .selected-flag {
            margin-left: 10px;
          }
          .Phone__input {
            padding: 0.375rem 2.75rem;
            padding-right: 0rem;
            width: 100%;
          }
        }

        .sex__race {
          margin: 25px 0px;
          margin-bottom: 0px;
          width: 100%;
        }

        .background__age {
          margin: 25px 0px;
          margin-bottom: 0px;
          width: 100%;
        }
      }

      .personal-info__address1 {
        width: 100%;

        display: flex;
        flex-direction: column;
        align-items: center;

        .address1__street {
          margin: 25px 0px;
          margin-bottom: 0px;
          margin-top: 0;
          width: 100%;
        }

        .address1__city {
          margin: 25px 0px;
          margin-bottom: 0px;
          width: 100%;
        }
      }

      .personal-info__address2 {
        .address2__postcode {
          margin-bottom: 0px;
          margin-right: 15px;
        }
        .address2__state {
          margin-bottom: 0px;
        }
        .address2__origin {
          margin-bottom: 0px;
          margin-left: 15px;
        }
      }

      .w-100 {
        .personal-info__edit,
        .personal-info__save {
          padding: 14px 0 !important;
        }
      }
    }
  }
}
