@mixin flexBox(
  $display,
  $flex-directions: row,
  $justify-content: null,
  $align-items: null,
  $flex: null
) {
  display: $display;
  flex-direction: $flex-directions;
  justify-content: $justify-content;
  align-items: $align-items;
  flex: $flex;
}

@mixin containerhome(
  $height: null,
  $width: null,
  $font-size: null,
  $text-align: null,
  $line-height: null,
  $text-transform: null
) {
  height: $height;
  width: $width;
  font-size: $font-size;
  text-align: $text-align;
  text-transform: $text-transform;
  line-height: $line-height;
}

.general_background {
  img {
    transition: opacity 2s linear 1s;
  }
}

//for items inside cards
.home__containerBody {
  @include flexBox(
    $display: flex,
    $flex-directions: column,
    $justify-content: center,
    $align-items: center
  );

  @include containerhome(
    $height: 90%,
    $width: 90%,
    $text-align: center,
  );

  .home__containerBodyTitle {
    margin: 0;

    .home__text3 {
      @include containerhome($font-size: 25px, $text-transform: capitalize);
      margin: 0;
    }
  }

  .home__containerBodyDate {
    margin: 0;

    .home__text3 {
      @include containerhome($font-size: 25px, $text-transform: capitalize);
      margin: 0;
    }
  }

  .home__containerBodyName {
    margin: 50px 0px;

    .home__text1 {
      @include containerhome($font-size: 35px);
      margin: 0;
      // line-height: 100%
    }
  }
}

//MEDIA AREA

@mixin mediahome($font-size1, $font-size2) {
  .home__containerBody {
    .home__containerBodyTitle {
      .home__text3 {
        font-size: $font-size1;
      }
    }

    .home__containerBodyDate {
      .home__text3 {
        font-size: $font-size1;
      }
    }

    .home__containerBodyName {
      .home__text1 {
        font-size: $font-size2;
      }
    }
  }
}

// font kecik
@media (min-width: 393px) {
  @include mediahome($font-size1: 28px, $font-size2: 40px);
}

//font med
@media (min-width: 482px) {
  @include mediahome($font-size1: 32px, $font-size2: 45px);
}

//tablet
@media (min-width: 576px) {
  @include mediahome($font-size1: 36px, $font-size2: 50px);
}

//pc
@media (min-width: 768px) {
  @include mediahome($font-size1: 39px, $font-size2: 55px);
}
