.home__why-us {
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 40px;

  .why-us__header > span {
    font-family: var(--major-title-fontFamily);
    font-size: var(--major-title-fontSize);
    font-style: normal;
    font-weight: bold;
    line-height: 50px;
    letter-spacing: 0em;
    color: var(--color-forTitle);

    position: relative;

    .EkadTepi {
      color: #47b0e2;
    }
  }

  .why-us__sub-header {
    .why-us_text {
      font-family: var(--minor-title-fontFamily);
      font-size: var(--title-description-fontSize);
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
      padding-top: 20px;
      padding-bottom: 30px;
      width: 90%;
      color: #3d3d3d;
    }
  }

  .why-us__details {
    //width: 800px;
    margin: auto;

    .details__rsvp {
      padding-right: 15px;

      .rsvp__wrapper {
        height: 596px;
        width: 100%;

        .rsvp__header {
          padding-bottom: 20px;
        }

        .rsvp__description {
          line-height: 30px;
          padding: 10px;
        }

        &:hover {
          .rsvp__description {
            display: initial;
            color: white;
          }

          .header__top {
            color: white;
          }
        }
      }
    }

    @media (max-width: 991px) {
      .details__rsvp {
        padding-bottom: 15px;
        padding-right: 0px;
        .rsvp__wrapper {
          height: 290px !important;
        }
      }
    }

    .details__section-wrapper {
      .section-wrapper__sop {
        padding-right: 15px;
        padding-bottom: 15px;

        .sop__wrapper {
          height: 290px;
          width: 100%;

          .sop__header {
            padding-bottom: 10px;
          }

          .sop__description {
            line-height: 25px;
            padding: 0 10px;
          }

          &:hover {
            .sop__description {
              display: initial;
              color: white;
            }

            .header__top {
              color: white;
            }
          }
        }
      }

      .section-wrapper__create-your-own {
        padding-bottom: 15px;

        .create-your-own__wrapper {
          height: 290px;
          width: 100%;

          .create-your-own__header {
            padding-bottom: 10px;
          }

          .why_us-description {
            line-height: 25px;
            padding: 0 15px;
          }

          &:hover {
            .why_us-description {
              display: initial;
              color: white;
            }

            .header__top {
              color: white;
            }
          }
        }
      }
    }

    .section-wrapper__easy-for-user {
      .easy-for-user__wrapper {
        height: 290px;
        width: 100%;

        .easy-for-user__header {
          padding-bottom: 10px;
        }

        .easy-for-user__description {
          line-height: 30px;
          padding: 0 15px;
        }

        &:hover {
          .easy-for-user__description {
            display: initial;
            color: white;
          }

          .header__top {
            color: white;
          }
        }
      }
    }
  }

  .general-box {
    //border: 3px solid #47b0e2;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
      rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;

    cursor: pointer;

    &:hover {
      background-color: #47b0e2;
    }

    .header__top {
      font-family: Concert One;
      font-style: normal;
      font-weight: normal;
      font-size: 30px;
      line-height: 35px;
      color: #47b0e2;
    }

    .header__bottom {
      font-family: Cookie;
      font-size: 25px;
      font-style: normal;
      font-weight: 400;
      line-height: 35px;
      letter-spacing: 0em;
      text-align: center;
      color: #4f758a;
    }

    .why_us-description {
      display: none;

      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
    }
  }
}

@media (max-width: 991px) {
  .home__why-us {
    margin-top: 35px;
    padding-top: 35px;
    padding-bottom: 35px;

    .why-us__header > span {
      font-size: var(--major-title-fontSize);
    }

    .why-us__sub-header {
      .why-us_text {
        font-family: var(--minor-title-fontFamily);
        font-size: var(--title-description-fontSize);
        padding-top: 15px;
        padding-bottom: 25px;
      }
    }

    .why-us__details {
      .details__rsvp {
        padding-bottom: 15px;
        padding-right: 0px;

        .rsvp__wrapper {
          height: 290px !important;
          width: 100%;

          .rsvp__header {
            padding-bottom: 10px;
          }

          .rsvp__description {
            line-height: 30px;
            width: 85%;
          }
        }
      }

      .details__section-wrapper {
        .section-wrapper__sop {
          padding-right: 15px;
          padding-bottom: 15px;

          .sop__wrapper {
            height: 290px;
            width: 100%;

            .sop__header {
              padding-bottom: 10px;
            }

            .sop__description {
              line-height: 25px;
              width: 95%;
            }
          }
        }

        .section-wrapper__create-your-own {
          padding-bottom: 15px;

          .create-your-own__wrapper {
            height: 290px;
            width: 100%;

            .create-your-own__header {
              padding-bottom: 10px;
            }

            .why_us-description {
              line-height: 25px;
              width: 95%;
            }
          }
        }
      }

      .section-wrapper__easy-for-user {
        .easy-for-user__wrapper {
          height: 290px;
          width: 100%;

          .easy-for-user__header {
            padding-bottom: 10px;
          }

          .easy-for-user__description {
            line-height: 30px;
            width: 85%;
          }
        }
      }
    }

    .general-box {
      .header__top {
        font-size: 30px;
      }

      .header__bottom {
        font-size: 25px;
        line-height: 35px;
      }

      .why_us-description {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 768px) {
  .home__why-us {
    margin-top: 30px;
    padding-top: 30px;
    padding-bottom: 30px;

    .why-us__header > span {
      font-size: var(--major768-title-fontSize);
    }

    .why-us__sub-header {
      .why-us_text {
        font-size: var(--title768-description-fontSize);
        padding-top: 10px;
        padding-bottom: 20px;
      }
    }

    .why-us__details {
      .details__rsvp {
        padding-bottom: 15px;
        padding-right: 0px;

        .rsvp__wrapper {
          height: 240px !important;

          .rsvp__header {
            padding-bottom: 5px;
          }

          .rsvp__description {
            line-height: 25px;
            width: 85%;
          }
        }
      }

      .details__section-wrapper {
        .section-wrapper__sop {
          padding-right: 15px;
          padding-bottom: 15px;

          .sop__wrapper {
            height: 290px;

            .sop__header {
              padding-bottom: 10px;
            }

            .sop__description {
              line-height: 25px;
              width: 95%;
            }
          }
        }

        .section-wrapper__create-your-own {
          padding-bottom: 15px;

          .create-your-own__wrapper {
            height: 290px;

            .create-your-own__header {
              padding-bottom: 10px;
            }

            .why_us-description {
              line-height: 25px;
              width: 95%;
            }
          }
        }
      }

      .section-wrapper__easy-for-user {
        .easy-for-user__wrapper {
          height: 240px;

          .easy-for-user__header {
            padding-bottom: 10px;
          }

          .easy-for-user__description {
            line-height: 25px;
            width: 85%;
          }
        }
      }
    }

    .general-box {
      .header__top {
        font-size: 27px;
      }

      .header__bottom {
        font-size: 22px;
        line-height: 30px;
      }

      .why_us-description {
        font-size: 13px;
      }
    }
  }
}

@media (max-width: 480px) {
  .home__why-us {
    margin-top: 25px;
    padding-top: 25px;
    padding-bottom: 25px;

    .why-us__header > span {
      font-size: var(--major480-title-fontSize);
    }

    .why-us__sub-header {
      .why-us_text {
        font-size: var(--title480-description-fontSize);
        padding-top: 5px;
        padding-bottom: 15px;
      }
    }

    .why-us__details {
      .details__rsvp {
        padding-bottom: 15px;
        padding-right: 0px;

        .rsvp__wrapper {
          height: 240px !important;

          .rsvp__header {
            padding-bottom: 5px;
          }

          .rsvp__description {
            width: 90%;
          }
        }
      }

      .details__section-wrapper {
        .section-wrapper__sop {
          padding-right: 5px;
          padding-bottom: 15px;

          .sop__wrapper {
            height: 290px;

            .sop__header {
              padding-bottom: 10px;
            }

            .sop__description {
              width: 98%;
            }
          }
        }

        .section-wrapper__create-your-own {
          padding-bottom: 15px;
          padding-left: 5px;

          .create-your-own__wrapper {
            height: 290px;

            .create-your-own__header {
              padding-bottom: 10px;
            }

            .why_us-description {
              width: 98%;
            }
          }
        }
      }

      .section-wrapper__easy-for-user {
        .easy-for-user__wrapper {
          height: 240px;

          .easy-for-user__header {
            padding-bottom: 10px;
          }

          .easy-for-user__description {
            width: 90%;
          }
        }
      }
    }

    .general-box {
      .header__top {
        font-size: 25px;
        padding: 0 10px;
        text-align: center !important;
        line-height: 30px;
      }

      .header__bottom {
        font-size: 20px;
        line-height: 20px;
        padding-top: 10px;
      }

      .why_us-description {
        font-size: 11px;
        line-height: 18px !important;
      }
    }
  }
}
