:root {
  --color-primary: #47b0e2;
  --color-secondary: #ff4a32;
  --color-disabled: #ebebe4;
  --color-important: red;
  --color-pending: orange;
  --color-warning: orange;
  --color-success: #41d888;
  --color-failure: #d8534d;

  //for website title color
  --color-forTitle: #3b3b3b;

  //for Ecard preview default setting
  --ecard-default-color: #3c3638;
  --ecard-default-fontColor: #3c3638;
  --ecard-default-backgroundColor: #3c3638;
  --ecard-default-iconColor: #3c3638;
}
