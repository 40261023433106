.media {
  display: flex;
  flex-direction: column;
  margin: 0 0 15px 0;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  // border: 2px solid rgb(236, 236, 236);

  .media__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0;

    &Author {
      text-align: left;
      margin: 6px 8px;
      font-family: "Poppins", sans-serif;
      font-size: 14px; //font size penghantar
      font-weight: 600;
      letter-spacing: 0.5px;
    }

    &Settings {
      margin: 6px 8px;
      color: black;
      font-size: 22px;
    }
  }

  .media__source {
    width: 100%;
    img {
      object-fit: contain;
    }
  }

  .media__info {
    width: 100%;

    &Caption {
      border-radius: 10px;
      margin: 10px 8px;
      font-family: "Poppins", sans-serif;
      font-size: 14px; //font size description
    }

    .media_timestamp {
      position: absolute;
      background-color: red;
      font-size: 100px;
    }
  }
}

//MEDIA AREA

@mixin mediamedia(
  $margin,
  $margin1,
  $fontsize1,
  $margin2,
  $fontsize2,
  $fontsize3
) {
  .media {
    margin-bottom: $margin;
    .media__header {
      &Author {
        margin: $margin1;
        font-size: $fontsize1; //font size penghantar
      }
      &Settings {
        margin: $margin1;
        font-size: $fontsize2;
      }
    }

    .media__info {
      &Caption {
        margin: $margin2;
        font-size: $fontsize3; //font size description
      }
    }
  }
}

@media (min-width: 393px) {
  @include mediamedia(
    $margin: 17px,
    $margin1: 8px 10px,
    $fontsize1: 15px,
    $fontsize2: 24px,
    $margin2: 14px 10px,
    $fontsize3: 15px
  );
}

@media (min-width: 482px) {
  @include mediamedia(
    $margin: 19px,
    $margin1: 10px 12px,
    $fontsize1: 16px,
    $fontsize2: 26px,
    $margin2: 18px 12px,
    $fontsize3: 16px
  );
}

@media (min-width: 576px) {
  @include mediamedia(
    $margin: 21px,
    $margin1: 12px 14px,
    $fontsize1: 17px,
    $fontsize2: 28px,
    $margin2: 22px 11px,
    $fontsize3: 14px
  );
}

@media (min-width: 768px) {
  @include mediamedia(
    $margin: 23px,
    $margin1: 14px 16px,
    $fontsize1: 18px,
    $fontsize2: 30px,
    $margin2: 26px 16px,
    $fontsize3: 18px
  );
}
