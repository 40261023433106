//home

.home__containerBodyTitle > .home__text3 {
  color: var(--ecard-default-fontColor);
  font-family: var(--ecard-default-fontfamily2);
}

.home__containerBodyName {
  color: var(--ecard-default-fontColor);
  font-family: var(--ecard-default-fontfamily3);
}

.home__containerBodyDate > .home__text3 {
  color: var(--ecard-default-fontColor);
  font-family: var(--ecard-default-fontfamily2);
}

//content

.countdown > .countdown__text3 {
  color: var(--ecard-default-fontColor);
  font-family: var(--ecard-default-fontfamily1);
}

.countdown__item {
  color: var(--ecard-default-fontColor);
  font-family: var(--ecard-default-fontfamily1);
}

.countdown__item > .countdown__timer {
  color: var(--ecard-default-fontColor);
  font-family: var(--ecard-default-fontfamily1);
}

.icons {
  color: var(--ecard-default-fontColor);
  text-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.a {
  color: var(--ecard-default-fontColor);
  font-family: var(--ecard-default-fontfamily1);
}

// semua header

.header > #tajuk {
  color: var(--ecard-default-fontColor);
  font-family: var(--ecard-default-fontfamily1);
}

//contact

.person {
  border: 1px solid var(--ecard-default-fontColor);
  border-width: 2px !important;
}

.person > div > p {
  color: var(--ecard-default-fontColor);
  font-family: var(--ecard-default-fontfamily1);
}

.ws,
.phone {
  background: var(--ecard-default-fontColor);
  -webkit-text-fill-color: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

//butiran

.b,
#pengantin {
  color: var(--ecard-default-fontColor);
  font-family: var(--ecard-default-fontfamily1);
}

//aturcara

.box_list > #list_aturcara {
  color: var(--ecard-default-fontColor);
  font-family: var(--ecard-default-fontfamily1);
}

//lokasi

iframe {
  border: 2px solid var(--ecard-default-fontColor);
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.3);
}

.location__menu {
  .lokasi__icon a {
    color: var(--ecard-default-fontColor);
  }

  .d {
    color: var(--ecard-default-fontColor);
    font-family: var(--ecard-default-fontfamily1);
  }
}

//rsvp

.sesi {
  box-shadow: rgba(0, 0, 0, 0.6) 0px 1px 4px;
  border-radius: 12px;
  overflow: hidden;

  .sesi__title .rsvp__sesi {
    color: var(--ecard-default-fontColor);
    font-family: var(--ecard-default-fontfamily1);
    .rsvp__sesiTime {
      color: var(--ecard-default-fontColor);
      font-family: var(--ecard-default-fontfamily1);
    }
  }
}

//album
