.about-us__send-email {
  font-family: var(--all-input-fontFamily);
  font-size: var(--all-input-fontSize);

  width: 80%;
  border: 2px solid #47b0e2;
  border-radius: 2px;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 5px 10px, rgba(0, 0, 0, 0.23) 0px 3px 3px;

  padding: 30px;

  form {
    position: relative;
    max-width: 450px;
    width: 80%;

    .send-email__name {
      border-bottom: 2px solid #47b0e2;
      border-radius: 0;
      margin-bottom: 20px;
      padding: 0;

      .name__label {
        padding-left: 6px;
        margin: 0;
      }

      .name__input {
        border: none;
        outline: none;
        box-shadow: none;
        font-size: var(--all-input-fontSize);
        padding-left: 3px;
      }
    }

    .send-email__email {
      border-bottom: 2px solid #47b0e2;
      border-radius: 0;
      margin-bottom: 20px;
      padding: 0;

      .email__label {
        padding-left: 6px;
        margin: 0;
      }

      .email__input {
        border: none;
        font-size: var(--all-input-fontSize);
        outline: none;
        box-shadow: none;
        padding-left: 3px;
      }
    }

    .send-email__title {
      border-bottom: 2px solid #47b0e2;
      border-radius: 0;
      margin-bottom: 30px;
      padding: 0;

      .title__label {
        padding-left: 6px;
        margin: 0;
      }

      .title__input {
        border: none;
        font-size: var(--all-input-fontSize);
        outline: none;
        box-shadow: none;
        padding-left: 3px;
      }
    }

    .send-email__msg {
      border: 2px solid #47b0e2;
      border-radius: 0;
      margin-bottom: 20px;
      padding: 0;

      .msg__label {
        margin: 0;
        padding: 6px;
      }

      .msg__input {
        border: none;
        height: 160px;
        resize: none;
        font-size: var(--all-input-fontSize);
        outline: none;
        box-shadow: none;
        padding-left: 0px;
      }
    }

    .send-email__submit {
      border: 2px solid #47b0e2;
      background-color: #47b0e2;
      color: #ffffff;
      padding: 13px 46px;
      margin-right: 10px;
      font-size: var(--all-button-fontSize);
    }

    .component-input__error-msg {
      position: absolute;
      right: 7%;
      text-align: right;
      width: fit-content;
      margin: 0;
    }
  }
  .email-svg {
    margin-left: 10px;
  }
}

//MEDIA AREA

@mixin mediaSendEmail(
  $fontSize1,
  $padding1,
  $width1,
  $marginBottom1,
  $marginBottom2,
  $paddingLeft1,
  $height1,
  $padding2,
  $marginRight1,
  $display1
) {
  .about-us__send-email {
    font-size: $fontSize1;
    padding: $padding1;

    form {
      width: $width1;

      .send-email__name {
        margin-bottom: $marginBottom1;

        .name__label {
          padding-left: $paddingLeft1;
        }

        .name__input {
          font-size: $fontSize1;
        }
      }

      .send-email__email {
        margin-bottom: $marginBottom1;

        .email__label {
          padding-left: $paddingLeft1;
        }

        .email__input {
          font-size: $fontSize1;
        }
      }

      .send-email__title {
        margin-bottom: $marginBottom2;

        .title__label {
          padding-left: $paddingLeft1;
        }

        .title__input {
          font-size: $fontSize1;
        }
      }

      .send-email__msg {
        margin-bottom: $marginBottom1;
        .msg__label {
          padding-left: $paddingLeft1;
        }

        .msg__input {
          height: $height1;

          font-size: $fontSize1;
        }
      }

      .send-email__submit {
        padding: $padding2;
        margin-right: $marginRight1;
      }

      .component-input__error-msg {
      }
    }
    .email-svg {
      display: $display1;
    }
  }
}

@media (max-width: 991px) {
  @include mediaSendEmail(
    $fontSize1: var(--all-input-fontSize),
    $padding1: 30px,
    $width1: 100%,
    $marginBottom1: 20px,
    $marginBottom2: 30px,
    $paddingLeft1: 6px,
    $height1: 160px,
    $padding2: 13px 46px,
    $marginRight1: 10px,
    $display1: none
  );
}

@media (max-width: 768px) {
  @include mediaSendEmail(
    $fontSize1: var(--all768-input-fontSize),
    $padding1: 25px,
    $width1: 100%,
    $marginBottom1: 15px,
    $marginBottom2: 25px,
    $paddingLeft1: 4px,
    $height1: 160px,
    $padding2: 10px 32px,
    $marginRight1: 8px,
    $display1: none
  );
}

@media (max-width: 480px) {
  @include mediaSendEmail(
    $fontSize1: var(--all480-input-fontSize),
    $padding1: 20px,
    $width1: 100%,
    $marginBottom1: 15px,
    $marginBottom2: 25px,
    $paddingLeft1: 2px,
    $height1: 160px,
    $padding2: 10px 26px,
    $marginRight1: 6px,
    $display1: none
  );
}
