.shimmer-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: shimmering 2s linear infinite;

    .shimmer {
        width: 50%;
        height: 100%;
        background: rgba(255, 255, 255, 0.2);
        transform: skewX(-20deg);
        box-shadow: 0 0 30px 30px rgba(255, 255, 255, 0.05)
    }
}

.dark .shimmer {
    background: rgba(255, 255, 255, 0.05);
}

//animations.scss"
@keyframes shimmering {
    0% {transform:  translateX(-150%);}
    25% {transform:  translateX(-60%);}
    50% {transform:  translateX(150%);}
    75% {transform:  translateX(60%) skewX(+30deg);}
    100% {transform:  translateX(-150%) skewX(+30deg);}
}