.personal-info__phone{

    .phone__label{

    }

    
}

.intl-tel-input{

    .Phone__input{
        border-radius: 10px;
        border: 2px solid #4f758a;

        &:focus{
            color: #495057;
            background-color: #fff;
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
    }
}

input[disabled] {
    background-color: #e9ecef;
 }