@mixin flexBox(
  $display,
  $flex-directions: row,
  $justify-content: null,
  $align-items: null,
  $flex: null
) {
  display: $display;
  flex-direction: $flex-directions;
  justify-content: $justify-content;
  align-items: $align-items;
  flex: $flex;
}

@mixin containerBox($display, $height, $width, $border-radius) {
  display: $display;
  height: $height;
  width: $width;
  border-radius: $border-radius;
}

.contact_component {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  background-color: rgb(236, 236, 236);
  height: 100%;
  width: 100%;
  border-radius: 30px;

  .contact_header {
    width: 100%;
    height: 10%;
    padding: 15px;
    background: #ffffff;
    border-radius: 30px 30px 0 0;

    .contact_header_back {
      display: flex !important;
      justify-content: flex-start !important;
      width: 20%;
      font-size: 24px;
    }
    .contact_header_title {
    
    }

    .contact_header_empty {
      width: 20%;
    }
  }
  
  .contact__container {
    padding: 0;
    height: 90%;
    width: 100%;
    border-radius: 0 0 30px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    //for items inside cards
    .inner__container {
      @include flexBox($display: flex, $flex-directions: column, $flex: 1);
      width: 100%;

      .container__person {
        // @include flexBox(
        //   $display: flex,
        //   $flex-directions: column,
        //   $align-items: center
        // );
        // justify-content: flex-start;

        // margin-top: 10px;
        width: 100%;
        height: 100%;
        overflow: auto;

        > div {
          margin-top: 10px !important;
          width: 80%;
        }
      }
    }
  }
}

.pishang {
  margin: 5px auto;

  .followtitle > .contact__text6 {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    text-align: center;
    margin: 5px 0px;
  }

  .wrapper {
    @include flexBox(
      $display: flex,
      $justify-content: center,
      $align-items: center
    );

    .mediabutton {
      @include containerBox(inline-block, 24px, 24px, 100px);

      margin: 0 7px;
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);

      a {
        
        &:hover{
          text-decoration: none;
        }
      }

      .mediaicon {
        @include containerBox(inline-block, 24px, 24px, 100px);

        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 16px;
      }

      &:nth-child(1) .mediaicon {
        background: lightseagreen;
      }

      &:nth-child(2) .mediaicon {
        // background: #e1306c;
        background: #25d366;

        .ws {
          background-color: white;
        }
      }

      &:nth-child(3) .mediaicon {
        background: #3b5998;
      }

      &:nth-child(4) .mediaicon {
        background:  #E1306C;
      }
    }
  }
}

//MEDIA AREA

@mixin media($font-size1, $height1, $width1, $margin, $font-size2) {
  .inner__container {
    .container__person {
    }
  }

  .pishang {
    .followtitle > .contact__text6 {
      font-size: $font-size1;
    }

    .wrapper {
      .mediabutton {
        height: $height1;
        width: $width1;
        margin: $margin;

        .mediaicon {
          height: $height1;
          width: $width1;
          font-size: $font-size2;
        }
      }
    }
  }
}

@media (min-width: 393px) {
  @include media(
    $font-size1: 13px,
    $height1: 24px,
    $width1: 24px,
    $margin: 0 9px,
    $font-size2: 15px
  );
}

@media (min-width: 482px) {
  @include media(
    $font-size1: 14px,
    $height1: 26px,
    $width1: 26px,
    $margin: 0 11px,
    $font-size2: 16px
  );
}

@media (min-width: 576px) {
  @include media(
    $font-size1: 15px,
    $height1: 28px,
    $width1: 28px,
    $margin: 0 13px,
    $font-size2: 17px
  );
}

@media (min-width: 768px) {
  @include media(
    $font-size1: 16px,
    $height1: 30px,
    $width1: 30px,
    $margin: 0 15px,
    $font-size2: 18px
  );
}
