.inputs__gallery-step {
  .gallery-step__title {
    width: 430px;
    height: 67px;

    background-color: #333434;

    color: white;

    padding: 16px 14px;

    font-family: Poppins;
    font-size: 16px;

    display: flex;
    align-items: center;
  }

  .gallery-step__description {
    padding-top: 30px;
    padding-left: 15px;
  }
}

//MEDIA AREA

// @media (max-width: 991px) {
// }

@media (max-width: 768px) {
  .inputs__gallery-step {
    .gallery-step__title {
      width: 430px;
      height: 67px;
    }
  }
}

@media (max-width: 480px) {
  .inputs__gallery-step {
    .gallery-step__title {
      width: 285px;
      height: 65px;
    }
  }
}
