.skeleton-wrapper {
    margin: 15px 10px;

    .skeleton-card-list{
      

        .skeleton.thumbnail{
            width: 240px;
            height: 354px;
        }

        .skeleton.title{
            margin-top: 6px;
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
            letter-spacing: 0em;
        }
    }
}
