.display__card-info {
  margin: 30px 0px;
  .card-info__header {
    font-family: var(--minor-title-fontFamily);
    font-size: var(--minor-title-fontSize);
  }

  .card-info__content {
    margin: 0px 40px;

    .card-info__selections {
      margin-bottom: 30px;

      .selections__item-wrapper {
        .selections__item {
          background: #ffffff;
          padding: 10px;
          margin-left: 25px;
          margin-right: 25px;
          cursor: pointer;

          &.selection__item_selected {
            border-bottom: 4px solid var(--color-primary);
            border-radius: 5%;
          }

          .item__header {
            font-family: var(--title-description-fontFamily);
            font-size: var(--title-description-fontSize);
            line-height: 15px;
          }
        }
      }
    }

    .card-info__details {
      height: 550px;
    }
  }
}

//MEDIA AREA

@media (max-width: 991px) {
  .display__card-info {
    margin: 30px 0px;
    .card-info__header {
      font-size: var(--minor-title-fontSize);
    }

    .card-info__content {
      margin: 0px 30px;

      .card-info__selections {
        margin-bottom: 30px;

        .selections__item-wrapper {
          .selections__item {
            padding: 10px;
            margin-left: 25px;
            margin-right: 25px;

            .item__header {
              font-size: var(--title-description-fontSize);
              line-height: 15px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .display__card-info {
    margin: 30px 0px;
    .card-info__header {
      font-size: var(--minor768-title-fontSize);
    }

    .card-info__content {
      margin: 0px 30px;

      .card-info__selections {
        margin-bottom: 20px;

        .selections__item-wrapper {
          .selections__item {
            padding: 10px 8px;
            margin-left: 15px;
            margin-right: 15px;

            .item__header {
              font-size: var(--title768-description-fontSize);
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .display__card-info {
    margin: 30px 0px;
    .card-info__header {
      font-size: var(--minor480-title-fontSize);
    }

    .card-info__content {
      margin: 0px 0px;

      .card-info__selections {
        margin-bottom: 20px;

        .selections__item-wrapper {
          .selections__item {
            padding: 10px 8px;
            margin-left: 15px;
            margin-right: 15px;

            .item__header {
              font-size: var(--title480-description-fontSize);
              line-height: 22px;
            }
          }
        }
      }
    }
  }
}
