.gallery_component {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  background-color: rgb(236, 236, 236);
  height: 100%;
  width: 100%;
  border-radius: 30px;

  .gallery_header {
    width: 100%;
    height: 10%;
    padding: 15px;
    background: #ffffff;
    border-radius: 30px 30px 0 0;

    .gallery_header_back {
      display: flex !important;
      justify-content: flex-start !important;
      width: 20%;
      font-size: 24px;
    }
    .gallery_header_title {
      width: 20%;
    }
    .gallery_header_add {
      width: 20%;

      .add__icon {
        .icons {
          font-size: 24px;
        }
      }
    }
  }

  .gallery__container {
    padding: 0;
    height: 90%;
    width: 100%;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .medias__timeline {
      flex: 1;
      width: 100%;

      padding: 0px;
      //box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
      overflow: scroll;
      overflow-x: hidden;
      //border-radius: 20px;
      overscroll-behavior: contain;

      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Internet Explorer 10+ */

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      .medias__timelineAdd {
        // margin: auto;
        // display: grid;
        // height: 100%;

        .icons {
          position: absolute;
          top: 45%;
          right: 50%;
          transform: translate(50%, -50%);
          font-size: 20px;
          visibility: hidden;
        }
      }
    }
  }
}

//MEDIA AREA

@mixin gallerymedia($padding, $fontsize1, $fontsize2) {
  .gallery_component {
    .gallery_header {
      padding: $padding;

      .gallery_header_back {
        font-size: $fontsize1;
      }
      .gallery_header_title {
      }

      .gallery_header_add {
        .add__icon {
          .icons {
            font-size: $fontsize2;
          }
        }
      }
    }

    .gallery__container {
      .medias__timeline {
        .medias__timelineAdd {
          .icons {
          }
        }
      }
    }
  }
}

@media (min-width: 393px) {
  @include gallerymedia($padding: 18px, $fontsize1: 26px, $fontsize2: 26px);
}

@media (min-width: 482px) {
  @include gallerymedia($padding: 21px, $fontsize1: 28px, $fontsize2: 28px);
}

@media (min-width: 576px) {
  @include gallerymedia($padding: 24px, $fontsize1: 30px, $fontsize2: 30px);
}

@media (min-width: 768px) {
  @include gallerymedia($padding: 27px, $fontsize1: 32px, $fontsize2: 32px);
}
