.data__container {
  flex: 1;
  position: absolute;
  padding: 0;
  height: 85%;
  width: 100%;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #f6f6f6;

  .data__timeline {
    flex: 1;
    width: 100%;
    margin: 10px 0px;

    padding: 0px;
    overflow: scroll;
    overflow-x: hidden;
    overscroll-behavior: contain;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
  }
}
