//base
.skeleton {

    background: #ddd;
    border-radius: 4px;

    &.text {
        width: 100%;
        height:12px;
    }


    &.title {
        width: 50%;
        height: 20px;
        margin-bottom: 15px;
    }

    &.thumbnail {
        width: 100px;
        height: 100px;
    }

    
    &.avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
    }

    &.img {

    }
}
.skeleton-wrapper {
    position: relative;
    overflow: hidden;
}

// themes
.skeleton-wrapper.light{
    background: #f2f2f2;
}

.skeleton-wrapper.dark{
    background: #444;
}

.skeleton-wrapper.dark .skeleton{
    background: #777;
}