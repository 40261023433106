.component-input-content-expander {
    max-width: 90vw;

    .component-input-content-expander__main-display {
        width: 100%;

        .main-display__text-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 100%;
        }
    }

    .component-input-content-expander__expander-icon {
        margin-left: 15px;
    }

    .component-input-content-expander__content-inputs {
        width: 430px;
        max-width: 100%;

        .content-details__outer-wrapper {

            &:last-child {
                .outer-wrapper__add-section {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                }

                .outer-wrapper__horizontal-line {
                    display: none;
                }
            }

            .outer-wrapper__delete-section {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                padding-bottom: 10px;

                .delete-section__icon {
                    cursor: pointer;
                }
            }

            .outer-wrapper__input-wrapper {
                .input-wrapper__item {                   
        
                    .item__header {
                        font-weight: 600;
                        text-align: center;
                        text-transform: capitalize;
                    }

                    &:last-child {
                        .item__text-input,
                        .item__dropdown-input,
                        .item__color-picker,
                        .item__date-picker {
                            padding-bottom: 0;
                        }
                    }
        
                    $unrelatedElementBottomPadding: 17px;
                    $relatedElementBottomPadding: 8px;
                    $labelRightPadding: 9px;
                    $border: 1px solid black;
        
                    .item__text-input {
                        padding-bottom: $unrelatedElementBottomPadding;
                        flex-wrap: wrap;

                        &.item__text-input_less-bottom-padding {
                            padding-bottom: $relatedElementBottomPadding;
                        }
        
                        .text-input__label {
                            padding-right: $labelRightPadding;
                            margin-bottom: 0;
                        }
        
                        .text-input__input {
                            border: $border;
                        }
                    }
        
                    .item__dropdown-input {
                        padding-bottom: $unrelatedElementBottomPadding;
                        width: 100%;
                        flex-wrap: wrap;
        
                        &.item__dropdown-input_less-bottom-padding {
                            padding-bottom: $relatedElementBottomPadding;
                        }
        
                        .dropdown-input__label {
                            padding-right: $labelRightPadding;
                            white-space: nowrap;
                        }
        
                        .dropdown-input__input-wrapper {
                            width: 100%;
        
                            .input-wrapper__toggler {
                                width: 100%;
                                color: rgb(73, 80, 87) !important;
                            }
                        }
                    }
        
                    .item__color-picker {
                        width: 100%;
                        padding-bottom: $unrelatedElementBottomPadding;
                        flex-wrap: wrap;
        
                        &.item__color-picker_less-bottom-padding {
                            padding-bottom: $relatedElementBottomPadding;
                        }
        
                        .color-picker__trigger {
                            width: 100%;
                        }
                    }
        
                    .item__date-picker {
                        padding-bottom: $relatedElementBottomPadding;
                        width: 100%;
                        flex-wrap: wrap;
        
                        &.item__date-picker_less-bottom-padding {
                            padding-bottom: $relatedElementBottomPadding;
                        }
        
                        .date-picker__label {
                            padding-right: $labelRightPadding;
                        }
        
                        .date-picker__input {
                            border: $border;
                            width: 100%;
                            color: rgb(73, 80, 87);
                        }
                    }

                    .item__phone-no {
                        padding-bottom: $relatedElementBottomPadding;
                        width: 100%;
                        flex-wrap: wrap;
        
                        &.item__phone-no_less-bottom-padding {
                            padding-bottom: $relatedElementBottomPadding;
                        }
        
                        .phone-no__label {
                            padding-right: $labelRightPadding;
                            margin-bottom: 0;
                        }
        
                        .phone-no__input {
                            border-radius: 0.25rem;
                            border: $border;
                            width: 100%;
                            height: 36px;

                            color: rgb(73, 80, 87);
                        }
                    }
                }
            }

            .outer-wrapper__add-section {
                display: none;

                padding-top: 20px;

                .add-section__icon {
                    cursor: pointer;
                }
            }

            .outer-wrapper__horizontal-line {
                height: 3px;
                width: 80%;

                margin-top: 20px;

                margin-left: auto;
                margin-right: auto;

                background-color: #646464;                
            }
        }
    }
}