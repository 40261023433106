.ecard-modal-content {
  // // margin: auto;
  // width: 80%;
}

.ecard-modal-header {
  display: flex;
  justify-content: center !important;
  align-items: center !important;

  .ecard-modal-tittle {
    text-transform: uppercase;
    font-size: 18px !important;
    font-family: Poppins;
  }
}

.ecard-modal-body {
  margin: auto;

  width: 100%;
  font-family: Poppins;
  font-size: 12px;
  .data {
    margin: 12px 0;
    label {
      font-family: Poppins;
      font-size: 12px;
    }
    .input {
      font-size: 12px;
    }
  }
}

.ecard-modal-footer {
}

//MEDIA AREA

@mixin mediaEcardModal($font-size1, $width, $font-size2, $margin) {
  .ecard-modal-header {
    .ecard-modal-title {
      font-size: $font-size1;
    }
  }

  .ecard-modal-body {
    width: $width;
    font-size: $font-size2;
    .data {
      margin: $margin;
      label {
        font-size: $font-size2;
      }
      .ecardInput {
        font-size: $font-size2;
      }
    }
  }
}

@media (min-width: 393px) {
  @include mediaEcardModal(
    $font-size1: 18px,
    $width: 90%,
    $font-size2: 14px,
    $margin: 14px 0
  );
}

@media (min-width: 482px) {
  @include mediaEcardModal(
    $font-size1: 18px,
    $width: 80%,
    $font-size2: 15px,
    $margin: 15px 0
  );
}

@media (min-width: 576px) {
  @include mediaEcardModal(
    $font-size1: 20px,
    $width: 70%,
    $font-size2: 16px,
    $margin: 16px 0
  );
}

@media (min-width: 768px) {
  @include mediaEcardModal(
    $font-size1: 20px,
    $width: 60%,
    $font-size2: 16px,
    $margin: 16px 0
  );
}
