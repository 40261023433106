.home__live-examples {
  margin-top: 40px;
  padding-top: 40px;
  padding-bottom: 40px;

  .live-examples__header > span {
    font-family: var(--major-title-fontFamily);
    font-size: var(--major-title-fontSize);
    font-style: normal;
    font-weight: bold;
    line-height: 50px;
    letter-spacing: 0em;
    color: var(--color-forTitle);

    position: relative;

    .EkadTepi {
      color: #47b0e2;
    }
  }

  .live-examples__sub-header {
    .live-example_text {
      font-family: var(--minor-title-fontFamily);
      font-size: var(--title-description-fontSize);
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
      letter-spacing: 0em;
      text-align: center;
      padding-top: 20px;
      padding-bottom: 30px;
      width: 90%;
      color: #3d3d3d;
    }
  }

  .live-examples__models {
    .models__left-side {
      //margin-right: 20px;

      .left-side__title {
        font-family: var(--minor-title-fontFamily);
        font-size: 16px;
        font-style: normal;
        font-weight: normal;
        line-height: 43px;
        margin-bottom: 10px;

        .majlis-1 {
          background-color: #f3ffe0;
          padding: 0px 20px;
          color: rgb(19, 133, 9);
        }
      }

      .left-side__demo {
      }
    }

    .models__right-side {
      //margin-left: 20px;
      width: min-content;

      .right-side__title {
        font-family: var(--minor-title-fontFamily);
        font-size: 16px;
        font-style: normal;
        font-weight: normal;
        line-height: 43px;
        margin-bottom: 10px;

        .majlis-2 {
          background-color: #f1e0ff;
          padding: 0px 20px;
          color: blueviolet;
        }
      }

      .right-side__demo {
      }
    }
  }

  .live-examples__instruction {
    padding-top: 15px;

    font-family: Belleza;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
  }
}

//MEDIA AREA

@mixin mediaLiveExample(
  $space,
  $fontSize1,
  $fontSize2,
  $paddingTop1,
  $paddingBottom1,
  $lineHeight3,
  $marginBottom1,
  $padding1
) {
  .home__live-examples {
    margin-top: $space;
    padding-top: $space;
    padding-bottom: $space;

    .live-examples__header > span {
      font-size: $fontSize1;
    }

    .live-examples__sub-header {
      .live-example_text {
        font-size: $fontSize2;
        padding-top: $paddingTop1;
        padding-bottom: $paddingBottom1;
      }
    }

    .live-examples__models {
      .models__left-side {
        .left-side__title {
          font-size: $fontSize2;
          line-height: $lineHeight3;
          margin-bottom: $marginBottom1;

          .majlis-1 {
            padding: $padding1;
          }
        }
      }

      .models__right-side {
        .right-side__title {
          font-size: $fontSize2;
          line-height: $lineHeight3;
          margin-bottom: $marginBottom1;

          .majlis-2 {
            padding: $padding1;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  @include mediaLiveExample(
    $space: 35px,
    $fontSize1: var(--major-title-fontSize),
    $fontSize2: var(--title-description-fontSize),
    $paddingTop1: 15px,
    $paddingBottom1: 25px,
    $lineHeight3: 43px,
    $marginBottom1: 10px,
    $padding1: 0px 20px
  );

  .models__right-side {
    margin-top: 30px;
  }
}

@media (max-width: 768px) {
  @include mediaLiveExample(
    $space: 30px,
    $fontSize1: var(--major768-title-fontSize),
    $fontSize2: var(--title768-description-fontSize),
    $paddingTop1: 10px,
    $paddingBottom1: 20px,
    $lineHeight3: 43px,
    $marginBottom1: 10px,
    $padding1: 0px 20px
  );
}

@media (max-width: 480px) {
  @include mediaLiveExample(
    $space: 25px,
    $fontSize1: var(--major480-title-fontSize),
    $fontSize2: var(--title480-description-fontSize),
    $paddingTop1: 5px,
    $paddingBottom1: 15px,
    $lineHeight3: 43px,
    $marginBottom1: 6px,
    $padding1: 0px 20px
  );
}
