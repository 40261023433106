@mixin containerdetails(
  $display,
  $flex-directions: row,
  $justify-content: null,
  $align-items: null,
  $flex: null
) {
  display: $display;
  flex-direction: $flex-directions;
  justify-content: $justify-content;
  align-items: $align-items;
  flex: $flex;
}

//for items inside cards
.details__container {
  flex: 1;
  position: absolute;
  padding: 0;
  height: 85%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.details__content {
  border-radius: 30px;
  width: 70%;

  @include containerdetails(
    $display: flex,
    $flex-directions: column,
    $justify-content: space-evenly,
    $align-items: center,
    $flex: 1
  );

  p {
    text-align: center;
    margin: 0px;
  }

  .b {
    width: 85%;
    font-size: 10px;
    line-height: 150%;
    letter-spacing: 0.4px;
  }

  hr {
    margin-top: 2px !important;
    margin-bottom: 0px !important;
  }

  #pengantin,
  #parents,
  #invitee {
    font-size: 12px;
  }

  #doa {
    font-size: 11px;
  }

  #parents {
    margin-top: 30px;
  }

  #thanks {
    margin-bottom: 30px;
  }
}

//animation
#parents,
#serta {
  animation: animationparents 2s ease-out;
}

#invitee {
  animation: animationparents 2s ease-out;
}

#b1 {
  animation: b1 2s ease-out;
}

#b2 {
  animation: b2 2s ease-out;
}

hr {
  animation: animationhr 2s ease-out;
}

#tempat {
  animation: tempat 2s ease-out;
}

#tarikh {
  animation: tarikh 2s ease-out;
}

#pengantin,
#thanks {
  animation: pengantinthanks 2s ease-out;
}

#doa {
  animation: doa 2s ease-out;
}

//MEDIA AREA

@mixin detailsmedia($fontsize1, $width, $fontsize2, $fontsize3) {
  .details__content {
    .b {
      font-size: $fontsize1;
      width: $width;
    }

    #pengantin,
    #parents,
    #invitee {
      font-size: $fontsize2;
    }

    #doa {
      font-size: $fontsize3;
    }
  }
}

@media (min-width: 393px) {
  @include detailsmedia(
    $fontsize1: 13px,
    $width: 90%,
    $fontsize2: 15px,
    $fontsize3: 14px
  );
}

@media (min-width: 482px) {
  @include detailsmedia(
    $fontsize1: 14px,
    $width: 90%,
    $fontsize2: 16px,
    $fontsize3: 15px
  );
}

@media (min-width: 576px) {
  @include detailsmedia(
    $fontsize1: 15px,
    $width: 90%,
    $fontsize2: 17px,
    $fontsize3: 16px
  );
}

@media (min-width: 768px) {
  @include detailsmedia(
    $fontsize1: 16px,
    $width: 90%,
    $fontsize2: 18px,
    $fontsize3: 17px
  );
}
