@mixin flexBox(
  $display,
  $flex-directions: row,
  $justify-content: null,
  $align-items: null,
  $flex: null
) {
  display: $display;
  flex-direction: $flex-directions;
  justify-content: $justify-content;
  align-items: $align-items;
  flex: $flex;
}

@mixin containerContactApps(
  $height: null,
  $margin: null,
  $width: null,
  $font-size: null,
  $text-align: null,
  $line-height: null
) {
  height: $height;
  margin: $margin;
  width: $width;
  font-size: $font-size;
  text-align: $text-align;
  line-height: $line-height;
}

.person {
  @include flexBox($display: flex, $flex-directions: column);

  @include containerContactApps($width: 100%, $height: 20%);

  border-radius: 5px;

  background-color: rgba(255, 255, 255, 0.8);

  .contact__name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 65%;

    margin: 0;

    .nama__penyambut {
      @include containerContactApps($font-size: 12px);
      margin: 0;
    }
    .hubungan__penyambut {
      @include containerContactApps($font-size: 9px);
      margin: 0;
    }
  }

  .person__icon {
    @include flexBox($display: flex);
    justify-content: space-around;
    align-items: center;
    margin: 0;
    height: 35%;

    a {
      text-decoration: none;
      margin: 0;
      height: 100%;

      .green {
        background-color: #25d366;
      }

      .blue {
        background-color: #71b3ff;
      }

      .icon_contact {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 100%;

        .fab {
          background-color: #ffffff;
        }

        .fas {
          background-color: #177fff;
        }
      }
    }

    .ws {
      width: 50%;
    }

    .phone {
      width: 50%;
    }
  }
}

//MEDIA AREA

@mixin mediaContactApps($fontsize1, $fontsize2, $fontsize3) {
  .person {
    .contact__name {
      .nama__penyambut {
        font-size: $fontsize1;
      }

      .hubungan__penyambut {
        font-size: $fontsize2;
      }
    }

    .person__icon {
      a {
        .icon_contact {
          font-size: $fontsize3;
        }
      }
    }
  }
}

//med phone

@media (min-width: 393px) {
  @include mediaContactApps(
    $fontsize1: 14px,
    $fontsize2: 11px,
    $fontsize3: 16px
  );
}

@media (min-width: 482px) {
  @include mediaContactApps(
    $fontsize1: 16px,
    $fontsize2: 13px,
    $fontsize3: 18px
  );
}

@media (min-width: 576px) {
  @include mediaContactApps(
    $fontsize1: 18px,
    $fontsize2: 15px,
    $fontsize3: 20px
  );
}

@media (min-width: 768px) {
  @include mediaContactApps(
    $fontsize1: 20px,
    $fontsize2: 16px,
    $fontsize3: 22px
  );
}
