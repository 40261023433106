.banking-center {
  margin: 20px 0;
  .online-banking {
    margin: 0 auto !important;

    .fpx-bank {
      background-color: rgb(247, 247, 247);
      border-radius: 8px;
      margin: 2.5px;
      padding: 10px;
      width: 110px;

      &:hover {
        cursor: pointer;

        background: #e0e0e0f0;
      }

      .fpx-bank__logo {
        width: 30px;
        height: 30px;
      }

      .fpx-bank__name {
        font-family: Poppins;
        font-size: 13px;
      }
    }

    .bank-offline {
      pointer-events: none;
      background-color: #e9ecef;
      color: #8d8d8d;
      border: none;

      .fpx-bank__logo {
        filter: gray; /* IE6-9 */
        -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
        filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
      }
    }

    .selection__bank-selected {
      color: rgb(255, 255, 255);
      background: rgb(100, 100, 100);

      &:hover {
        cursor: pointer;
        background: rgb(100, 100, 100);
      }
    }
  }
}

//MEDIA ARREA

@mixin meidaOnlineBanking(
  $margin1,
  $margin2,
  $padding,
  $width1,
  $box,
  $fontSize
) {
  .banking-center {
    margin: $margin1;

    .online-banking {
      .fpx-bank {
        margin: $margin2;
        padding: $padding;
        width: $width1;

        .fpx-bank__logo {
          width: $box;
          height: $box;
        }

        .fpx-bank__name {
          font-size: $fontSize;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  @include meidaOnlineBanking(
    $margin1: 20px 0,
    $margin2: 2.5px,
    $padding: 10px,
    $width1: 110px,
    $box: 30px,
    $fontSize: 13px
  );
}

@media (max-width: 768px) {
  @include meidaOnlineBanking(
    $margin1: 15px 0,
    $margin2: 3px,
    $padding: 10px,
    $width1: 100px,
    $box: 28px,
    $fontSize: 13px
  );
}

@media (max-width: 480px) {
  @include meidaOnlineBanking(
    $margin1: 15px 0,
    $margin2: 3px,
    $padding: 10px,
    $width1: 90px,
    $box: 26px,
    $fontSize: 13px
  );
}
