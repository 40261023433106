//variables
$total-items: 7;

//animation setup
////////////////////////////////////////////////////////animation loading
@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
////////////////////////////////////////////////////////animation home
@-webkit-keyframes animationbodytitle {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  70% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes animationbodytitle {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  70% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes animationbodyname {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes animationbodyname {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes animationbodydate {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  70% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes animationbodydate {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  70% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

////////////////////////////////////////////////////////animation content

@-webkit-keyframes fontgradient {
  0% {
    background-position: 0% 92%;
  }
  50% {
    background-position: 100% 9%;
  }
  100% {
    background-position: 0% 92%;
  }
}

@-moz-keyframes fontgradient {
  0% {
    background-position: 0% 92%;
  }
  50% {
    background-position: 100% 9%;
  }
  100% {
    background-position: 0% 92%;
  }
}

@keyframes fontgradient {
  0% {
    background-position: 0% 92%;
  }
  50% {
    background-position: 100% 9%;
  }
  100% {
    background-position: 0% 92%;
  }
}

////////////////////////////////////////////////////////animation contact

@-webkit-keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: 0;
  }
}

@-moz-keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: 0;
  }
}

@keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -0.2em;
  }
  70% {
    top: -0.3em;
  }
  100% {
    top: 0;
  }
}
////////////////////////////////////////////////////////animation butiran

@-webkit-keyframes animationhr {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes animationhr {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes animationparents {
  0% {
    -webkit-transform: translateY(-300px);
    transform: translateY(-300px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes animationparents {
  0% {
    -webkit-transform: translateY(-300px);
    transform: translateY(-300px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes b1 {
  0% {
    -webkit-transform: translateY(300px) translateX(-100px);
    transform: translateY(300px) translateX(-100px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
}

@keyframes b1 {
  0% {
    -webkit-transform: translateY(300px) translateX(-100px);
    transform: translateY(300px) translateX(-100px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes b2 {
  0% {
    -webkit-transform: translateY(300px) translateX(100px);
    transform: translateY(300px) translateX(100px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
}

@keyframes b2 {
  0% {
    -webkit-transform: translateY(300px) translateX(100px);
    transform: translateY(300px) translateX(100px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes tempat {
  0% {
    -webkit-transform: translateY(-300px) translateX(-100px);
    transform: translateY(-300px) translateX(-100px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
}

@keyframes tempat {
  0% {
    -webkit-transform: translateY(-300px) translateX(-100px);
    transform: translateY(-300px) translateX(-100px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes tarikh {
  0% {
    -webkit-transform: translateY(-300px) translateX(100px);
    transform: translateY(-300px) translateX(100px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
}

@keyframes tarikh {
  0% {
    -webkit-transform: translateY(-300px) translateX(100px);
    transform: translateY(-300px) translateX(100px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
}

@-webkit-keyframes pengantinthanks {
  0% {
    -webkit-transform: translateY(300px);
    transform: translateY(300px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes pengantinthanks {
  0% {
    -webkit-transform: translateY(300px);
    transform: translateY(300px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes doa {
  0% {
    -webkit-transform: translateY(300px) translateX(-100px);
    transform: translateY(300px) translateX(-100px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
}

@keyframes doa {
  0% {
    -webkit-transform: translateY(300px) translateX(-100px);
    transform: translateY(300px) translateX(-100px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
    opacity: 1;
  }
}

////////////////////////////////////////////////////////animation aturcara

@-webkit-keyframes aturcaraanimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes aturcaraanimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@for $i from 1 through $total-items {
  #list_aturcara:nth-child(#{$i}) {
    animation-delay: 0.6s * $i;
  }
}

////////////////////////////////////////////////////////animation lokasi

@-webkit-keyframes animationlokasi {
  0%,
  100% {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  15% {
    -webkit-transform: translateX(-8px) rotate(-3deg);
    transform: translateX(-8px) rotate(-3deg);
  }

  30% {
    -webkit-transform: translateX(5px) rotate(3deg);
    transform: translateX(5px) rotate(3deg);
  }

  45% {
    -webkit-transform: translateX(-5px) rotate(-1.8deg);
    transform: translateX(-5px) rotate(-1.8deg);
  }

  60% {
    -webkit-transform: translateX(2.5px) rotate(1.2deg);
    transform: translateX(2.5px) rotate(1.2deg);
  }

  75% {
    -webkit-transform: translateX(-1px) rotate(-0.6deg);
    transform: translateX(-1px) rotate(-0.6deg);
  }
}

@keyframes animationlokasi {
  0%,
  100% {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }

  15% {
    -webkit-transform: translateX(-8px) rotate(-3deg);
    transform: translateX(-8px) rotate(-3deg);
  }

  30% {
    -webkit-transform: translateX(5px) rotate(3deg);
    transform: translateX(5px) rotate(3deg);
  }

  45% {
    -webkit-transform: translateX(-5px) rotate(-1.8deg);
    transform: translateX(-5px) rotate(-1.8deg);
  }

  60% {
    -webkit-transform: translateX(2.5px) rotate(1.2deg);
    transform: translateX(2.5px) rotate(1.2deg);
  }

  75% {
    -webkit-transform: translateX(-1px) rotate(-0.6deg);
    transform: translateX(-1px) rotate(-0.6deg);
  }
}

///////////////////////////////////////////////////////////animation rsvp

@-webkit-keyframes animationrsvp {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes animationrsvp {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

/////////////////////////////////////////////////////////animation ucapan

////////////////////////////////////////////////////////animation hadiah

@-webkit-keyframes animationshopee {
  0% {
    -webkit-transform: translateX(-300px);
    transform: translateX(-300px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(-28px);
    transform: translateX(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  75% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  85% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes animationshopee {
  0% {
    -webkit-transform: translateX(-300px);
    transform: translateX(-300px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(-28px);
    transform: translateX(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  75% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  85% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

@-webkit-keyframes animationhand {
  0% {
    -webkit-transform: translateX(-300px) rotate(-540deg);
    transform: translateX(-300px) rotate(-540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}
@keyframes animationhand {
  0% {
    -webkit-transform: translateX(-300px) rotate(-540deg);
    transform: translateX(-300px) rotate(-540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}

@-webkit-keyframes butiran {
  0% {
    -webkit-transform: translate(0, 30px);
    transform: translate(0, 30px);
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@keyframes butiran {
  0% {
    -webkit-transform: translate(0, 30px);
    transform: translate(0, 30px);
  }

  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

//// logo animation

@-webkit-keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
  }
}
@keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
  }
}

@keyframes half-spin {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
  }
}
@-webkit-keyframes half-spin {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
  }
}

@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@-webkit-keyframes love5 {
  0% {
    top: 150px;
  }
  20% {
    left: 110px;
  }
  40% {
    left: 120px;
  }
  60% {
    left: 110px;
  }
  80% {
    left: 115px;
  }
  100% {
    top: 100px;
    left: 5px;
    opacity: 0;
  }
}
