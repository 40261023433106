.location_component {
    display: flex !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    background-color: rgb(236, 236, 236);
    height: 100%;
    width: 100%;
    border-radius: 30px;
  
    .location_header {
      width: 100%;
      height: 10%;
      padding: 15px;
      background: #ffffff;
      border-radius: 30px 30px 0 0;
  
      .location_header_back {
        display: flex !important;
        justify-content: flex-start !important;
        width: 20%;
        font-size: 24px;
      }
      .location_header_title {
      
      }
  
      .location_header_empty {
        width: 20%;
      }
    }

    .location__container{
        padding: 0;
        height: 90%;
        width: 100%;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}