.credit-card {
  margin: 20px 0;
  font-family: Tienne;
  font-size: 16px;
  padding: 15px;
  color: rgb(255, 255, 255);
  border-radius: 10px;
  background-image: linear-gradient(55deg, #4055b2f5 20%, #03d5a0e0 90%);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.25);

  .component-input__label {
    margin-left: 3px;
    margin-bottom: 0px;
  }

  .component-input__input {
    border-radius: 5px;
    border: none;
  }

  .credit-card__number {
    margin: 15px 0px;
    margin-top: 0px;
  }
  .credit-card__info {
    .credit-card__expiration-date {
    }
    .credit-card__cvc {
    }
  }
  .credit-card__fullname {
  }
}
