$border: 1px solid #474a4d;
$border-radius: 8px;

.custom__dropdown {
  position: absolute;
  width: 80%;
  height: 50%;
  top: 80%;
  right: 10%;
  transform: translateY(-80%);
  background-color: #ffffff;
  border: $border;
  //border-radius: $border-radius;

  overflow: hidden;

  .custom__form {
    display: flex;
    flex-direction: column;

    align-items: center;
    width: 100%;
    height: 100%;

    font-family: Poppins;
    padding: 10px 6px;

    .custom__formText {
      width: 100%;

      height: 100%;
      textarea {
        width: 100%;
        height: 100%;
        padding: 5px;
        box-sizing: border-box;
        border: 2px solid #ccc;

        background-color: #f8f8f8;
        resize: none;
        font-size: 12px;
      }
    }

    .sahkan-ucapan {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      .custom__formCheckbox {
        // flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        // margin: 0;
        // width: 90%;
        font-size: 12px;
        margin: 4px;

        label {
          margin: 0 5px;
        }
      }

      .text_btn {
        width: 90%;
        margin: auto;
      }
    }
  }
}

//MEDIA AREA

@mixin mediadropdownMenu(
  $padding1,
  $padding2,
  $font-size1,
  $margin1,
  $margin2
) {
  .custom__dropdown {
    .custom__form {
      padding: $padding1;

      .custom__formText {
        textarea {
          padding: $padding2;
          font-size: $font-size1;
        }
      }

      .sahkan-ucapan {
        .custom__formCheckbox {
          font-size: $font-size1;
          margin: $margin1;

          label {
            margin: $margin2;
          }
        }

        .text_btn {
        }
      }
    }
  }
}

@media (min-width: 393px) {
  @include mediadropdownMenu(
    $padding1: 11px 7px,
    $padding2: 6px,
    $font-size1: 13px,
    $margin1: 5px,
    $margin2: 0 6px
  );
}

@media (min-width: 482px) {
  @include mediadropdownMenu(
    $padding1: 12px 8px,
    $padding2: 7px,
    $font-size1: 14px,
    $margin1: 6px,
    $margin2: 0 7px
  );
}

@media (min-width: 576px) {
  @include mediadropdownMenu(
    $padding1: 13px 9px,
    $padding2: 8px,
    $font-size1: 15px,
    $margin1: 7px,
    $margin2: 0 8px
  );
}

@media (min-width: 768px) {
  @include mediadropdownMenu(
    $padding1: 14px 10px,
    $padding2: 9px,
    $font-size1: 16px,
    $margin1: 8px,
    $margin2: 0 9px
  );
}
