.display__security-info {
  //for label and input styling
  .component-input__label {
    font-family: var(--all-input-fontFamily);
    font-size: var(--all-input-fontSize);
    margin-left: 8px;
  }

  .component-input__input {
    font-family: var(--all-input-fontFamily);
    font-size: var(--all-input-fontSize);
    border-radius: 10px;
    border: 2px solid #e9ecef;
  }

  form {
    margin: 30px 50px;
    line-height: 10px;

    .security__change-password {
      .security__change-password-title {
        font-family: var(--title-description-fontFamily);
        font-size: var(--title-description-fontSize);
        padding-top: 15px;
      }

      .security__change-password-old {
        margin: 25px 0px;
      }

      .security__change-password-new {
        margin: 25px 0px;
      }

      .w-100 {
        margin: 25px 0px;

        .security__change-password-edit,
        .security__change-password-save {
          width: 50%;
          border-radius: 8px;
          margin: 0px 8px;

          &:active {
            transform: scale(0.98);
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.24);
          }
        }
      }
    }

    .security__change-email {
      margin-top: 50px;

      .security__change-email-title {
        font-family: var(--title-description-fontFamily);
        font-size: var(--title-description-fontSize);
        padding-top: 15px;
      }

      .security__change-email-new {
        margin: 25px 0px;
      }

      .security__change-email-verification {
        margin: 25px 0px;
      }

      .w-100 {
        margin: 25px 0px;

        .security__change-email-edit,
        .security__change-email-save {
          width: 50%;
          border-radius: 8px;
          margin: 0px 8px;

          &:active {
            transform: scale(0.98);
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.24);
          }
        }
      }
    }
  }
}

//MEDIA AREA

@media (max-width: 991px) {
  .display__security-info {
    //for label and input styling
    .component-input__label {
      font-size: var(--all-input-fontSize);
      margin-left: 8px;
    }

    .component-input__input {
      font-size: var(--all-input-fontSize);
      border-radius: 10px;
    }

    form {
      .security__change-password {
        .security__change-password-title {
          font-size: var(--title-description-fontSize);
          padding-top: 15px;
        }

        .security__change-password-old {
          margin: 25px 0px;
        }

        .security__change-password-new {
          margin: 25px 0px;
        }
      }

      .security__change-email {
        .security__change-email-title {
          font-size: var(--title-description-fontSize);
          padding-top: 15px;
        }

        .security__change-email-new {
          margin: 25px 0px;
        }

        .security__change-email-verification {
          margin: 25px 0px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .display__security-info {
    //for label and input styling
    .component-input__label {
      font-size: var(--all768-input-fontSize);
      margin-left: 8px;
    }

    .component-input__input {
      font-size: var(--all768-input-fontSize);
      border-radius: 10px;
    }

    form {
      margin: 30px 30px;

      .security__change-password {
        .security__change-password-title {
          font-size: var(--title768-description-fontSize);
          padding: 25px 0;
        }

        .security__change-password-old {
          margin: 25px 0px !important;
        }

        .security__change-password-new {
          margin: 25px 0px;
        }
      }

      .security__change-email {
        .security__change-email-title {
          font-size: var(--title768-description-fontSize);
        }

        .security__change-email-new {
          margin: 25px 0px;
        }

        .security__change-email-verification {
          margin: 25px 0px;
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .display__security-info {
    //for label and input styling
    .component-input__label {
      font-size: var(--all480-input-fontSize);
      margin-left: 8px;
    }

    .component-input__input {
      font-size: var(--all480-input-fontSize);
      border-radius: 6px;
    }

    form {
      margin: 20px 10px;

      .security__change-password {
        .security__change-password-title {
          font-size: var(--title480-description-fontSize);
          padding: 25px 0;
        }

        .security__change-password-old {
          margin: 25px 0px !important;
        }

        .security__change-password-new {
          margin: 25px 0px;
        }
      }

      .security__change-email {
        .security__change-email-title {
          font-size: var(--title480-description-fontSize);
        }

        .security__change-email-new {
          margin: 25px 0px;
        }

        .security__change-email-verification {
          margin: 25px 0px;
        }
      }
    }
  }
}
